import { useQuery } from "@tanstack/react-query";
import { getMediaUrl } from "apis/flex/helpers";
export default ({ fileId }) => {
  return useQuery({
    enabled: !!fileId,
    queryKey: ["MediaUrl", fileId],
    queryFn: () => getMediaUrl(fileId),
    staleTime: 1e3 * 60 * 60
  });
};
