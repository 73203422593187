import React from 'react';
import { ensureURLFormat } from 'helpers/strings';
import useMediaUrl from 'hooks/useMediaUrl';
import { useState } from 'react';
import ModalVideoContent from 'components/app/recruitment/campaigns/ModalVideoContent';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Button } from 'react-bootstrap';
import { ResourcePreview } from '../TrainingClient';
import { Controller, useFormContext } from 'react-hook-form';
import { TrainingResource } from 'apis/flex/hr';

export default ({ resource }: { resource: TrainingResource }) => {
  const [show, setShow] = useState<boolean>();
  const { data: mediaUrl, isLoading } = useMediaUrl({
    fileId: (resource.videoId || resource.fileId || [null])[0]
  });
  const openResource = () => {
    if (resource.type === 'Video' || resource.type === 'Embed') {
      return setShow(true);
    }
    if (resource.type === 'File') {
      return window.open(mediaUrl, '_blank');
    }
    if (resource.type === 'URL') {
      return window.open(ensureURLFormat(resource.url), '_blank');
    }
  };
  const handleClick = () => {
    openResource();
  };
  const { trigger } = useFormContext();
  return (
    <Controller
      name={`viewedDate`}
      rules={{ required: true }}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <ModalVideoContent
              forceWatch={resource.type === 'Video'}
              show={show}
              setShow={setShow}
              onEnded={() => {
                field.onChange(new Date());
                trigger('viewedDate');
              }}
              attachment={{ src: mediaUrl || resource.videoUrl }}
            >
              {resource.type === 'Embed' && (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  dangerouslySetInnerHTML={{ __html: resource.embedCode }}
                />
              )}
            </ModalVideoContent>
            {(resource.videoId || resource.fileId) && isLoading ? (
              <Skeleton />
            ) : (
              <div
                ref={field.ref}
                className={classNames(
                  'cursor-pointer hover-bg-200 p-3 h-100 form-control',
                  {
                    'is-invalid': error,
                    'border-0': !error
                  }
                )}
                onClick={() => {
                  if (resource.type !== 'Video') {
                    field.onChange(new Date());
                    trigger('viewedDate');
                  }
                  handleClick();
                }}
              >
                <Flex
                  alignItems="center"
                  justifyContent={'between'}
                  className={'me-3'}
                >
                  <ResourcePreview resource={resource} />
                  <Button variant="falcon-primary">View</Button>
                </Flex>
              </div>
            )}
            {resource.description && (
              <div
                className="text-600 p-2 ms-3 fs--1"
                dangerouslySetInnerHTML={{ __html: resource.description }}
              />
            )}
            {!field.value && (
              <h6 className="w-100 text-center mb-0 mt-3 text-muted">
                Review the resource before continuing
              </h6>
            )}
          </>
        );
      }}
    ></Controller>
  );
};
