import { useQuery } from "@tanstack/react-query";
import { getAvailableShifts, shiftsApi } from "apis/flex/hr";
import { defaultCrudHookBuilder } from "hooks/useDefaultCrud";
const useShifts = defaultCrudHookBuilder("Shifts", shiftsApi, {
  staleTime: 1e3 * 60
});
const prepareParam = (arr) => {
  return arr?.map((d) => d || 0);
};
export const useAvailableShifts = ({
  startDate,
  endDate,
  resourceGroupId,
  employeeIds,
  departmentIds,
  trainingIds,
  contractIds
}) => {
  return useQuery({
    queryKey: [
      "AvailableShifts",
      employeeIds,
      departmentIds,
      trainingIds,
      contractIds,
      startDate,
      endDate,
      resourceGroupId
    ],
    staleTime: 1e3 * 60,
    queryFn: () => getAvailableShifts(startDate, endDate, resourceGroupId, {
      employeeId: prepareParam(employeeIds),
      departmentId: prepareParam(departmentIds),
      trainingId: prepareParam(trainingIds),
      contractId: prepareParam(contractIds)
    })
  });
};
export default useShifts;
