import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React, { useEffect, useState } from 'react';
import useMailerTemplates from './useMailerTemplates';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import useMediaForm from 'hooks/useMediaForm';
import LoadingButton from 'components/common/LoadingButton';
import { ResponsiveModal } from 'components/common/Modals';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  EventAction,
  EventPrefix,
  getFakeTemplateData,
  getTemplatePreview,
  MailerTemplate
} from 'apis/flex/notifications';
import { debounce } from 'lodash';
import { FormPlaceholder } from 'components/wizard/FormWizard';
import {
  flattenObjectIntoArrayOfDotNotationStrings,
  objectWalker
} from 'helpers/utils';
import classNames from 'classnames';

export const EmailEditor = ({
  variables = [],
  height = 300,
  pluginProps,
  formGroupProps,
  ...rest
}: {
  variables?: string[];
  height?: number;
} & Partial<WizardInputProps>) => {
  return (
    <div>
      <WizardInput
        pluginProps={{
          height,
          handlebarsValues: variables,
          ...pluginProps
          // children: (
          //   <>
          //     <ToolbarGroup>
          //       <FunctionDropdown title="{}" Button={VarButton} />
          //       <FunctionDropdown title="IF" Button={IfButton} />
          //       <FunctionDropdown title="IF/ELSE" Button={IfElseButton} />
          //     </ToolbarGroup>
          //   </>
          // )
        }}
        formGroupProps={{
          className: classNames(formGroupProps?.className, 'mb-0 mb-auto'),
          ...formGroupProps
        }}
        type="texteditor"
        name="body"
        label="Email body"
        {...rest}
      />
      <Alert className="fs--1 p-2" variant="info" dismissible>
        <Alert.Heading>Hint</Alert.Heading>
        Start typing {'{{'} to see a list of available variables. You can also
        use Handlebars syntax for more complex functionality.
        <br />
        <a
          rel="noreferrer"
          className="mt-1 d-block alert-link"
          href="https://handlebarsjs.com/guide/"
          target="_blank"
        >
          Read more
        </a>
      </Alert>
    </div>
  );
};
export const EmailForm = ({
  domain,
  action,
  name = '',
  template
}: {
  name?: string;
  domain?: EventPrefix;
  action?: EventAction;
  template?: MailerTemplate;
}) => {
  // const sqlTables = [domainConfigs[domain]?.sqlTable];
  // const sqlDb = domainConfigs[domain]?.sqlDb;
  const { data: fakeData } = useQuery({
    queryKey: ['fakeNotificationTemplateData'],
    queryFn: () => getFakeTemplateData(),
    staleTime: Infinity
  });
  const variables =
    flattenObjectIntoArrayOfDotNotationStrings(fakeData?.[domain] || {}) || [];
  objectWalker(fakeData?.[domain], null, (k, v) => {
    if (Array.isArray(v)) {
      variables.push(k + '.length');
    }
  });
  variables.sort();
  console.log(domain, fakeData?.[domain], variables);
  return (
    <>
      <WizardInput type="text" name={name + 'subject'} label="Subject" />
      <WizardInput
        type="text"
        instruction="This will be shown at the top of the email"
        label="Intro"
        name={name + 'intro'}
      />
      <Row>
        <Col xs={12} xxl={6}>
          <EmailEditor
            height={300}
            variables={variables}
            name={name + 'body'}
            label="Body"
            // sqlDb={sqlDb}
            // sqlTables={sqlTables}
          />
        </Col>
        <Col xs={12} xxl={6}>
          <EmailPreview
            template={template}
            domain={domain}
            action={action}
            height={300}
            className="mt-4 border rounded-3"
          />
        </Col>
      </Row>
    </>
  );
};
export const EmailPreview = ({
  domain,
  namePrefix,
  template,
  height = 300,
  action,
  ...props
}: {
  domain: EventPrefix;
  action?: EventAction;
  namePrefix?: string;
  template: MailerTemplate;
} & React.DetailedHTMLProps<
  React.IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
>) => {
  // const template = useWatch({name:namePrefix+'body'}) as MailerTemplateForm;
  const { setError, clearErrors } = useFormContext();
  const {
    data: html,
    isLoading,
    mutate
  } = useMutation<string, any, MailerTemplate>({
    mutationFn: data => getTemplatePreview(data, domain, action),
    onError: err => {
      if (err.code === 'ERR_BAD_RESPONSE') {
        setError(namePrefix + 'body', {
          message: 'Could not parse email template'
        });
      }
    },
    onSuccess: () => {
      clearErrors(namePrefix + 'body');
    }
  });
  const [rendered, setRendered] = useState('');
  const handleDebouncedChange = React.useMemo(
    () =>
      debounce(template => {
        mutate(template);
      }, 2000),
    [mutate]
  );
  useEffect(() => {
    if (
      template?.intro + template?.body !== rendered &&
      !!template?.intro + template?.body
    ) {
      handleDebouncedChange(template);
      setRendered(template.intro + template.body);
    }
  }, [template]);
  return (
    <>
      {isLoading || !html ? (
        <FormPlaceholder />
      ) : (
        <iframe
          width={'100%'}
          height={height}
          content="preview"
          srcDoc={html}
          {...props}
        />
      )}
    </>
  );
};
export const TemplateForm = () => {
  return (
    <>
      <WizardInput
        type="switch"
        name="isDefault"
        registerProps={{ required: false }}
        label="Set as default"
      />
      <WizardInput type="text" name="name" label="Template name" />
    </>
  );
};
export const EmailEditorModal = ({ id, show, onShow, domain }) => {
  const {
    data: email,
    upsert,
    isUpserting
  } = useMailerTemplates({ id, select: d => d[0] });
  const methods = useMediaForm<MailerTemplate>();
  useEffect(() => {
    if (email) {
      methods.reset(email);
    }
  }, [email]);
  return (
    <ResponsiveModal show={show} onHide={() => onShow(false)}>
      <Modal.Body>
        <FormProvider {...methods}>
          <TemplateForm />
          <EmailForm />;
          <EmailPreview template={methods.watch()} domain={domain} />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => onShow(false)}>
          Cancel
        </Button>
        <LoadingButton
          onClick={() =>
            methods.handleSubmit(
              v => upsert(v, () => onShow(false)),
              console.error
            )
          }
          loading={isUpserting}
        >
          Save
        </LoadingButton>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
