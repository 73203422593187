import api from ".";
import CrudApi from "./CrudApi";
export const reportConfigsApi = new CrudApi(
  "/helpers/reports/configs"
);
export const pageReportsApi = new CrudApi("/helpers/reports/page");
export const getReportData = ({
  id,
  config
}) => api.get("/helpers/reports/data", {
  params: id ? { id } : config
}).then((d) => d.data);
export const getDomainConfigs = () => {
  return api.get("/helpers/reports/domain-config").then((d) => d.data);
};
