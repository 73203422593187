import {
  addDays,
  format,
  setHours,
  setMinutes,
  startOfISOWeek
} from 'date-fns';

export const getDateTime = (date, time) => {
  const timestring =
    typeof time === 'string' && time.length < 9
      ? time
      : new Date(time).getFullYear() > 1970
      ? new Date(time).toLocaleTimeString()
      : new Date(
          format(new Date(date), 'yyyy-MM-dd') + 'T' + time.split('T')[1]
        ).toLocaleTimeString();
  return setMinutes(
    setHours(new Date(date), timestring.toString().split(':')[0]),
    time.toString().split(':')[1]
  );
};
export const formatDateToISO = date => {
  // Adjust to the local time zone and extract the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`; // Return the date in YYYY-MM-DD format
};
export const getTime = (time, timeFormat = 'HH:mm') => {
  if (!time) return '00:00';
  const datetime = getDateTime(new Date(), time);
  return format(datetime, timeFormat);
};
export const getStartOfWeek = date => startOfISOWeek(new Date(date));
export const getWeekdayIndex = date => {
  //get weekday as Monday is 0
  const day = new Date(date).getDay();
  return day === 0 ? 6 : day - 1;
};
export const weekdaysIn = date =>
  Array(7)
    .fill(0)
    .map((x, i) => {
      const dte = addDays(startOfISOWeek(new Date(date)), i);
      return dte;
    });
export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];
export const getTimeOfDay = (date = new Date()) => {
  const now = new Date(date);
  const hour = now.getHours();
  return hour < 12 ? 'morning' : hour < 17 ? 'afternoon' : 'evening';
};
