import { useQuery } from "@tanstack/react-query";
import { encryptParams } from "apis/flex/helpers";
export default (params, enabled = true) => {
  return useQuery({
    queryKey: ["encryptUrlParams", params],
    queryFn: () => encryptParams(params),
    staleTime: Infinity,
    enabled: !!params && enabled
  });
};
