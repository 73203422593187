import WizardInput, {
  WizardFormGroupProps,
  WizardInputProps
} from 'components/wizard/WizardInput';
import React from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import FormPicker from 'components/common/customForms/widgets/FormPicker';
import TemplatePicker from 'components/mailer/templates/TemplatePicker';
const StageForm = ({
  withDueDate,
  namePrefix = '',
  withForm,
  ...rest
}: {
  withForm?: boolean;
  withDueDate?: boolean;
  namePrefix?: '' | `${string}.`;
} & Partial<WizardInputProps>) => {
  const hasNotify = useWatch({ name: namePrefix + 'sendEmail' });
  return (
    <>
      <WizardInput
        type="switch"
        name={namePrefix + 'sendEmail'}
        label="Notify applicant(s)"
        registerProps={{ required: false }}
        {...rest}
      />
      {hasNotify && (
        <TemplatePicker
          name={namePrefix + 'notificationTemplateId'}
          domain="applicant-stage"
          action="added"
        />
      )}
      {withForm && (
        <FormPicker
          name={namePrefix + 'formId'}
          label="Task (optional)"
          registerProps={{ required: false }}
          {...rest}
        />
      )}
      {withDueDate && (
        <WizardInput
          type="datetime"
          label="Due date"
          registerProps={{ required: false }}
          name={namePrefix + 'dueDate'}
        />
      )}
    </>
  );
};
export default StageForm;
