import React, { useMemo } from 'react';
import useTags from 'hooks/useTags';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { domainConfigs } from 'components/notification/config';
import { useFormContext } from 'react-hook-form';
import SoftBadge from './SoftBadge';
import { SoftBadgeProps } from './SoftBadge';
import classNames from 'classnames';

export const Tags = ({
  ids,
  className,
  children,
  ...rest
}: {
  ids: number[];
  children?: React.ReactNode;
} & Omit<SoftBadgeProps, 'children'>) => {
  const { data: tags } = useTags({
    filters: { id: ids }
  });
  return tags?.map(tag => (
    <SoftBadge
      pill
      key={tag.id}
      bg="info"
      className={classNames(className, 'me-1')}
      {...rest}
    >
      {children}
      {tag.name}
    </SoftBadge>
  ));
};

export default ({
  tableName,
  name,
  ...rest
}: { tableName: string } & Omit<WizardInputProps, 'type'>) => {
  const config = domainConfigs.tag;
  const {
    data: tags,
    add,
    isAdding
  } = useTags({
    filters: { tableName },
    useFilter: !!tableName,
    select: d => d.map(config.format),
    afterSave: added => {
      const oldVal = getValues(name);
      const newVal = [
        ...(!oldVal || Array.isArray(oldVal) ? oldVal : [oldVal]),
        added.id
      ];
      console.log('setting value', name, newVal);
      setValue(name, newVal, {
        shouldDirty: true,
        shouldTouch: true
      });
    }
  });
  const { setValue, getValues } = useFormContext();
  const handleCreate = (val: string) => {
    add({ name: val, tableName });
  };
  const pluginProps = useMemo(
    () => ({
      creatable: true,
      onCreate: handleCreate,
      isLoading: isAdding
    }),
    []
  );
  return (
    <>
      <WizardInput
        type="select"
        name={name}
        {...rest}
        loading={isAdding || rest.loading}
        options={tags}
        pluginProps={pluginProps}
      />
    </>
  );
};
