import api from ".";
import { cleanFilePath } from "helpers/utils";
import {
  create,
  keyResolver,
  windowScheduler
} from "@yornaath/batshit";
export default class CrudApi {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.#baseUrl = cleanFilePath(baseUrl) + "/";
    this.batcher = create({
      resolver: keyResolver("id"),
      scheduler: windowScheduler(1e3),
      name: cleanFilePath(baseUrl),
      fetcher: async (ids) => {
        return this.getByIds(ids.map(Number));
      }
    });
    this.publicBatcher = create({
      resolver: keyResolver("id"),
      scheduler: windowScheduler(1e3),
      name: cleanFilePath(baseUrl),
      fetcher: async (ids) => {
        return this.getPublicByIds(ids.map(Number));
      }
    });
    this.listBatcher = create({
      resolver: keyResolver("id"),
      scheduler: windowScheduler(1e3),
      name: cleanFilePath(baseUrl),
      fetcher: async (ids) => {
        return this.getByIds(ids.map(Number), true);
      }
    });
  }
  #baseUrl;
  batcher;
  publicBatcher;
  listBatcher;
  getOne = async (id, aggregations) => {
    return api.get(this.#baseUrl + id, { params: { aggregations } }).then((d) => d.data);
  };
  getByIds = async (ids, list) => {
    return api.get(this.#baseUrl + (list ? "list" : ""), {
      params: {
        filters: { first: "id", second: ids },
        includeDeleted: true
      }
    }).then((d) => d.data);
  };
  getPublicByIds = async (ids) => {
    return api.get(this.#baseUrl + "public", {
      params: {
        filters: { first: "id", second: ids },
        includeDeleted: true
      }
    }).then((d) => d.data);
  };
  get = async (filters, sort, customFilter, paging, includeDeleted, aggregations, columns) => {
    return api.get(this.#baseUrl, {
      params: {
        filters,
        includeDeleted,
        paging,
        customFilter,
        sort,
        aggregations,
        columns
      }
    }).then((d) => d.data);
  };
  getList = async (filters, sort, customFilter, paging, includeDeleted, aggregations, columns) => {
    return api.get(this.#baseUrl + "list", {
      params: {
        filters,
        includeDeleted,
        paging,
        customFilter,
        sort,
        aggregations,
        columns
      }
    }).then((d) => d.data);
  };
  counts = async (by, filters, allowedOnly, includeDeleted, customFilter) => {
    return api.get(
      this.#baseUrl + (allowedOnly ? "allowed-counts" : "counts"),
      {
        params: { filters, includeDeleted, by, customFilter }
      }
    ).then((d) => d.data);
  };
  getPublic = async (filters, sort, customFilter, paging, includeDeleted, aggregations, columns) => {
    return api.get(this.#baseUrl + "public", {
      params: {
        filters,
        includeDeleted,
        paging,
        customFilter,
        sort,
        aggregations,
        columns
      }
    }).then((d) => d.data);
  };
  insert = async (data, noReturn) => {
    return api.post(this.#baseUrl, { data, noReturn }).then((d) => d.data);
  };
  selfInsert = async (data, noReturn) => {
    return api.post(this.#baseUrl + "me", { data, noReturn }).then((d) => d.data);
  };
  clone = async (id, noReturn) => {
    return api.post(this.#baseUrl + id + "/clone", { noReturn }).then((d) => d.data);
  };
  insertBulk = async (data, noReturn) => {
    return api.post(this.#baseUrl + "/bulk", { data, noReturn }).then((d) => d.data);
  };
  update = async (id, data, noReturn) => {
    return api.patch(this.#baseUrl + id, { data, noReturn }).then((d) => d.data);
  };
  updateBulk = async (filters, data, noReturn) => {
    return api.patch(this.#baseUrl, { data, filters, noReturn }).then((d) => d.data);
  };
  selfUpdate = async (id, data, noReturn) => {
    return api.patch(this.#baseUrl + "me/" + id, { data, noReturn }).then((d) => d.data);
  };
  remove = async (id) => {
    return api.delete(this.#baseUrl + id).then((d) => d.data);
  };
  bulkRemove = async (ids) => {
    return api.delete(this.#baseUrl + "bulk", { data: { ids } }).then((d) => d.data);
  };
  meta = async () => {
    return api.get(this.#baseUrl + "meta").then((d) => d.data);
  };
}
