import React, { useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { copyTextToClipboard } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { apiSuccess } from 'apis/errors';
import useModal from './useModal';

export default ({ text: textProp }: { text?: string } = {}) => {
  const copyRef = useRef(null);
  const [copyText, setCopyText] = useState(textProp);
  const { show, setShow } = useModal({
    title: 'Copy Link',
    body: (
      <InputGroup>
        <Form.Control
          size="sm"
          type="text"
          // className="invitation-link"
          defaultValue={copyText}
          value={copyText}
          ref={copyRef}
        />
        <Button onClick={() => handleCopy()} variant="falcon-default" size="sm">
          <FontAwesomeIcon icon={faCopy} />
        </Button>
      </InputGroup>
    )
  });
  const handleCopy = (text?: string, done?: () => void) => {
    if (text) {
      setCopyText(text);
    }
    copyTextToClipboard(text || copyText)
      .then(() => {
        apiSuccess('Copied to clipboard!');
        if (show) {
          setShow(false);
        }
        done?.();
      })
      .catch(e => {
        console.error('Error copying text', e);
        setShow(true);
        done?.();
      });
  };
  return {
    copy: handleCopy
  };
};
