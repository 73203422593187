import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import Divider from './Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

export default ({
  children,
  label = (expanded: boolean) => `Show ${expanded ? 'Less' : 'More'}`,
  activeCount
}: {
  children: React.ReactNode;
  label?: (expanded: boolean) => React.ReactNode;
  activeCount?: number;
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Divider className="p-2">
        <Button variant="link" size="sm" onClick={() => setShow(!show)}>
          {label(show)}
          {activeCount && (
            <Badge
              bg="warning"
              className="mx-1"
              pill
              style={{
                verticalAlign: 'bottom'
              }}
            >
              {activeCount}
            </Badge>
          )}
          <motion.div
            className="d-inline-block ms-1"
            initial={{ rotate: 0 }}
            animate={{ rotate: show ? 180 : 0 }}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </motion.div>
        </Button>
      </Divider>
      {show && children}
    </>
  );
};
