import React from 'react';
import useClients from './useClients';
import { UseCrudProps } from 'hooks/useDefaultCrud';
import { Client } from 'apis/flex/projects';
import DomainTable from 'components/common/DomainTable';

export default (props: UseCrudProps<Client, Client[]>) => {
  return (
    <DomainTable
      crudHook={useClients}
      crudProps={props}
      domain="client"
      columns={[
        'name',
        'website',
        'telephone',
        'email',
        'accountsEmail',
        { id: 'contacts', formatter: v => v()?.length }
      ]}
    />
  );
};
