import React from 'react';
import WizardInput from 'components/wizard/WizardInput';
import PropTypes from 'prop-types';
import useApplicantScores from './hooks/useApplicantScores';
import { useUser } from 'hooks/useUser';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ApplicantScore } from 'apis/flex/recruitment';
import ActionFooter from 'components/common/ActionFooter';
import { ResponsiveModal } from 'components/common/Modals';
export const ScoreFormInner = ({ name, ...rest }) => {
  return (
    <WizardInput type="rating" pluginProps={{ max: 5, ...rest }} name={name} />
  );
};
export const ApplicantScoreForm = ({
  applicantIds,
  onFinished
}: {
  applicantIds: number[];
  onFinished: () => void;
}) => {
  const { id: userId } = useUser();
  const { bulkAdd, data, isBulkAdding } = useApplicantScores({
    filters: { scoredBy: userId, applicantId: applicantIds },
    useFilter: true,
    select: d => d[0],
    afterSave: () => onFinished()
  });
  const methods = useForm<ApplicantScore, any, ApplicantScore>({
    values: data
  });
  const handleSubmit = methods.handleSubmit(vals =>
    bulkAdd(
      applicantIds.map(id => ({
        applicantId: id,
        scoredBy: userId,
        scoredDate: new Date(),
        score: vals.score
      }))
    )
  );
  return (
    <FormProvider {...methods}>
      <ScoreFormInner name="score" />
      <ActionFooter
        isLoading={isBulkAdding}
        onCancel={onFinished}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
};
export const ApplicantScoreModal = ({
  show,
  setShow,
  applicantIds
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  applicantIds: number[];
}) => {
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <ApplicantScoreForm
        applicantIds={applicantIds}
        onFinished={() => setShow(false)}
      />
    </ResponsiveModal>
  );
};
