import React from 'react';
import { Button, CloseButton, Image, Modal } from 'react-bootstrap';
import VideoPlayer, { VideoPlayerProps } from 'components/common/VideoPlayer';
import Error500 from 'components/errors/Error500';
import { FileId } from 'apis/types';
import useFileLoader from 'components/wizard/hooks/useFileLoader';

const ModalVideoContent = ({
  show,
  setShow,
  attachment,
  forceWatch,
  autoplay,
  children,
  ...rest
}: {
  show: boolean;
  setShow: any;
  attachment?: {
    id?: string | number;
    image?: string;
    src?: string;
    title?: string;
    date?: string;
    type?: string;
    fileId?: FileId;
  };
  forceWatch?: boolean;
} & Partial<VideoPlayerProps>) => {
  const handleEnded = () => setShow(false);
  const [error, setError] = React.useState<boolean>(false);
  const { files } = useFileLoader({ value: attachment?.fileId });
  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => setShow(false)}
      className="video-popup"
      contentClassName="bg-transparent border-0"
      backdropClassName="video-popup-backdrop"
      backdrop={forceWatch ? 'static' : undefined}
      centered
    >
      {!forceWatch && (
        <CloseButton
          variant="white"
          onClick={() => setShow(false)}
          className="video-popup-close-btn"
        />
      )}
      <Modal.Body className="p-0 rounded overflow-hidden">
        {error ? (
          <Error500
            button={
              <Button
                onClick={() => {
                  setError(null);
                  setShow(false);
                }}
              >
                Close
              </Button>
            }
          />
        ) : (
          children ||
          (!attachment.src ? (
            <div style={{ position: 'relative', paddingTop: '75%' }}>
              <Image
                className="translate-middle-x start-50"
                height={'100%'}
                style={{
                  position: 'absolute',
                  top: 0,
                  borderRadius: 'var(--falcon-border-radius-lg)'
                }}
                src={files?.[0]?.preview || attachment.image}
              />
            </div>
          ) : (
            <VideoPlayer
              light={attachment.image}
              controls={!forceWatch}
              onEnded={handleEnded}
              url={attachment.src}
              autoplay={forceWatch || autoplay}
              onError={() => setError(true)}
              {...rest}
            />
          ))
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalVideoContent;
