import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Card, Form } from 'react-bootstrap';
import { apiSuccess } from 'apis/errors';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import SelectedApplicants from './widgets/SelectedApplicants';
import TemplatePicker from 'components/mailer/templates/TemplatePicker';
import { rejectApplicants } from 'apis/flex/recruitment';
const RejectCard = ({
  rejecting = [],
  onComplete
}: {
  rejecting?: number[];
  onComplete?: () => void;
}) => {
  const nav = useNavigate();
  const { mutate, isLoading: rejectLoading } = useMutation<
    any,
    Error,
    { feedback: string; notificationTemplateId: number }
  >({
    mutationFn: ({ feedback, notificationTemplateId }) =>
      rejectApplicants(rejecting, feedback, notificationTemplateId)
  });
  const methods = useForm({ defaultValues: { feedback: '' } });
  const queryClient = useQueryClient();

  const handleReject = vals => {
    mutate(
      { ...vals },
      {
        onSuccess: () => {
          apiSuccess('Applicants rejected');
          onComplete();
        },
        onSettled: () => {
          queryClient.invalidateQueries(['Applicants']);
        }
      }
    );
  };
  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(handleReject, err => console.log(err))}
      >
        <Card>
          <Card.Header>
            <Card.Title>Reject applicants</Card.Title>
          </Card.Header>
          <Card.Body>
            <SelectedApplicants ids={rejecting} />
            <RejectForm />
          </Card.Body>
          <Card.Footer>
            <Button type="button" variant="link" onClick={() => nav(-1)}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="danger"
              loading={rejectLoading}
            >
              Reject
            </LoadingButton>
          </Card.Footer>
        </Card>
      </Form>
    </FormProvider>
  );
};
RejectCard.propTypes = {
  rejecting: PropTypes.array,
  onComplete: PropTypes.func
};
export const RejectForm = ({ name = '' }) => {
  return (
    <>
      <TemplatePicker
        registerProps={{ required: false }}
        name={`${name}notificationTemplateId`}
        instruction="Will use default if not specified"
        domain="applicant"
        action="rejected"
      />
    </>
  );
};
const RejectApplicant = () => {
  const { id } = useParams();
  return <RejectCard rejecting={id.split(',').map(n => parseInt(n))} />;
};

RejectForm.propTypes = {
  ids: PropTypes.array
};
export default RejectApplicant;
