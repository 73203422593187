import { useMutation, useQuery } from "@tanstack/react-query";
import { apiSuccess } from "apis/errors";
import {
  getApplicantPipelines,
  rerunApplicantPipeline
} from "apis/flex/recruitment";
export default ({
  applicantId,
  filters,
  select
} = {}) => {
  const query = useQuery({
    enabled: !!applicantId,
    queryKey: ["ApplicantPipelines", applicantId],
    queryFn: () => getApplicantPipelines({ applicantId, filters }),
    select,
    staleTime: 1e3 * 60
  });
  const { mutate, isLoading } = useMutation({
    mutationFn: (id) => rerunApplicantPipeline(id),
    onSuccess: () => {
      apiSuccess("Pipeline run successfully");
      !!applicantId && query.refetch();
    }
  });
  return { ...query, run: mutate, isRunning: isLoading };
};
