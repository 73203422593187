import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useApplicantGroups from './hooks/useApplicantGroups';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import blankUser from 'assets/img/team/avatar.png';
import StarRating from 'components/common/StarRating';
import TooltipBadge from 'components/common/TooltipBadge';
import { meanBy } from 'lodash';
import { useUser } from 'hooks/useUser';
import { SelectedDomainItems } from 'components/common/DomainItemSelector';
import useApplicantScores from './hooks/useApplicantScores';
import { Applicant } from 'apis/flex/recruitment';
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import useResponseTableColumns from 'components/common/customForms/Reporting/useResponseTableColumns';
import { Column } from 'components/common/advance-table-v2/AdvanceTableProvider';
import useCampaign from '../campaigns/hooks/useCampaign';
import UserIcons from 'components/app/users/widgets/UserIcons';
import { getApplicantActions } from './detail/ApplicantDetail';
import { useGuard } from 'hooks/useGuard';
import { Row } from '@tanstack/react-table';

export default ({ campaignId }: { campaignId: number }) => {
  const groups = useApplicantGroups({ campaignId });
  const { data: campaign } = useCampaign({ id: campaignId });
  const { id: userId } = useUser();
  const { addAsync } = useApplicantScores();
  const handleScore = (applicant: Applicant, score: number) => {
    addAsync({ applicantId: applicant.id, score });
  };
  const { data: questionColumns, isLoading } = useResponseTableColumns({
    formId: campaign?.formId,
    visible: true,
    select: f =>
      f.map(
        ({ accessorFn, ...col }) =>
          ({
            ...col,
            id: 'response.' + col.id,
            accessorFn: (
              d: Omit<Applicant, 'response'> & {
                response: Record<number | 'metadata', any>;
              }
            ) => {
              const response = d.response;
              return (
                response?.metadata?.[col.id]?.analysis || response?.[col.id]
              );
            }
          } as unknown as Column<Applicant>)
      )
  });
  //   console.log('questionColumns', questionColumns);
  const applicantColumns: Column<Applicant>[] = [
    {
      id: 'name',
      accessorFn: a => a.firstName + ' ' + a.surname,
      formatter: getValue => (
        <Flex alignItems="center">
          <Avatar
            src={blankUser}
            className={`status-offline me-2`}
            mediaClass={'border border-3 rounded-circle border-200 bg-100'}
          />
          <span className="me-4">{getValue()}</span>
        </Flex>
      )
    },
    {
      id: 'score',
      type: 'number',
      accessorFn: a => meanBy(a.scores, s => s.score),
      formatter: (get, d) => {
        const ids = d?.scores?.map(s => s.scoredBy);
        return (
          <Flex alignItems={'center'}>
            <UserIcons ids={ids} size="s" className="me-2" />
            <StarRating
              color="primary"
              fractions={4}
              stop={5}
              rating={get()}
              readonly
            />
          </Flex>
        );
      }
    },
    {
      id: 'myScore',
      type: 'number',
      accessorFn: a => a?.scores?.find(s => s.scoredBy === userId)?.score,
      formatter: (get, d) => (
        <div
          onClick={e => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          <StarRating
            fractions={1}
            stop={5}
            rating={get()}
            handleChange={score => handleScore(d, score)}
          />
        </div>
      )
    },
    { id: 'email' },
    { id: 'appliedDate', type: 'datetime' },
    {
      id: 'acceptReject',
      header: 'Outcome',
      formatter: (get, d) => {
        return (
          get() && (
            <TooltipBadge
              className="fs-3"
              icon={get() === 'accepted' ? faCheckCircle : faTimesCircle}
              color={get() === 'accepted' ? 'success' : 'danger'}
              tooltip={
                <SelectedDomainItems
                  show={false}
                  compact
                  size="xs"
                  domain="user"
                  ids={[d.acceptRejectBy]}
                />
              }
            />
          )
        );
      }
    },
    ...(questionColumns || [])
    // {
    //   id: 'response',
    //   formatter: null,
    //   columns:
    // }
  ];
  //   console.log('applicantColumns', applicantColumns);
  const { can } = useGuard({ roles: ['applicant'] });
  return (
    <DomainTable
      onNewClick={null}
      domain="applicant"
      isLoading={isLoading}
      selectAll
      crudProps={{
        filters: { campaignId },
        asList: false,
        select: d =>
          d.map(a => {
            const metaLookup = a.response?.reduce(
              (aa, bb) => ({
                ...aa,
                [bb.questionId]: bb.metadata
              }),
              {}
            );
            return {
              ...a,
              response: a.response?.reduce(
                (a, b) => ({
                  ...a,
                  [b.questionId]: b.response,
                  metadata: {
                    ...a.metadata,
                    [b.questionId]: metaLookup[b.questionId]
                  }
                }),
                {} as any
              )
            };
          })
      }}
      tabs={groups?.map(g => ({
        label: g.title,
        crudFilter: g.filter
      }))}
      columns={!!questionColumns && applicantColumns}
      actions={getApplicantActions({}).map(action => ({
        name: action.label,
        onClick: () => null,
        icon: action.icon,
        show: (r: Applicant) =>
          typeof action.show === 'function' ? action.show([r]) : action.show,
        modalOnClick: ({
          hide,
          row
        }: {
          hide: () => void;
          row: Row<Applicant>;
        }) => action.modal({ hide, data: [row.original] })
      }))}
      bulkActions={getApplicantActions({
        canEdit: d =>
          can(
            'edit',
            d.map(a => a.id)
          )
      }).map(action => ({
        name: action.label,
        actionFn: () => null,
        icon: action.icon,
        show: (r: Applicant[]) =>
          typeof action.show === 'function' ? action.show(r) : action.show,
        modalOnClick: ({
          hide,
          rows
        }: {
          hide: () => void;
          rows: Row<Applicant>[];
        }) => action.modal({ hide, data: rows.map(r => r.original) })
      }))}
    />
  );
};
