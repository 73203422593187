import React from 'react';
import CampaignsTable from './CampaignsTable';

const Campaigns = () => {
  return (
    <>
      <CampaignsTable />
    </>
  );
};
export default Campaigns;
