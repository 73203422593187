import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useResourceGroups from './useResourceGroups';

export default () => {
  return (
    <DomainTable
      domain="resource-group"
      title="Resource Groups"
      crudHook={useResourceGroups}
      columns={['name', 'description']}
    />
  );
};
