import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import React, { HTMLAttributes } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export default ({
  dragHandleProps,
  iconProps,
  ...props
}: {
  dragHandleProps: DraggableProvidedDragHandleProps;
  iconProps?: FontAwesomeIconProps;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...dragHandleProps} {...props}>
      <FontAwesomeIcon
        className="me-2 text-500 "
        icon={faGripVertical}
        size="1x"
      />
    </div>
  );
};
