import React from 'react';
import ItemSelector, {
  ItemSelectorProps,
  SelectorItem
} from 'components/common/ItemSelector';
import useTrainingCourses from '../hooks/useTrainingCourses';
import { TrainingCourse } from 'apis/flex/hr';
import { getMediaStreamUrl } from 'helpers/utils';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import DomainItemIcon from 'components/common/DomainItemIcon';
import {
  CrudItemSelectorProps,
  DomainItemSelector,
  SelectedDomainItems
} from 'components/common/DomainItemSelector';

const useCourseItems = ({
  filter,
  mutator = () => ({})
}: {
  filter?: (course: TrainingCourse) => boolean;
  mutator?: (course: TrainingCourse) => Partial<SelectorItem>;
}) => {
  return useTrainingCourses({
    useFilter: true,
    select: d =>
      d.filter(filter).map(course => ({
        label: course.name,
        value: course.id,
        info: {
          modules: (course.resources?.length || 0) + ' resources'
        },
        avatar:
          !!course.profileBackground &&
          getMediaStreamUrl(course.profileBackground[0]),
        ...mutator(course)
      }))
  });
};
export default ({
  name,
  ...rest
}: Omit<CrudItemSelectorProps, 'domain' | 'isLoading'>) => {
  return (
    <DomainItemSelector
      name={name || 'courseId'}
      label="Training course"
      placeholder="Select a course"
      domain="training-course"
      {...rest}
    />
  );
};
export const SelectedTrainingCourses = ({
  ids,
  ...rest
}: SelectedDomainItemsProps) => {
  return (
    <SelectedDomainItems
      ids={ids}
      domain="training-course"
      placeholder={'No courses selected'}
      {...rest}
    />
  );
};
