import _ from "lodash";
import { logger } from "../system/logging";
const log = (obj, ...args) => logger.info(obj, ...args);
export const convertShiftTimeValueToShift = (time) => {
  const parts = time.split("-");
  return {
    start: parts[0],
    end: parts[1]
  };
};
export const shiftToHours = (s) => {
  const [startH, startM] = s.start.split(":");
  const [endH, endM] = s.end.split(":");
  return ((Number(endH) - Number(startH)) * 60 + Number(endM) - Number(startM)) / 60;
};
const reduceShiftHours = (func, v) => {
  return Object.keys(v).filter((b) => v[b]?.length).reduce(
    (a, b) => a + Math[func](
      ...v[b].map((s) => shiftToHours(convertShiftTimeValueToShift(s)))
    ),
    0
  );
};
export const getMaxShiftHours = (v) => reduceShiftHours("max", v);
export const getMinShiftHours = (v) => reduceShiftHours("min", v);
export const parseValueForComparison = (v) => {
  if (v === void 0 || v === null) return v;
  if (typeof v === "object") return Object.values(v).join(" ").toLowerCase();
  return JSON.stringify(v).toLowerCase();
};
const isStringNumber = (n) => typeof n === "string" && !isNaN(n / 1);
const getRuleValueParser = (dataType = null, ruleType = "", aggregation = null) => {
  return getValueParser(
    dataType,
    ruleType,
    !!aggregation || ["in", "!in", "allin", "!allin", "between", "!between"]
  );
};
const getInputValueParser = (dataType = null, ruleType = "") => {
  return getValueParser(dataType, ruleType);
};
export const aggregate = (aggregation, values) => {
  if (aggregation === "sum") {
    return values.reduce((acc, curr) => acc + curr, 0);
  }
  if (aggregation === "avg") {
    return values.reduce((acc, curr) => acc + curr, 0) / values.length;
  }
  if (aggregation === "min") {
    return Math.min(...values);
  }
  if (aggregation === "max") {
    return Math.max(...values);
  }
  if (aggregation === "count") {
    return values.length;
  }
  if (aggregation === "countDistinct") {
    return new Set(values).size;
  }
  return values.length;
};
export const getValueParser = (dataType = null, ruleType = "", arrayIf = null) => {
  const basicParse = (v) => getValueParser()(v);
  switch (dataType) {
    case "range":
      return (v) => [getValueParser()(v[0] || 0), getValueParser()(v[1] || 0)];
    default:
      return (v) => {
        if (arrayIf === true || Array.isArray(arrayIf) && arrayIf.includes(ruleType)) {
          const parseArray = (arr) => arr.map((p) => basicParse(p));
          return Array.isArray(v) ? parseArray(v) : v === void 0 || v === null ? basicParse(v) : parseArray([basicParse(v)]);
        } else {
          return isStringNumber(v) ? Number(v) : v;
        }
      };
  }
};
export const stringComparison = (v, o, includes) => {
  console.log(
    "comparing strings",
    v,
    o,
    parseValueForComparison(v),
    o?.toString().toLowerCase(),
    parseValueForComparison(o),
    includes
  );
  return v !== void 0 && v !== null && o !== void 0 && o !== null ? includes ? parseValueForComparison(v).includes(o?.toString().toLowerCase()) : parseValueForComparison(v) === parseValueForComparison(o) : false;
};
const isDateInLast = (date, inLastMs) => new Date(date).getTime() > (/* @__PURE__ */ new Date()).getTime() - inLastMs;
const validationFunctions = (type) => {
  switch (type) {
    case "empty":
      return (a) => a === "" || a === void 0 || a === null;
    case "!empty":
      return (a) => a !== "" && a !== void 0 && a !== null;
    case "<":
      return (a, b) => new Date(a) < new Date(b);
    case ">":
      return (a, b) => new Date(a) > new Date(b);
    case "between":
      return (a, b) => new Date(a) >= new Date(b[0]) && new Date(a) <= new Date(b[1]);
    case "!between":
      return (a, b) => new Date(a) < new Date(b[0]) || new Date(a) > new Date(b[1]);
    case "==":
      return (a, b) => stringComparison(a, b);
    case "!=":
      return (a, b) => !stringComparison(a, b);
    case "!contains":
      return (a, b) => !stringComparison(a, b, true);
    case "contains":
      return (a, b) => stringComparison(a, b, true);
    case "in":
      return (a, b) => (Array.isArray(a) ? a : [a]).some(
        (v) => (Array.isArray(b) ? b : [b]).some((o) => stringComparison(v, o))
      );
    case "!in":
      return (a, b) => !(Array.isArray(a) ? a : [a]).some(
        (v) => (Array.isArray(b) ? b : [b]).some((o) => stringComparison(v, o))
      );
    case "!allin":
      return (a, b) => !(Array.isArray(b) ? b : [b]).every(
        (v) => (Array.isArray(a) ? a : [a]).some((o) => stringComparison(v, o))
      );
    case "allin":
      return (a, b) => (Array.isArray(b) ? b : [b]).every(
        (v) => (Array.isArray(a) ? a : [a]).some((o) => stringComparison(v, o))
      );
    case ">count":
      return (a, b) => (Array.isArray(a) ? a : [a]).length > (b || 0);
    case "<count":
      return (a, b) => (Array.isArray(a) ? a : [a]).length < (b || 0);
    case ">shiftDays":
      return (s, b) => Object.keys(s).reduce((a, b2) => a + (s[b2].some((ss) => ss) ? 1 : 0), 0) > (b || 0);
    case "<shiftDays":
      return (s, b) => Object.keys(s).reduce((a, b2) => a + (s[b2].some((ss) => ss) ? 1 : 0), 0) < (b || 0);
    case "<shiftMinHours":
      return (s, b) => getMinShiftHours(s) < (b || 0);
    case ">shiftMinHours":
      return (s, b) => getMinShiftHours(s) > (b || 0);
    case "<shiftMaxHours":
      return (s, b) => getMaxShiftHours(s) > (b || 0);
    case ">shiftMaxHours":
      return (s, b) => getMaxShiftHours(s) < (b || 0);
    case "lastYears":
      return (v, b) => isDateInLast(v, b * 365 * 24 * 60 * 60 * 1e3);
    case "lastDays":
      return (v, b) => isDateInLast(v, b * 24 * 60 * 60 * 1e3);
    case "lastHours":
      return (v, b) => isDateInLast(v, b * 60 * 60 * 1e3);
    case "lastMinutes":
      return (v, b) => !isDateInLast(v, b * 60 * 1e3);
    case "!lastYears":
      return (v, b) => !isDateInLast(v, b * 365 * 24 * 60 * 60 * 1e3);
    case "!lastDays":
      return (v, b) => !isDateInLast(v, b * 24 * 60 * 60 * 1e3);
    case "!lastHours":
      return (v, b) => !isDateInLast(v, b * 60 * 60 * 1e3);
    case "!lastMinutes":
      return (v, b) => !isDateInLast(v, b * 60 * 1e3);
    default:
      return (a, b) => stringComparison(a, b, true);
  }
};
export const getRuleFunc = (type, dataType, aggregation = null) => {
  const validator = validationFunctions(type);
  if (aggregation) {
    return (a, b) => {
      const val = a.map((v) => v[aggregation.column]);
      return validator(aggregate(aggregation.type, val), b);
    };
  }
  if (type.includes(".")) {
    const parser = getInputValueParser(dataType, type.split(".")[0]);
    return (a, b) => {
      const val = a?.[type.split(".")[1]];
      console.log(
        { subType: type.split(".")[0], value: parser(val) },
        "Running rule validation on subtype"
      );
      return getRuleFunc(type.split(".")[0], dataType)(parser(val), b);
    };
  }
  return (a, b) => {
    console.log("checking rule", { type, a, b, validator });
    if (a === void 0)
      return type === "empty" || type.startsWith("<") || type.startsWith("!");
    return validator(a, b);
  };
};
export const mutateCustomRule = (filters, filterer, mutator) => {
  return filters?.reduce((acc, andRule) => {
    const filteredAndRule = andRule.filter(filterer);
    if (filteredAndRule.length > 0) {
      acc.push(filteredAndRule.map(mutator));
    }
    return acc;
  }, []);
};
export const mergeRules = (rules, ...rulesToMerge) => {
  if (!rulesToMerge?.length) return rules;
  if (!rules) return rulesToMerge.flat();
  return rules.concat(...rulesToMerge);
};
export const getValidationMessage = (dataType, comparitor, value) => {
  let val = value;
  if (comparitor.includes(".")) {
    return _.startCase(comparitor.split(".")[1]) + " " + getValidationMessage(dataType, comparitor.split(".")[0], val);
  }
  if (!comparitor.includes("last")) {
    switch (dataType) {
      case "date":
      case "datetime":
        if (Array.isArray(value)) {
          val = value.map((v) => new Date(v).toLocaleDateString("en-GB"));
        } else {
          val = new Date(value).toLocaleDateString("en-GB");
        }
        break;
      default:
        null;
    }
  }
  const getBaseComparitorMessage = () => getValidationMessage("number", comparitor.slice(0, 1), val);
  if (comparitor.includes("shiftDays")) {
    return "Number of days " + _.lowerCase(getBaseComparitorMessage());
  }
  if (comparitor.includes("shiftMinHours")) {
    return "Minimum possible hours " + _.lowerCase(getBaseComparitorMessage());
  }
  if (comparitor.includes("shiftMaxHours")) {
    return "Maximum possible hours " + _.lowerCase(getBaseComparitorMessage());
  }
  const getDateWithinMessage = (span) => (comparitor.startsWith("!") ? "Cannot" : "Must") + " be within the last " + val + " " + span + (val > 1 ? "s" : "") + ".";
  if (comparitor.includes("lastYears")) {
    return getDateWithinMessage("year");
  }
  if (comparitor.includes("lastDays")) {
    return getDateWithinMessage("day");
  }
  if (comparitor.includes("lastHours")) {
    return getDateWithinMessage("hour");
  }
  if (comparitor.includes("lastMinutes")) {
    return getDateWithinMessage("minute");
  }
  switch (comparitor) {
    case "empty":
      return "Must be empty";
    case "!empty":
      return "Cannot be empty";
    case "<":
      return "Must be " + (dataType === "date" || dataType === "datetime" ? "before " : "less than ") + val;
    case ">":
      return "Must be " + (dataType === "date" || dataType === "datetime" ? "after " : "more than ") + val;
    case "between":
      return "Must be between " + val[0] + " and " + val[1];
    case "!between":
      return "Cannot be between " + val[0] + " and " + val[1];
    case "==":
      return "Must equal " + val;
    case "!=":
      return "Cannot equal " + val;
    case "!contains":
      return "Cannot contain '" + value + "'";
    case "contains":
      return "Must contain '" + value + "'";
    case "in":
      return "Must be one of " + value.join(", ");
    case "!in":
      return "Cannot be one of " + value.join(", ");
    case "allin":
      return "Must have all of " + value.join(", ");
    case "!allin":
      return "Cannot have all of " + value.join(", ");
    default:
      return "Value does not satisfy the rule '" + comparitor + " " + value + "'";
  }
};
const validateRule = (orRule, inputType, inputValue, errorOnPass) => {
  const evaluate = (value) => errorOnPass ? !value : !!value;
  const inputParser = getInputValueParser(
    orRule.inputType || inputType,
    orRule.type
  );
  const ruleParser = getRuleValueParser(
    orRule.inputType || inputType,
    //the rule value will always just be the value, not an object type like the input value, so needs to be parsed directly in all case
    orRule.type.split(".")[0],
    !!orRule.aggregation
  );
  const ruleFunc = getRuleFunc(
    orRule.type,
    inputType,
    !!orRule.aggregation && {
      type: orRule.aggregation,
      column: orRule.question.split(".")[1]
    }
  );
  const result = ruleFunc(inputParser(inputValue), ruleParser(orRule.value));
  log(
    {
      orRule,
      result,
      inputValue: inputParser(inputValue),
      ruleValue: ruleParser(orRule.value)
    },
    "Validating rule value"
  );
  return evaluate(result) ? true : getValidationMessage(
    orRule.inputType || inputType,
    orRule.type,
    ruleParser(orRule.value)
  );
};
const getProperty = (getter, rule) => _.isFunction(getter) ? getter(rule) : getter;
const mapValidationResults = (rules, inputType, inputValue, errorOnPass, enabled = () => true) => {
  return rules.map((andRule) => {
    return (Array.isArray(andRule) ? andRule : !andRule ? [] : [andRule]).map(
      (rule) => {
        const _inputType = getProperty(inputType, rule);
        const _inputValue = getProperty(inputValue, rule);
        const valueParser = getRuleValueParser(
          _inputType,
          //the rule value will always just be the value, not an object type like the input value, so needs to be parsed directly in all cases
          rule.type.split(".")[0]
        );
        const description = getValidationMessage(
          _inputType,
          rule.type,
          valueParser(rule.value)
        );
        return {
          rule,
          value: _inputValue,
          inputType: _inputType,
          description,
          result: !getProperty(enabled, rule) ? true : validateRule(
            rule,
            _inputType,
            getProperty(inputValue, rule),
            errorOnPass
          )
        };
      }
    );
  });
};
const areAllResultsTrue = (results) => results.every((orRules) => orRules.some((rule) => rule.result === true));
const areAnyResultsTrue = (results) => results.some((orRules) => orRules.some((rule) => rule.result === true));
const combineErrors = (results) => results?.map(
  (orRules) => orRules.map((rule) => typeof rule.result === "string" ? rule.result : null).filter((r) => r).join(" or ")
).join(" and ") || "";
const trueIfAllPass = (rules, inputType, inputValue, enabled = () => true) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    false,
    enabled
  );
  const haveAllPassed = areAllResultsTrue(results);
  if (haveAllPassed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};
const trueIfAnyPass = (rules, inputType, inputValue, enabled = () => true) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    false,
    enabled
  );
  const haveAnyPassed = areAnyResultsTrue(results);
  if (haveAnyPassed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};
const trueIfAllFail = (rules, inputType, inputValue, enabled = () => true) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    true,
    enabled
  );
  const haveAllFailed = areAllResultsTrue(results);
  if (haveAllFailed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};
const trueIfAnyFail = (rules, inputType, inputValue, enabled = () => true) => {
  const results = mapValidationResults(
    rules,
    inputType,
    inputValue,
    true,
    enabled
  );
  const haveAnyFailed = areAllResultsTrue(results);
  if (haveAnyFailed) return true;
  const combinedError = combineErrors(results);
  return combinedError;
};
export const validate = {
  trueIfAllFail,
  trueIfAllPass,
  trueIfAnyFail,
  trueIfAnyPass,
  validateRule,
  mapResults: mapValidationResults,
  combineErrors
};
