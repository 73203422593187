import api from "./index";
import CrudApi from "./CrudApi";
export const getFutureAcademies = () => api.get("/recruitment/academies", {
  params: {
    filters: { first: "startDateTime", comparitor: ">", second: /* @__PURE__ */ new Date() }
  }
});
export const addAcademy = (details, events) => api.post("/recruitment/academies", { details, events });
export const editAcademy = (id, data, events) => api.patch("/recruitment/academies/" + id, { data, events });
export const editAcademyEvent = (id, data) => {
  return api.patch("/recruitment/academies/events/" + id, data);
};
export const inviteToAcademy = (academyId, userIds, deadline) => api.post("/recruitment/academies/" + academyId + "/invite", {
  userIds,
  deadline
});
export const getAcademies = ({
  id,
  filters
}) => api.get("/recruitment/academies/" + (id || ""), {
  params: {
    filters
  }
});
export const getAcademyLeaders = (filters) => api.get("/recruitment/academies/leaders/", {
  params: {
    filters
  }
});
export const registerForAcademy = (id) => api.post("/recruitment/academies/" + id + "/register/");
export const stagesAPI = {
  get: (campaignId, filters) => api.get(
    "/recruitment/campaigns/" + campaignId + "/stages",
    { params: filters }
  ).then((d) => d.data),
  add: (campaignId, data) => api.post(
    "/recruitment/campaigns/" + campaignId + "/stages",
    data
  ).then((d) => d.data),
  edit: (campaignId, id, data) => api.patch(
    "/recruitment/campaigns/" + campaignId + "/stages/" + id,
    data
  ).then((d) => d.data)
};
export const applicantScoresApi = new CrudApi(
  "/recruitment/applicants/scores"
);
export const campaignsApi = new CrudApi("/recruitment/campaigns");
export const getNewApplicants = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 1e3);
  });
};
export const getApplicantSource = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 500);
  });
};
export const getDailyApplicants = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 500);
  });
};
export const getToDos = () => {
  return new Promise((res) => {
    res({
      data: []
    });
  });
};
export const getRunningCampaigns = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 3e3);
  });
};
export const getAllCampaigns = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 3e3);
  });
};
export const getRecruitRatio = () => {
  return new Promise((res) => {
    setTimeout(() => {
      res({
        data: []
      });
    }, 3e3);
  });
};
export const getApplicantPipelines = ({
  applicantId,
  filters
}) => api.get("/recruitment/applicants/" + applicantId + "/pipelines", {
  params: { filters }
}).then((d) => d.data);
export const rerunApplicantPipeline = (applicantPipelineId) => api.post("/recruitment/applicants/pipelines/" + applicantPipelineId + "/rerun").then((d) => d.data);
export const applicantsApi = new CrudApi("/recruitment/applicants");
export const rejectApplicants = (applicantIds, feedback, notificationTemplateId) => {
  return api.post("/recruitment/applicants/reject/", {
    applicantIds,
    feedback,
    notificationTemplateId
  });
};
export const acceptApplicants = (applicantIds, data) => {
  return api.post("/recruitment/applicants/accept/", {
    applicantIds,
    ...data
  });
};
export const applicantStagesApi = new CrudApi(
  "/recruitment/applicants/stages"
);
export const recruitmentStagesApi = new CrudApi(
  "/recruitment/stages"
);
