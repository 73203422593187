export const mean = (...numbers) => {
  return numbers.reduce((a, b) => a + b, 0) / numbers.length;
};
export const median = (...numbers) => {
  const sorted = [...numbers].sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);
  if (sorted.length % 2) return sorted[middle];
  return (sorted[middle - 1] + sorted[middle]) / 2;
};
export const bigNumber = (number, decimals = 2) => {
  if (isNaN(number / 1)) return "";
  const num = number >= 1e6 ? number / 1e6 : number >= 1e3 ? number / 1e3 : number;
  const suffix = number >= 1e6 ? "m" : number > 1e3 ? "k" : "";
  const dp = number > 1e3 ? 2 : 0;
  return Number(num).toLocaleString("en-GB", { maximumFractionDigits: dp }) + suffix;
};
