import React from 'react';
import { Employee } from 'apis/flex/hr';
import {
  DomainItemSelectorProps,
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
const Selector = getDomainItemSelector<Employee>('employee');
const Selected = getSelectedDomainItems('employee');
export const EmployeeSelector = (props: DomainItemSelectorProps<Employee>) => {
  return <Selector {...props} searchFields={['firstName', 'surname']} />;
};
export const SelectedEmployees = (props: SelectedDomainItemsProps) => {
  return <Selected {...props} />;
};
