import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useQuestionnaires from './useQuestionnaires';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export default () => {
  const { projectId: paramsId } = useParams();
  const projectId = Number(paramsId || useWatch({ name: 'id' }));
  return (
    <DomainTable
      domain="questionnaire"
      crudHook={useQuestionnaires}
      crudProps={{ filters: { projectId }, useFilter: !!projectId }}
      columns={[
        'name',
        'expectedDuration',
        'actualDuration',
        'expectedIncidence',
        'actualIncidence'
      ]}
    />
  );
};
