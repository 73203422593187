import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { JSXElementConstructor, useEffect, useState } from 'react';
import Rating, { RatingComponentProps } from 'react-rating';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Variant } from 'react-bootstrap/esm/types';

const RatingComponent = Rating as JSXElementConstructor<RatingComponentProps>;

const StarRating = ({
  fractions = 2,
  rating,
  handleChange = v => v,
  color = 'warning',
  disabled = false,
  ...rest
}: {
  fractions?: number;
  rating?: number;
  handleChange?: (value: number) => void;
  color?: Variant;
  disabled?: boolean;
} & RatingComponentProps) => {
  const [value, setValue] = useState(rating);
  useEffect(() => {
    if (rating && value !== rating) setValue(rating);
  }, [rating]);
  const handleChangeInner = v => {
    setValue(v);
    handleChange(v);
  };
  return (
    <RatingComponent
      fractions={fractions}
      initialRating={value}
      fullSymbol={
        <FontAwesomeIcon icon="star" className={classNames(`text-${color}`)} />
      }
      readonly={disabled}
      emptySymbol={<FontAwesomeIcon icon="star" className="text-300" />}
      onClick={handleChangeInner}
      // onChange={handleChangeInner}
      // direction={isRTL ? 'rtl' : 'ltr'}
      {...rest}
    />
  );
};

StarRating.propTypes = {
  fractions: PropTypes.number,
  rating: PropTypes.number,
  handleChange: PropTypes.func,
  color: PropTypes.string
};

export default StarRating;
