import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileId } from 'apis/types';
import classNames from 'classnames';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import { blobIcon } from 'helpers/utils';
import Skeleton from 'react-loading-skeleton';

const FileIcon = ({
  file: fileInput,
  width,
  height,
  url
}: {
  file?: FileId | any;
  width?: number;
  height?: number;
  url?: string;
}) => {
  const fileLogoStyle = {
    width: width || height || 30,
    height: height || width || 30
  };
  const { files, isLoading } = useFileLoader({ value: fileInput });
  const file = files[0];
  return isLoading ? (
    <Skeleton style={fileLogoStyle} />
  ) : file?.type?.indexOf('image') === 0 ? (
    <img
      className={classNames('rounded', {
        'opacity-25': file?.uploading
      })}
      style={fileLogoStyle}
      src={file?.preview}
      alt={file?.path || file.name}
    />
  ) : (
    (file || url) && (
      <FontAwesomeIcon
        icon={url ? 'link' : file?.icon || blobIcon(file) || 'file'}
        className={classNames('rounded', {
          'opacity-25': file?.uploading
        })}
        style={fileLogoStyle}
      />
    )
  );
};
export default FileIcon;
