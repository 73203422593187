import React from 'react';
import { useEffect, useState } from 'react';
import useActionNotification from './useActionNotification';
import { EventName } from 'apis/flex/notifications';

export default ({
  eventName,
  seen,
  actioned,
  itemId
}: {
  eventName: EventName | EventName[];
  seen?: boolean;
  actioned?: boolean;
  itemId?: number;
}) => {
  const { setSeen, setActioned } = useActionNotification({
    eventName
  });
  const [hasRunOnItem, setHasRunOnItem] = useState<number>(null);
  useEffect(() => {
    if (hasRunOnItem !== itemId) {
      if (seen && setSeen) {
        setSeen({ itemId });
      }
      if (actioned && setActioned) {
        setActioned({ itemId });
      }
      if (
        ((seen && setSeen) || !seen) &&
        ((actioned && setActioned) || !actioned)
      ) {
        setHasRunOnItem(itemId);
      }
    }
  }, [setSeen, setActioned, itemId]);
  return <></>;
};
