import PropTypes from 'prop-types';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useState } from 'react';

const LoadingButton = (props: {
  type?: 'button' | 'submit' | 'reset' | undefined;
  color?: string;
  variant?: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  children?: string | React.ReactNode;
  size?: 'sm' | 'lg';
  'data-tour'?: string;
  confirm?: string;
  compact?: boolean;
}) => {
  const [prompting, setPrompting] = useState<boolean>();
  const handleClick = () => {
    if (prompting || !props.confirm) {
      setPrompting(false);
      props.onClick && props.onClick();
    } else {
      setPrompting(true);
    }
  };
  return (
    // <div className="position-relative d-inline">
    // {props.confirm && (
    //   <span
    //     className="bg-100 fs--1 p-1 position-absolute rounded-3 start-50 text-danger text-nowrap transition transition-base translate-middle-x"
    //     style={{
    //       opacity: prompting ? 1 : 0,
    //       zIndex: prompting ? 5 : -1,
    //       top: prompting ? '-110%' : '0%'
    //     }}
    //   >
    //     {typeof props.confirm === 'string' ? props.confirm : 'Are you sure?'}
    //   </span>
    // )}
    <Button
      type={props.type || 'submit'}
      color={props.color || 'primary'}
      variant={props.variant || 'primary'}
      onClick={props.onClick && handleClick}
      style={{ zIndex: 3 }}
      className={
        props.className +
        ' d-inline-flex align-items-center justify-content-center gap-1 position-relative'
      }
      disabled={props.disabled || props.loading}
      size={props.size}
      data-tour={props['data-tour']}
    >
      {props.loading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : null}
      {props.compact && props.loading ? null : props.children}
    </Button>
    //   {prompting && (
    //     <>
    //       <Flex
    //         className="position-absolute start-0 top-0 w-100 h-100 bg-700 fs--2 text-100 rounded"
    //         style={{ zIndex: 4 }}
    //       >
    //         <Button
    //           size="sm"
    //           variant="dark"
    //           className="px-1 w-50 opacity-75"
    //           onClick={handleClick}
    //         >
    //           <FontAwesomeIcon icon={faCheck} size="sm" />
    //         </Button>
    //         <Button
    //           size="sm"
    //           variant="dark"
    //           className="px-1 w-50 opacity-75"
    //           onClick={() => setPrompting(false)}
    //         >
    //           <FontAwesomeIcon icon={faTimes} size="sm" />
    //         </Button>
    //       </Flex>
    //     </>
    //   )}
    // </div>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  confirm: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  size: PropTypes.string,
  'data-tour': PropTypes.string,
  compact: PropTypes.bool
};

export default LoadingButton;
