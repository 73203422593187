import flexAPI from ".";
import CrudApi from "./CrudApi";
export const customFormsApi = new CrudApi("forms");
export const customFormQuestionsApi = new CrudApi(
  "forms/questions"
);
export const customFormTagsApi = new CrudApi("forms/tags");
export const customFormsResponseApi = new CrudApi("forms/responses");
export const getFormDependencies = (formId) => flexAPI.get("/forms/" + formId + "/dependencies").then((d) => d.data);
export const getSystemForm = (tagName) => flexAPI.get("/forms/system", { params: { tagName } }).then((d) => d.data);
