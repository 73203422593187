import { Questionnaire } from 'apis/flex/projects';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';
import {
  DomainItemSelectorProps,
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import React from 'react';
export default (props: DomainItemSelectorProps<Questionnaire>) => {
  const Selector = getDomainItemSelector<Questionnaire>('questionnaire');
  return <Selector {...props} />;
};
export const SelectedQuestionnaires = (props: SelectedDomainItemsProps) => {
  const Selected = getSelectedDomainItems('questionnaire');
  return <Selected {...props} />;
};
