import React, {
  JSXElementConstructor,
  PropsWithRef,
  ReactElement,
  ReactNode
} from 'react';
import { OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';

const CustomTooltip = ({
  placement = 'top',
  content,
  children,
  tooltipProps
}: {
  placement?: Placement;
  content: any;
  tooltipProps?: TooltipProps;
  children:
    | ReactElement<PropsWithRef<any>, JSXElementConstructor<any>>
    | ((props: OverlayTriggerRenderProps) => ReactNode);
}) => {
  return content ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip
          {...tooltipProps}
          style={{ ...tooltipProps?.style, position: 'fixed' }}
        >
          {content}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  ) : (
    <>{children}</>
  );
};

export default CustomTooltip;
