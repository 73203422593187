import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ClientsTable from './ClientsTable';
import DomainReportTiles from 'components/app/reporting/DomainReportTiles';

export default () => {
  return (
    <Row>
      <Col xs={12}>
        <DomainReportTiles domain="client" />
      </Col>
      <Col xs={12}>
        <ClientsTable />
      </Col>
    </Row>
  );
};
