import { useQuery } from "@tanstack/react-query";
import { targetGroupStatsApi } from "apis/flex/projects";
export default ({
  targetGroupDefinition,
  ...options
}) => {
  return useQuery({
    queryKey: ["targetGroupStats", "rpu", targetGroupDefinition],
    queryFn: () => targetGroupStatsApi.rpu({
      ...targetGroupDefinition,
      costCenters: targetGroupDefinition.costCenters?.map((c) => ({
        ...c,
        projectCostCenter: c.projectCostCenter
      }))
    }),
    enabled: !!targetGroupDefinition?.id && !!targetGroupDefinition?.target && !!targetGroupDefinition?.costCenters?.length,
    staleTime: 1e3 * 60 * 5,
    ...options
  });
};
