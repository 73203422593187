import React, { ReactNode, useState } from 'react';
import {
  ArrayPath,
  FieldArray,
  FieldArrayWithId,
  FormProvider,
  Path,
  useFieldArray,
  UseFieldArrayReturn,
  useWatch
} from 'react-hook-form';
import AdvanceTableProvider, {
  Column,
  ColumnObject
} from './advance-table-v2/AdvanceTableProvider';
import AdvanceTable from './advance-table-v2/AdvanceTable';
import AdvanceEditor from './advance-table-v2/AdvanceEditor';
import { ResponsiveModal } from './Modals';
import { Button, Modal } from 'react-bootstrap';
import { faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
const UpsertModal = ({ form, show, setShow }) => {
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)} wide>
      <Modal.Body>{form}</Modal.Body>
      <Modal.Footer>
        <div className="form-text">
          Changes will be saved when the page is saved
        </div>
        <Button variant="link" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
export default <
  TFieldValues extends Record<string, any> = Record<string, any>,
  TName extends ArrayPath<TFieldValues> = ArrayPath<TFieldValues>
>({
  name,
  columns,
  form,
  defaultValues,
  fieldArray
}: {
  fieldArray: UseFieldArrayReturn<TFieldValues, TName>;
  name: TName;
  columns: Column<FieldArray<TFieldValues, TName>>[];
  form: (
    field: FieldArrayWithId<TFieldValues, TName, 'id'>,
    index: number
  ) => ReactNode;
  defaultValues?: Partial<FieldArray<TFieldValues, TName>>;
}) => {
  const data = useWatch<TFieldValues>({ name: name as Path<TFieldValues> });
  const [editing, setEditing] = useState<number>();
  return (
    <>
      <AdvanceTableProvider
        onNewClick={() => {
          fieldArray.append(defaultValues as any);
          setEditing(fieldArray.fields.length || 1);
        }}
        // onEditClick={rows => setEditing(rows[0].index)}
        actions={[
          {
            name: 'Edit',
            icon: faEdit,
            onClick: row => setEditing(row.index)
          },
          {
            name: 'Delete',
            icon: faTrash,
            variant: 'danger',
            onClick: row => {
              fieldArray.remove(row.index);
            }
          },
          {
            name: 'Duplicate',
            icon: faCopy,
            onClick: row => {
              fieldArray.append({
                ...(row.original as Record<string, any>),
                id: undefined
              } as any);
              setEditing(fieldArray.fields.length);
            }
          }
        ]}
        data={data}
        columns={columns}
      >
        <AdvanceTable />
      </AdvanceTableProvider>
      {fieldArray.fields.map((field, i) => (
        <UpsertModal
          key={field.id}
          form={form(field, i)}
          show={editing === i}
          setShow={() => setEditing(null)}
        />
      ))}
    </>
  );
};
