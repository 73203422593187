import Fuse from 'fuse.js';
import { SearchInput } from 'components/navbar/top/SearchBox';
import React, { useEffect, useState } from 'react';
import { useDebounce } from './Debouncer';
import { FormControlProps } from 'react-bootstrap';

export default <TData,>({
  data,
  keys,
  searchOptions,
  children,
  ...rest
}: {
  data: TData[];
  keys: (keyof TData & string)[];
  searchOptions?: Fuse.IFuseOptions<TData>;
  children?: (items: TData[]) => React.ReactNode;
} & Omit<FormControlProps, 'onFocus' | 'onBlur' | 'children' | 'onChange'>) => {
  const searcher = new Fuse(data, {
    includeScore: true,
    keys,
    isCaseSensitive: false,
    threshold: 0.3,
    findAllMatches: true,
    ignoreLocation: true,
    ...searchOptions
  });
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  useEffect(() => {
    if (!search) return setResults(data);
    setResults(searcher.search(search).map(d => d.item));
  }, [search]);
  if (children)
    return (
      <>
        <DebouncedSearchInput onChange={setSearch} {...rest} />
        {children(results)}
      </>
    );
  return <DebouncedSearchInput onChange={setSearch} {...rest} />;
};
export const DebouncedSearchInput = ({
  onChange,
  onPending = null,
  ms = 200,
  value,
  ...props
}: {
  onChange: (s: string) => void;
  onPending?: () => void;
  ms?: number;
} & Partial<{
  value: any;
  setValue: any;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: any;
  placeholder?: string;
  showIcon?: boolean;
}> &
  Omit<FormControlProps, 'onFocus' | 'onBlur' | 'children' | 'onChange'>) => {
  const [input, setInput] = useState(value);
  const { data: debouncedInput, isPending } = useDebounce({ data: input, ms });
  useEffect(() => {
    if (!isPending && value !== input) {
      // console.log(
      //   'debounced search heard external change',
      //   value,
      //   input,
      //   debouncedInput
      // );
      setInput(value);
    }
  }, [value]);
  useEffect(() => {
    // console.log(
    //   'debounced search heard internal change',
    //   debouncedInput,
    //   input,
    //   value
    // );
    onChange(debouncedInput);
  }, [debouncedInput]);
  useEffect(() => {
    if (isPending) {
      onPending?.();
    }
  }, [isPending]);
  return (
    <SearchInput
      isLoading={isPending}
      value={input}
      setValue={setInput}
      {...props}
    />
  );
};
