import DomainDetail from 'components/common/DomainDetail';
import React from 'react';
import useProjects from './useProjects';
import DetailCard from 'components/common/detail/DetailCard';
import { getDomainInput } from 'components/common/DomainInput';
import { Client, Project } from 'apis/flex/projects';
import { useWatch } from 'react-hook-form';
import TargetGroupsTable from './targetGroups/TargetGroupsTable';
import ProjectTeam from './ProjectTeam';
import {
  DomainItemSelector,
  getDomainItemSelector
} from 'components/common/DomainItemSelector';
import useClients from '../clients/useClients';
import ItemSelector from 'components/common/ItemSelector';
import { FiUser } from 'react-icons/fi';
import useQuotes from '../quotes/useQuotes';
import QuestionnairesTable from './questionnaires/QuestionnairesTable';
import IconButton from 'components/common/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getDomainHome } from 'hooks/useDomainRouter';
const Input = getDomainInput<Project>();
const ClientSelector = getDomainItemSelector<Client>('client');
const ClientContactSelector = () => {
  const clientId = useWatch({ name: 'clientId' });
  const { data: client } = useClients({ id: clientId, select: d => d[0] });
  return (
    !!clientId && (
      <ItemSelector
        icon={<FiUser />}
        name="clientContactId"
        label="Contact"
        data={client?.contacts?.map(c => ({
          value: c.id,
          label: c.firstName + ' ' + c.surname
        }))}
      />
    )
  );
};
const ProjectInfo = () => {
  return (
    <DetailCard id="projectDetail" title="Info">
      <DomainItemSelector
        domain="quote"
        name="quoteId"
        label="Quote"
        crudHook={useQuotes}
        readOnly
      />
      <Input name="name" label="Name" />
      <ClientSelector label="Client" name="clientId" />
      <ClientContactSelector />
      <QuestionnairesTable />
    </DetailCard>
  );
};
const ProjectTargetGroups = () => {
  const projectId = useWatch({ name: 'id' });
  const nav = useNavigate();
  return (
    <>
      <TargetGroupsTable projectId={projectId} />
      <div className="d-flex justify-content-end">
        <IconButton
          variant="falcon-primary"
          icon={faPlus}
          onClick={() =>
            nav(getDomainHome('target-group', { projectId }) + '/new')
          }
        >
          Add Target Group
        </IconButton>
      </div>
    </>
  );
};
export default () => {
  return (
    <DomainDetail domain="project" crudHook={useProjects}>
      <DetailCard id="team" title="Team">
        <ProjectTeam />
      </DetailCard>
      <ProjectInfo />
      <DetailCard id="targetGroups" title="Target Groups">
        <ProjectTargetGroups />
      </DetailCard>
    </DomainDetail>
  );
};
