import React, { useEffect, useMemo, useState } from 'react';
import AdvanceTableProvider, {
  Action,
  AdvanceTableProviderProps,
  BulkAction,
  ColumnObject
} from './AdvanceTableProvider';
import {
  CrudFilters,
  DefaultCrudData,
  defaultCrudHookBuilder,
  mergeFilters,
  Sorts,
  UseCrudProps
} from 'hooks/useDefaultCrud';
import {
  CustomOrRule,
  CustomRule,
  getValueParser,
  mergeRules
} from 'helpers/validation/validate';
import CustomTabs from '../CustomTabs';
import Skeleton from 'react-loading-skeleton';
import { ApiField } from 'apis/types';
export const CrudTabNavItem = <TData extends DefaultCrudData = any>({
  label,
  crudHook,
  crudProps,
  filters
}: {
  label: string;
  crudHook: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps: Partial<UseCrudProps<TData, TData[]>>;
  filters: CrudFilters<TData>;
}) => {
  const { counts, isLoading } = crudHook({
    ...crudProps,
    count: true,
    filters: mergeFilters(filters, crudProps?.filters),
    useFilter: true,
    data: false
  });
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>{label}</div>
      <div className="ms-1 d-flex align-items-center">
        ({isLoading ? <Skeleton width={15} /> : counts?.[0]?.count})
      </div>
    </div>
  );
};
export type CrudTableProps<
  TData extends DefaultCrudData,
  TAccessed extends TData = TData
> = Omit<
  AdvanceTableProviderProps<TData, TAccessed>,
  'data' | 'onNewClick' | 'actions' | 'bulkActions' | 'columns'
> & {
  crudHook: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps?: Partial<UseCrudProps<TData, TData[]>>;
  defaultValue?: Partial<TData>;
  onNewClick?: (defaultValue: Partial<TData>) => void;
  actions?: (data: TData[]) => Action<TAccessed>[];
  bulkActions?: (data: TData[]) => BulkAction<TAccessed>[];
  columns: CrudTableColumn<TData>[];
};
const CrudTableProvider = <
  TData extends DefaultCrudData,
  TAccessed extends TData = TData
>({
  children,
  crudHook,
  crudProps: passedCrudProps,
  isLoading,
  tabs,
  columns,
  actions,
  bulkActions,
  ...props
}: CrudTableProps<TData, TAccessed>) => {
  if (!crudHook) {
    throw new Error('Crud hook is not defined');
  }
  const crudProps = useMemo(
    (): UseCrudProps<TData, TData[]> => ({
      useFilter: true,
      staleTime: 1000 * 60 * 5,
      asList: !passedCrudProps.columns?.length,
      ...passedCrudProps,
      count: true,
      keepPreviousData: true
    }),
    [passedCrudProps]
  );
  return (
    <CustomTabs
      alignment="top"
      showTabs={!!tabs?.length}
      items={(
        tabs || [
          {
            label: 'All',
            crudFilter: {}
          }
        ]
      ).map(t => ({
        id: t.label.toLowerCase(),
        title: () => (
          <CrudTabNavItem
            label={t.label}
            crudHook={crudHook}
            crudProps={crudProps}
            filters={t.crudFilter}
          />
        ),
        content: () => (
          <CrudTableTabContent<TData, TAccessed>
            columns={columns}
            crudHook={crudHook}
            crudProps={{
              ...crudProps,
              filters: mergeFilters(crudProps?.filters, t.crudFilter)
            }}
            actions={actions}
            bulkActions={bulkActions}
            isLoading={isLoading}
            {...props}
          >
            {children}
          </CrudTableTabContent>
        )
      }))}
    />
  );
  // return tabs ? (
  //   <CustomTabs
  //     alignment="top"
  //     items={tabs.map(t => ({
  //       id: t.label.toLowerCase(),
  //       title: () => (
  //         <CrudTabNavItem
  //           label={t.label}
  //           crudHook={crudHook}
  //           crudProps={crudProps}
  //           filters={t.crudFilter}
  //         />
  //       ),
  //       content: () => (
  //         <CrudTableProvider
  //           columns={columns}
  //           crudHook={crudHook}
  //           crudProps={{
  //             ...crudProps,
  //             filters: mergeFilters(crudProps?.filters, t.crudFilter)
  //           }}
  //           error={error}
  //           isLoading={isLoading}
  //           perPage={perPage}
  //           actions={actions}
  //           bulkActions={bulkActions}
  //           onNewClick={onNewClick}
  //           {...props}
  //         >
  //           {children}
  //         </CrudTableProvider>
  //       )
  //     }))}
  //   />
  // ) : (
  //   <AdvanceTableProvider {...providerProps}>{children}</AdvanceTableProvider>
  // );
};
export type CrudTableColumn<TData extends { id: number }> =
  | ApiField<TData>
  | (ColumnObject<TData> & {
      remoteFilter?: (filter: CustomOrRule) => CustomRule;
    });
export default CrudTableProvider;
export const CrudTableTabContent = <
  TData extends { id: number } = any,
  TAccessed extends TData = TData
>({
  crudProps,
  crudHook,
  isLoading: passedIsLoading,
  perPage = 10,
  error,
  ...tableProps
}: Omit<AdvanceTableProviderProps<TData, TAccessed>, 'columns'> & {
  crudHook: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps?: Partial<UseCrudProps<TData, TData[]>>;
  columns: CrudTableColumn<TData>[];
}) => {
  // console.log('actions in tabcontent', passedActions);
  const [filters, setFilters] = useState<CustomRule>();
  const [paging, setPaging] = useState({ page: 1, pageSize: perPage });
  const [sort, setSort] = useState<Sorts<TData>>();
  const handleFilterChange = (filters: CustomRule) => {
    setFilters(filters || []);
  };
  const {
    data,
    isLoading: fetchLoading,
    error: fetchError,
    isFetching,
    meta,
    counts,
    hasValidData: _hasValidData
  } = crudHook({
    customFilter: filters,
    paging,
    sort,
    ...crudProps
  });
  const [tableData, setTableData] = useState<TData[]>([]);
  useEffect(() => {
    if (!updateCallback) {
      setTableData(data);
    }
  }, [data]);
  const [updateCallback, setUpdateCallback] = useState<() => void>(null);
  const hasValidData = () =>
    _hasValidData({
      paging,
      customFilter: filters,
      sort
    });
  useEffect(() => {
    if (hasValidData() && updateCallback) {
      updateCallback();
      setTableData(data);
      setUpdateCallback(null);
    }
  }, [isFetching, data, paging, filters]);
  const resolveColumnFilters = (filters: CustomRule): CustomRule => {
    const alteredFilters = filters
      .flatMap(and =>
        and.map(filter => {
          const col = tableProps.columns.find(
            c => typeof c !== 'string' && c.id === filter.question
          ) as CrudTableColumn<any>;
          if (typeof col === 'string') return;
          if (col?.remoteFilter) return col.remoteFilter(filter);
        })
      )
      .filter(Boolean);
    const originalsRemoved: CustomRule = filters
      .map(and =>
        and
          .map(filter => {
            const col = tableProps.columns.find(
              c => typeof c !== 'string' && c.id === filter.question
            ) as CrudTableColumn<any>;
            if (typeof col === 'string') return filter;
            if (!col?.remoteFilter) return filter;
          })
          .filter(Boolean)
      )
      .filter(Boolean);
    return mergeRules(originalsRemoved, ...alteredFilters);
  };
  const parseFilterValues = (filters: CustomRule): CustomRule =>
    filters.map(and =>
      and.map(filter => ({
        ...filter,
        value: filter.column
          ? getValueParser(
              filter.column.columnDef.meta.inputType,
              filter.type
            )(filter.value)
          : filter.value
      }))
    );
  const isRemoteLoading = (updateCallback && !hasValidData()) || fetchLoading;
  return (
    <AdvanceTableProvider<TData, TAccessed>
      {...tableProps}
      sqlColumns={meta?.columns}
      remoteData={{
        totalCount: counts?.[0]?.count,
        filters: { filter: filters, isLoading: !!isRemoteLoading },
        onFilter: (f, cb) => {
          console.log('onFilter', f, cb);
          console.log('resolveColumnFilters(f)', resolveColumnFilters(f));
          console.log(
            'parseFilterValues',
            parseFilterValues(resolveColumnFilters(f))
          );
          setUpdateCallback(() => cb);
          handleFilterChange(parseFilterValues(resolveColumnFilters(f)));
        },
        paging: {
          ...paging,
          pageIndex: paging.page - 1,
          isLoading: !!isRemoteLoading
        },
        onPaging: (p, cb) => {
          // console.log('onPaging', p, cb);
          setUpdateCallback(() => cb);
          setPaging({ page: p.pageIndex + 1, pageSize: p.pageSize });
        },
        onSort: s =>
          setSort(
            s.map(
              ss =>
                [ss.id, ss.desc ? 'desc' : 'asc'] as [
                  string & keyof TData,
                  'asc' | 'desc'
                ]
            )
          )
      }}
      filterable={(meta?.columns || [])
        .filter(c => c.TABLE_NAME === meta.table)
        .map(c => c.COLUMN_NAME)
        .concat(
          tableProps.columns.map(c => (typeof c === 'string' ? c : c.id))
        )}
      sortable={meta?.columns
        .filter(c => c.TABLE_NAME === meta.table)
        .map(c => c.COLUMN_NAME)}
      error={error || fetchError}
      data={tableData}
      perPage={perPage}
      isLoading={fetchLoading || passedIsLoading}
      columns={tableProps.columns}
    />
  );
};
