import React from 'react';
import ResourceGroupsTable from './ResourceGroupsTable';

export default () => {
  return (
    <>
      <ResourceGroupsTable />
    </>
  );
};
