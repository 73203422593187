import Flex from 'components/common/Flex';
import { ResponsiveModal } from 'components/common/Modals';
import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import usePrescreening from './hooks/usePrescreening';
import { ScreeningCheckType } from 'apis/flex/hr';
import LoadingButton from 'components/common/LoadingButton';
import { EmployeeSelector } from '../staff/widgets/EmployeeSelector';
import ActionFooter from 'components/common/ActionFooter';

export const NewPrescreeningGeneric = ({ namePrefix = '' }) => {
  const prescreening = useWatch({ name: namePrefix + 'prescreening' });

  return (
    <>
      <WizardInput
        type="select"
        multiple
        options={[
          //right to work checks will by definition include identity checks
          {
            value: 'Right to work',
            exclusive: true,
            label: 'Identity + Right to work'
          },
          { value: 'Identity', exclusive: true, label: 'Identity only' }
        ]}
        name={namePrefix + 'prescreening'}
        label="Pre-screening"
        registerProps={{
          required: false
        }}
        instruction={
          prescreening?.includes('Identity') && (
            <>
              By deselecting 'Right to work', you agree to conduct the required
              legal checks manually
            </>
          )
        }
      />
      {prescreening?.includes('Right to work') && (
        <WizardInput
          type="switch"
          name={namePrefix + 'autoScreen'}
          label="Automate screening"
          registerProps={{
            required: false,
            shouldUnregister: true
          }}
          instruction={`Automated Right to Work screening may incur additional costs.`}
        />
      )}
    </>
  );
};
export const NewPrescreening = () => {
  return (
    <>
      <EmployeeSelector name="employeeIds" label="Employee(s)" multiple />
      <NewPrescreeningGeneric />
    </>
  );
};
export const NewPrescreeningForm = ({
  defaultValues,
  onFinished
}: {
  defaultValues?: any;
  onFinished: (success: boolean) => void;
}) => {
  const methods = useForm<
    {
      prescreening: ScreeningCheckType[];
      employeeIds: number[];
      autoScreen?: boolean;
    },
    any,
    {
      prescreening: ScreeningCheckType[];
      employeeIds: number[];
      autoScreen?: boolean;
    }
  >({ defaultValues });
  const { bulkAdd, isBulkAdding } = usePrescreening({});
  useEffect(() => {
    if (defaultValues && !methods.formState.isDirty) {
      methods.reset(defaultValues);
    }
  }, [defaultValues, methods.formState.isDirty]);
  const handleSubmit = methods.handleSubmit(vals => {
    bulkAdd(
      vals.employeeIds.map(e => ({
        employeeId: e,
        checks: vals.prescreening.map(p => ({
          checkType: p,
          autoScreen: vals.autoScreen
        }))
      })),
      { onSuccess: () => onFinished(true) }
    );
  });
  return (
    <FormProvider {...methods}>
      <NewPrescreening />
      <ActionFooter
        onCancel={() => onFinished(false)}
        onSubmit={handleSubmit}
        isLoading={isBulkAdding}
      />
    </FormProvider>
  );
};
export const NewPrescreeningModal = ({
  defaultValues,
  show,
  setShow
}: {
  defaultValues?: {
    prescreening?: ScreeningCheckType[];
    employeeIds?: number[];
    autoScreen?: boolean;
  };
  show: boolean;
  setShow: (show: any) => void;
}) => {
  return (
    <ResponsiveModal show={!!show} onHide={() => setShow(null)}>
      <Modal.Header closeButton>
        <Modal.Title>New Prescreening</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewPrescreeningForm
          onFinished={() => setShow(null)}
          defaultValues={defaultValues}
        />
      </Modal.Body>
    </ResponsiveModal>
  );
};
