import React, { ReactNode } from 'react';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ItemSelectorItem, {
  SelectorItemData,
  SelectorItemProps
} from './ItemSelectorItem';
import { FiMoreHorizontal } from 'react-icons/fi';
import { domainIconWidths } from './DomainIcon';
export type ItemSelectorGroupProps = Omit<SelectorItemProps, 'data'> & {
  placeholder?: ReactNode;
  data: SelectorItemData[];
  selectedText?: (data: SelectorItemData[]) => ReactNode;
  maxShown?: number;
};
const ItemSelectorGroup = ({
  placeholder = 'Select an item',
  data,
  avatar,
  icon,
  color,
  size = 'md',
  compact = false,
  selectedText = d => d.length + ' selected',
  onNameClick,
  maxShown = 10,
  isLoading,
  bold,
  show = true,
  ...rest
}: ItemSelectorGroupProps) => {
  const avatarWidth = domainIconWidths[size];
  const compactGap = 10;
  const parent = {
    hover: len => {
      return {
        height: len * avatarWidth + (len - 1) * compactGap,
        //otherwise when it bounces it will overshoot the container
        width: '90%'
      };
    },
    initial: len => ({
      height: avatarWidth,
      width: compactGap * len + avatarWidth,
      transition: {
        when: 'afterChildren'
      }
    })
  };
  const children: Variants = {
    hover: i => {
      return {
        x: 0,
        y: (avatarWidth + 10) * i,
        // width: '100%',
        transition: {
          when: false
        }
      };
    },
    initial: i => {
      // console.log(i, compactGap);
      return {
        opacity: 1,
        x: compactGap * i,
        y: 0,
        // width: avatarWidth,
        transition: {
          when: false
        }
      };
    }
  };
  const toShow = data.slice(0, maxShown);
  const unShown = data.slice(maxShown);
  if (unShown.length) {
    toShow.push({
      label: unShown.length + ' more',
      icon: <FiMoreHorizontal />,
      color: 'secondary'
    });
  }
  // console.log(isLoading, data, toShow);
  return (
    <>
      <AnimatePresence>
        {!isLoading && !data?.length ? (
          <div className="text-500">{placeholder}</div>
        ) : (
          <motion.div
            className={classNames('position-relative', { 'my-2': !compact })}
            variants={parent}
            custom={toShow.length}
            initial={'initial'}
            whileHover={'hover'}
            transition={transitions.lightBounce}
          >
            <div className="position-absolute h-100 translate-middle-y top-50">
              {toShow.length > 1 && (
                <motion.h6
                  className="white-space-nowrap position-absolute top-50"
                  initial={{ opacity: 0, x: 0, y: '-50%' }}
                  animate={{
                    x: avatarWidth + compactGap * toShow.length,
                    y: '-50%',
                    opacity: 1
                  }}
                  variants={{ hover: { opacity: 0, x: 100 } }}
                >
                  {selectedText(data)}
                </motion.h6>
              )}
              {(isLoading ? [null] : toShow).map((row, i) => {
                // console.log('mapping ', row);
                return (
                  <motion.div
                    variants={children}
                    custom={i}
                    key={row?.value}
                    className="position-absolute"
                    style={{ zIndex: i + 1 }}
                    // initial={{ opacity: 0, x: '100px' }}
                    animate={{
                      opacity: 1,
                      x: compactGap * i,
                      // y: -avatarWidth / 2,
                      // width: avatarWidth,
                      transition: {
                        when: false
                      }
                    }}
                    // transition={transitions.lightBounceStagger(i)}
                  >
                    <ItemSelectorItem
                      avatar={avatar}
                      icon={icon}
                      color={color}
                      key={i}
                      data={row}
                      show={toShow.length === 1 && show}
                      size={size}
                      onNameClick={onNameClick}
                      bold={bold}
                      compact={compact}
                      isLoading={isLoading}
                      {...rest}
                    />
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
ItemSelectorGroup.propTypes = {
  data: PropTypes.array,
  placeholder: PropTypes.string,
  avatar: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.string
};
export default ItemSelectorGroup;
