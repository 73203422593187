import { customFormTagsApi } from "apis/flex/customForms";
import useDefaultCrud from "hooks/useDefaultCrud";
export default ({
  select,
  ...rest
} = {}) => {
  return useDefaultCrud("CustomFormTags", customFormTagsApi, {
    useFilter: true,
    select,
    staleTime: 1e3 * 60 * 10,
    ...rest
  });
};
