import { ErrorComponentLayout } from 'layouts/ErrorLayout';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import useMailerPreferences from './useMailerPreferences';
import LoadingButton from 'components/common/LoadingButton';
import Success from 'components/wizard/Success';
import useEncryptedParams from 'hooks/useEncryptedParams';
import Error500 from 'components/errors/Error500';
import PageLoader from 'components/common/PageLoader';

export default () => {
  const { data: params, isLoading } = useEncryptedParams();
  const { add, isAdding } = useMailerPreferences({
    afterSave: () => setState('added')
  });
  const [state, setState] = useState<string>();
  return (
    <ErrorComponentLayout>
      <Card>
        {/* <Card.Header>
          <Card.Title>Unsubscribe</Card.Title>
        </Card.Header> */}
        {!!isLoading && !!params ? (
          <PageLoader height={400} />
        ) : !!params && !params.email ? (
          <Error500
            error={{
              message: 'Could not get email from params',
              name: 'Name_missing'
            }}
          />
        ) : state === 'added' ? (
          <Success title="Unsubscribed!">
            Your email <b>{params?.email}</b> has successfully unsubscribed. You
            will no longer receive any emails from us.
          </Success>
        ) : (
          <Card.Body>
            Once you click "Unsubscribe", you will no longer receive emails from
            us. Please be certain before doing so.
            <br />
            {!state && (
              <LoadingButton
                variant="danger"
                className="w-100 my-4"
                onClick={() =>
                  add({
                    email: params.email,
                    notificationId: params.notificationId,
                    type: 'blacklist'
                  })
                }
                loading={!params?.email || isAdding}
              >
                Unsubscribe
              </LoadingButton>
            )}
          </Card.Body>
        )}
      </Card>
    </ErrorComponentLayout>
  );
};
