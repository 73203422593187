import { Card, Col, Dropdown, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import ReportBuilder from './ReportBuilder';
import ReportTile from './ReportTile';
import CardDropdown from 'components/common/CardDropdown';
import usePageReports from './usePageReports';
import { ResponsiveModal } from 'components/common/Modals';
import { ReportConfig } from 'apis/flex/reporting';
import { CustomRule } from 'helpers/validation/validate';
import Skeleton from 'react-loading-skeleton';
import useReportConfigs from './useReportConfigs';
import ExpandableParagraph from 'components/common/ExpandableParagraph';

export default ({
  reportId,
  config: configProp,
  simple,
  filters,
  variables
}: {
  reportId?: number;
  config?: ReportConfig;
  simple?: boolean;
  filters?: CustomRule;
  variables?: Record<string, any>;
}) => {
  const [editing, setEditing] = useState(false);
  const [viewing, setViewing] = useState(false);
  const { remove, data, isRemoving, isLoading } = usePageReports({
    filters: { reportId: reportId || configProp?.id },
    useFilter: true
  });
  const { data: loadedConfig } = useReportConfigs({
    id: reportId,
    select: data => data[0]
  });
  const config = loadedConfig || configProp;
  return (
    <Card>
      <Card.Header className="pb-0">
        <h6 className="mb-0">{isLoading ? <Skeleton /> : config?.title}</h6>
        <div className="form-text mt-0 d-inline-block">
          <ExpandableParagraph
            maxCharacters={20}
            buttonProps={{ size: 'sm', className: 'fs--2' }}
          >
            {config?.description || <> </>}
          </ExpandableParagraph>
        </div>
        <CardDropdown className="position-absolute top-0 end-0 m-1">
          <Dropdown.Item onClick={() => setEditing(true)}>Edit</Dropdown.Item>
          <Dropdown.Item
            className="text-danger"
            onClick={() => remove(data?.[0]?.id)}
          >
            Remove
          </Dropdown.Item>
        </CardDropdown>
      </Card.Header>
      <Card.Body className="pt-0">
        <div onClick={() => setViewing(true)}>
          <ReportTile
            isLoading={isRemoving || isLoading}
            simple={simple}
            reportId={reportId}
            config={config}
            variables={variables}
          />
        </div>
        <ResponsiveModal wide show={viewing} onHide={() => setViewing(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>{isLoading ? <Skeleton /> : config?.title}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <ExpandableParagraph maxCharacters={200}>
                {config?.description}
              </ExpandableParagraph>
            </div>
            <ReportTile height={'70vh'} reportId={reportId} config={config} />
          </Modal.Body>
        </ResponsiveModal>
      </Card.Body>
      <ResponsiveModal wide show={editing} onHide={() => setEditing(false)}>
        <ReportBuilder
          variables={variables}
          filters={filters}
          config={config}
          reportId={reportId}
          onSave={() => setEditing(false)}
          onCancel={() => setEditing(false)}
        />
      </ResponsiveModal>
    </Card>
  );
};
