import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React from 'react';
export type NamePrefix = `` | `${string}.`;
export const getDomainInput =
  <TData extends { id: number } = any>({
    namePrefix = ''
  }: {
    namePrefix?: NamePrefix;
  } = {}) =>
  ({
    name,
    ...props
  }: Omit<WizardInputProps, 'name'> & { name: keyof TData & string }) => {
    return <WizardInput name={namePrefix + name} {...props} />;
  };
