import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import companyConfig from 'companyConfig';

const Error403 = ({
  error,
  button
}: {
  error?: { message?: string; data?: { requiredPermissions?: string[] } };
  button?: ReactNode;
}) => {
  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">403</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Unauthorised!
        </p>
        <hr />
        <p>
          Your account is not authorised to view this content. If you believe
          this is an error, please
          <a href={'mailto:' + companyConfig.supportEmail} className="ms-1">
            contact us
          </a>
          .
        </p>
        {!!error?.data?.requiredPermissions && (
          <>
            <hr />
            <div className="text-700">
              One or more of the following permissions are needed:{' '}
              {error?.data?.requiredPermissions.map(p => {
                return <SoftBadge key={p}>{p}</SoftBadge>;
              })}
            </div>
          </>
        )}
        {!!error?.message && (
          <>
            <hr />
            <p className="text-700">The error received was {error.message}.</p>
          </>
        )}
      </Card.Body>
      {button && <Card.Footer>{button}</Card.Footer>}
    </Card>
  );
};
export default Error403;
