import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormLabel, Modal } from 'react-bootstrap';
import useMediaForm from 'hooks/useMediaForm';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import { ResponsiveModal } from 'components/common/Modals';
import LoadingButton from 'components/common/LoadingButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { contractsApi } from 'apis/flex/hr';
import { apiSuccess } from 'apis/errors';
import Flex from 'components/common/Flex';
import PdfOverlayer from './PdfOverlayer';

export const ContractPdf = () => {
  const pdf = useWatch({ name: 'fileId' });
  const { setValue } = useFormContext();
  return (
    <>
      {!pdf?.length && (
        <WizardInput
          type="file"
          pluginProps={{ accept: { 'application/pdf': [] }, maxFiles: 1 }}
          name="fileId"
          label="Upload PDF"
        />
      )}
      {!!pdf?.length && (
        <>
          <FormLabel className="d-flex">
            <p className="text-danger mb-0">*</p>Signature(s)
          </FormLabel>
          <PdfOverlayer
            pdf={pdf}
            onReset={() => setValue('fileId', [])}
            name="fields"
          />
        </>
      )}
    </>
  );
};
export const ContractPay = () => {
  const basicType = useWatch({ name: 'basicType' });
  const minimumWage = useWatch({ name: 'minimumWage' });
  const livingWage = useWatch({ name: 'livingWage' });
  const { setValue } = useFormContext();
  return (
    <>
      <WizardInput
        type="radio"
        name="basicType"
        label="Basic pay type"
        registerProps={{
          required: false
        }}
        options={['hourly', 'monthly', 'annual']}
      />
      {basicType && (
        <>
          <WizardInput
            type="currency"
            name="basicRate"
            registerProps={{
              required: false,
              onChange: () => {
                setValue('minimumWage', false);
                setValue('livingWage', false);
              }
            }}
          />
          <Flex alignItems={'center'}>
            <WizardInput
              type="switch"
              name="minimumWage"
              label="National minimum wage"
              registerProps={{
                required: false,
                onChange: () => {
                  setValue('basicRate', '');
                  setValue('livingWage', false);
                }
              }}
            />
            {minimumWage && (
              <WizardInput
                type="number"
                registerProps={{ required: false }}
                name="wageAddition"
                hideLabel
                formGroupProps={{ className: 'ms-2 p-0 fs--1' }}
                formControlProps={{ min: 0, max: 100 }}
                prefix="+"
                suffix="%"
              />
            )}
          </Flex>
          <Flex alignItems={'center'}>
            <WizardInput
              type="switch"
              name="livingWage"
              label="National living wage"
              registerProps={{
                required: false,
                onChange: () => {
                  setValue('basicRate', '');
                  setValue('minimumWage', false);
                }
              }}
            />
            {livingWage && (
              <WizardInput
                type="number"
                formGroupProps={{ className: 'ms-2 p-0 fs--1' }}
                registerProps={{ required: false }}
                name="wageAddition"
                hideLabel
                formControlProps={{ min: 0, max: 100 }}
                prefix="+"
                suffix="%"
              />
            )}
          </Flex>
        </>
      )}
    </>
  );
};
export const ContractInfo = () => {
  return (
    <>
      <WizardInput type="text" name="name" />
      <WizardInput
        registerProps={{ required: false }}
        type="switch"
        name="isActive"
      />
    </>
  );
};
export const ContractHours = () => {
  return (
    <>
      <WizardInput
        type="number"
        name="minWeeklyHours"
        label="Minimum hours p/week"
      />
    </>
  );
};
export const ContractForm = () => {
  return (
    <>
      <ContractInfo />
      <ContractPdf />
      <ContractHours />
      <ContractPay />
    </>
  );
};
const ContractFormModal = ({ show, setShow, data }) => {
  const methods = useMediaForm({
    defaultValues: { name: '', fileId: [], active: true }
  });
  useEffect(() => {
    if (data) methods.reset(data);
  }, [data]);
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: vals => {
      if (data) return contractsApi.update(data.id, vals);
      return contractsApi.create(vals);
    },
    onSuccess: () => {
      apiSuccess('Saved!');
      setShow(false);
      queryClient.invalidateQueries(['Contracts']);
    }
  });
  const handleSubmit = methods.handleSubmit(vals => {
    vals.fileId = vals.fileId[0];
    mutate(vals);
  });
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>{data ? 'Edit' : 'Create'} Contract</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <ContractForm />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <LoadingButton loading={isLoading} onClick={handleSubmit}>
          Save
        </LoadingButton>
      </Modal.Footer>
    </ResponsiveModal>
  );
};

ContractFormModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  data: PropTypes.object
};
export default ContractFormModal;
