import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
// import { timeline } from 'data/support-desk/contactDetailsData';
import { Col, Row } from 'react-bootstrap';
import UserIcon from '../app/users/widgets/UserIcon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

export type TimelineItem = {
  title: ReactNode;
  description?: ReactNode;
  icon?: IconProp;
  renderIcon?: (event: TimelineItem) => JSX.Element;
  date: Date;
  status?: { variant: string; content: string };
  userId?: number;
};
export const TimelinePlaceholder = () => {
  return (
    <div className="timeline-vertical py-0">
      {[0, 0, 0, 0].map((_, i) => (
        <div
          key={i}
          className={classNames('timeline-item', {
            'timeline-item-start': i % 2 === 0,
            'timeline-item-end': i % 2 !== 0
          })}
        >
          <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
            <Skeleton width={50} height={50} circle />
          </div>
          <Row
            className={` ${
              i % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
            }`}
          >
            <Col lg={6} className="timeline-item-time">
              <Skeleton width={150} />
            </Col>
            <Col lg={6}>
              <div className="timeline-item-content arrow-bg-white">
                <div className="timeline-item-card bg-white dark__bg-1100">
                  <h5 className="mb-2 hover-primary">
                    <Skeleton />
                  </h5>
                  <div className="border-bottom mb-3 pb-4 text-600 fs--1 w-100">
                    <Skeleton count={3} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};
const Timeline = ({ timeline }: { timeline: TimelineItem[] }) => {
  return (
    <div className="timeline-vertical py-0">
      {timeline
        ?.filter(i => i)
        .map((item, index) => {
          const { title, description, icon, date, status, userId, renderIcon } =
            item;
          return (
            <div
              key={index}
              className={classNames('timeline-item', {
                'timeline-item-start': index % 2 === 0,
                'timeline-item-end': index % 2 !== 0
              })}
            >
              <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
                {icon ? (
                  <FontAwesomeIcon icon={icon} className="fs-1" />
                ) : (
                  renderIcon && renderIcon(item)
                )}
              </div>
              <Row
                className={` ${
                  index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
                }`}
              >
                <Col lg={6} className="timeline-item-time">
                  <div>
                    <h6 className="mb-0 text-700">
                      {' '}
                      {format(date, 'dd/MM/yyyy')}
                    </h6>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="timeline-item-content arrow-bg-white">
                    <div className="timeline-item-card bg-white dark__bg-1100">
                      <h5 className="mb-2 hover-primary">{title}</h5>
                      <div className="border-bottom mb-3 pb-4 text-600 fs--1 w-100">
                        {description}
                      </div>
                      <Flex
                        wrap="wrap"
                        justifyContent={'between'}
                        alignItems={'center'}
                        className="pt-2"
                      >
                        <h6 className="mb-0 text-600 lh-base">
                          <>
                            <FontAwesomeIcon
                              icon={['far', 'clock']}
                              className="me-1"
                            />
                            {format(date, 'HH:mm')}
                          </>
                        </h6>
                        {!!userId && <UserIcon size="s" id={userId} />}
                        {status && (
                          <SoftBadge bg={status.variant}>
                            {status.content}
                          </SoftBadge>
                        )}
                      </Flex>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
    </div>
  );
};
export default Timeline;
