import React, { ReactNode } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { EventPrefix } from 'apis/flex/notifications';
import ItemSelectorGroup, { ItemSelectorGroupProps } from './ItemSelectorGroup';
import { domainConfigs } from 'components/notification/config';
export type DomainIconItemProps = {
  data: {
    avatar?: string;
    info?: Record<string, ReactNode>;
    label: string;
    icon?: ReactNode;
    color?: string;
  }[];
  domain: EventPrefix;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  placeholder?: ReactNode;
  compact?: boolean;
  maxShown?: number;
} & Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onAnimationStart' | 'onDragStart' | 'onDragEnd' | 'onDrag'
>;
const DomainItemIcon = ({
  data = [],
  domain,
  size = 'md',
  placeholder = 'N/A',
  compact,
  maxShown = 10,
  ...rest
}: ItemSelectorGroupProps & { domain: EventPrefix }) => {
  const domainConfig = domainConfigs[domain];
  return (
    <ItemSelectorGroup
      {...{
        placeholder,
        icon: domainConfig?.icon,
        color: domainConfig?.color,
        size,
        compact,
        maxShown
      }}
      data={data}
      {...rest}
    />
  );
};
export default DomainItemIcon;
