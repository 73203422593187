import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import { apiError, apiPromise, apiSuccess } from "apis/errors";
import { useMemo } from "react";
import useCrudConfig from "./useCrudConfig";
import { ensureArray } from "helpers/utils";
import { convertObjectToArray } from "apis/flex/helpers";
export const getCrudFilterFields = (filter) => {
  if (!filter) return [];
  if ("first" in filter) return [filter.first];
  if (Array.isArray(filter)) return filter.map(getCrudFilterFields).flat();
  return Object.keys(filter);
};
const mergeFilter = (filters, filtersToMerge) => {
  const ensure = (arr) => Array.isArray(arr) ? arr : [arr];
  if (!filtersToMerge) return ensure(filters);
  if (!filters) return ensure(filtersToMerge);
  const arr1 = ensure(filters);
  const arr2 = ensure(filtersToMerge);
  return [...arr1, ...arr2];
};
const convertFilter = (f) => {
  if (!f) return f;
  if (Array.isArray(f))
    return f.map(
      (ff) => convertFilter(ff)
    );
  if (!("first" in f)) {
    const typed = f;
    return Object.keys(typed).reduce(
      (acc, curr) => {
        return acc.concat({
          first: curr,
          second: typed[curr] === null ? "null" : convertObjectToArray(typed[curr])
        });
      },
      []
    );
  }
  return f;
};
export const mergeFilters = (filters, ...filtersToMerge) => {
  const merged = [];
  const addFilter = (f) => {
    const filterConverted = convertFilter(f);
    if (Array.isArray(filterConverted)) {
      merged.push(...filterConverted);
    } else {
      merged.push(filterConverted);
    }
  };
  addFilter(filters);
  if (filtersToMerge.length === 0) return filters;
  filtersToMerge.forEach((filt) => {
    if (filt) {
      addFilter(filt);
    }
  });
  return merged;
};
export const mutateAsyncToast = (fn) => {
  return (...args) => apiPromise(fn(...args), {
    pending: "Working on it...",
    success: null,
    error: null
  });
};
const cleanIds = (id) => ensureArray(id)?.filter(
  (i) => (!!i || i === 0) && (typeof i == "number" || !isNaN(i / 1))
) || [];
const useDefaultCrud = (queryKey, api, {
  countBy,
  ...props
} = {}) => {
  const crudConfig = useCrudConfig();
  const prepareParams = (params) => {
    return {
      ...params,
      //because 'column' may be appended to the filter at some point to use within the client but should never be sent to the server
      customFilter: params.customFilter?.map(
        (ands) => ands?.map((or) => ({ ...or, column: void 0 })) || []
      ),
      id: ensureArray(params.id)?.map(Number),
      includeDeleted: params.includeDeleted || void 0,
      getPublic: params.getPublic || void 0,
      asList: params.asList || void 0
    };
  };
  const {
    id,
    filters,
    select,
    enabled = true,
    staleTime = 1e3 * 60,
    useFilter = false,
    afterSave,
    includeDeleted,
    getPublic = false,
    noReturnOnChange = true,
    count,
    allowedOnly,
    beforeSave = (d) => d,
    initialData,
    sort,
    customFilter,
    paging,
    asList,
    data = true,
    infinite,
    aggregations,
    meta: enableMeta = true,
    columns,
    invalidate: _invalidate = [],
    ...rest
  } = prepareParams({ ...props, ...crudConfig });
  const enableMemo = useMemo(
    () => !!((!!cleanIds(id).length || !!filters || useFilter) && enabled && !infinite && data),
    [id, filters, useFilter, enabled, data, infinite]
  );
  const infiniteEnableMemo = useMemo(
    () => !!((!!cleanIds(id).length || !!filters || useFilter) && enabled && !!infinite && data),
    [id, filters, useFilter, enabled, infinite]
  );
  const _queryKey = [
    queryKey,
    paging,
    filters,
    customFilter,
    sort,
    aggregations,
    id,
    data,
    useFilter,
    getPublic,
    includeDeleted,
    count,
    countBy,
    allowedOnly,
    asList,
    infinite,
    columns
  ];
  const queryClient = useQueryClient();
  const hasValidData = (props2 = {}) => {
    const prepared = prepareParams(props2);
    const checkKey = [..._queryKey];
    if (prepared.paging) {
      checkKey[1] = prepared.paging;
    }
    if (prepared.filters) {
      checkKey[2] = prepared.filters;
    }
    if (prepared.customFilter) {
      checkKey[3] = prepared.customFilter;
    }
    if (prepared.sort) {
      checkKey[4] = prepared.sort;
    }
    return !!queryClient.getQueryData(checkKey);
  };
  const invalidate = async () => {
    console.log("invalidating");
    await Promise.all(
      [queryKey, ..._invalidate].map(
        (key) => queryClient.invalidateQueries({ queryKey: [key], exact: false })
      )
    );
  };
  const getData = async (updatedIds, updatedPaging) => {
    if (updatedIds || id) {
      const batcher = getPublic ? api.publicBatcher : asList ? api.listBatcher : api.batcher;
      return await Promise.all(
        ensureArray(updatedIds || id).map(batcher.fetch)
      );
    } else if (useFilter) {
      if (asList) {
        return api.getList(
          filters,
          sort,
          customFilter,
          updatedPaging || paging,
          includeDeleted,
          aggregations,
          columns
        );
      }
      if (getPublic) {
        return api.getPublic(
          filters,
          sort,
          customFilter,
          updatedPaging || paging,
          includeDeleted,
          aggregations,
          columns
        );
      }
      return api.get(
        filters,
        sort,
        customFilter,
        updatedPaging || paging,
        includeDeleted,
        aggregations,
        columns
      );
    } else {
      return Promise.resolve([]);
    }
  };
  const { data: meta, isLoading: isMetaLoading } = useQuery(
    {
      queryKey: [queryKey, "meta"],
      queryFn: () => api.meta(),
      staleTime: Infinity,
      enabled: enableMeta
    }
  );
  const dataQueryProps = {
    queryKey: _queryKey,
    queryFn: async () => {
      return await getData();
    },
    select: (d) => {
      if (d.pages && d.pageParams) {
        return {
          ...d,
          pages: d.pages.map((p) => select ? select(p) : p)
        };
      }
      return select ? select(d) : d;
    },
    enabled: enableMemo,
    staleTime: enabled && (staleTime || 1e3 * 10),
    initialData,
    ...rest
  };
  const query = useQuery(dataQueryProps);
  const infiniteQuery = useInfiniteQuery({
    ...dataQueryProps,
    enabled: infiniteEnableMemo,
    queryFn: async ({
      pageParam = { page: 1, pageSize: infinite.pageSize }
    }) => {
      if (!pageParam) return [];
      return await getData(null, pageParam);
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length === infinite.pageSize ? { page: pages.length + 1, pageSize: infinite.pageSize } : void 0;
    }
  });
  const countEnableMemo = useMemo(
    () => !!((!!filters || useFilter) && enabled && (count || countBy)),
    [filters, useFilter, count, countBy, enabled]
  );
  const { data: counts, isLoading: isCountsLoading } = useQuery({
    queryKey: [..._queryKey, "counts"],
    queryFn: () => {
      if (useFilter) {
        return api.counts(
          countBy,
          filters,
          allowedOnly,
          includeDeleted,
          customFilter
        ).then((d) => d.map((c) => ({ ...c, count: Number(c.count) })));
      } else {
        return Promise.resolve(null);
      }
    },
    enabled: countEnableMemo,
    staleTime: enabled && (staleTime || 1e3 * 10),
    ...rest
  });
  const {
    mutate: update,
    isLoading: isUpdating,
    error: updateError,
    mutateAsync: updateAsync
  } = useMutation({
    mutationFn: ({ id: id2, data: data2, isSelf, noReturn = noReturnOnChange }) => isSelf ? api.selfUpdate(id2, beforeSave(data2), noReturn) : api.update(id2, beforeSave(data2), noReturn),
    onMutate: async ({ id: id2, data: data2, isSelf, noReturn }) => {
      const _queryKey2 = [queryKey, id2, filters, useFilter, getPublic];
      await queryClient.cancelQueries({ queryKey: _queryKey2 });
      const previous = queryClient.getQueryData(_queryKey2);
      queryClient.setQueryData(_queryKey2, {
        id: id2,
        data: beforeSave(data2),
        isSelf,
        noReturn
      });
      return { previous, updated: { id: id2, data: data2, isSelf, noReturn } };
    },
    onSuccess: (data2) => {
      invalidate();
      if (afterSave) {
        return afterSave(data2);
      }
      apiSuccess("Updated!");
    },
    onError: (err, data2, context) => {
      queryClient.setQueryData(_queryKey, context.previous);
      apiError(err);
    }
  });
  const {
    mutate: bulkUpdate,
    isLoading: isBulkUpdating,
    error: bulkUpdateError,
    mutateAsync: bulkUpdateAsync
  } = useMutation({
    mutationFn: ({ ids, data: data2, noReturn = noReturnOnChange }) => api.updateBulk({ id: ids }, beforeSave(data2), noReturn),
    onMutate: async ({ data: data2, isSelf, noReturn }) => {
      const _queryKey2 = [queryKey, id, filters, useFilter, getPublic];
      await queryClient.cancelQueries({ queryKey: _queryKey2 });
      const previous = queryClient.getQueryData(_queryKey2);
      queryClient.setQueryData(_queryKey2, {
        id,
        data: beforeSave(data2),
        isSelf,
        noReturn
      });
      return { previous, updated: { id, data: data2, isSelf, noReturn } };
    },
    onSuccess: (data2) => {
      invalidate();
      if (afterSave) {
        return afterSave(data2);
      }
      apiSuccess("Updated!");
    },
    onError: (err, data2, context) => {
      queryClient.setQueryData(_queryKey, context.previous);
      apiError(err);
    }
  });
  const {
    mutate: add,
    isLoading: isAdding,
    error: addError,
    mutateAsync: addAsync
  } = useMutation({
    mutationFn: ({ vals, isSelf, noReturn = noReturnOnChange }) => isSelf ? api.selfInsert(beforeSave(vals), noReturn) : api.insert(beforeSave(vals), noReturn),
    onSuccess: (data2) => {
      invalidate();
      if (afterSave) {
        return afterSave(data2);
      }
      apiSuccess("Created!");
    }
  });
  const {
    mutate: clone,
    isLoading: isCloning,
    error: cloneError,
    mutateAsync: cloneAsync
  } = useMutation({
    mutationFn: ({ id: id2, noReturn = noReturnOnChange }) => api.clone(id2, noReturn),
    onSuccess: (data2) => {
      invalidate();
      if (afterSave) {
        return afterSave(data2);
      }
      apiSuccess("Created!");
      queryClient.refetchQueries(_queryKey);
    }
  });
  const {
    mutate: remove,
    isLoading: isRemoving,
    error: removeError,
    mutateAsync: removeAsync
  } = useMutation({
    mutationFn: (id2) => api.remove(id2),
    onSuccess: (data2) => {
      invalidate();
      if (afterSave) {
        return afterSave(data2);
      }
      apiSuccess("Removed!");
      queryClient.refetchQueries(_queryKey);
    }
  });
  const {
    mutate: bulkRemove,
    isLoading: isBulkRemoving,
    error: bulkRemoveError,
    mutateAsync: bulkRemoveAsync
  } = useMutation({
    mutationFn: (ids) => api.bulkRemove(ids),
    onSuccess: (data2) => {
      invalidate();
      if (afterSave) {
        return afterSave(data2);
      }
      apiSuccess("Removed!");
      queryClient.refetchQueries(_queryKey);
    }
  });
  const {
    mutate: bulkAdd,
    isLoading: isBulkAdding,
    error: bulkAddError,
    mutateAsync: bulkAddAsync
  } = useMutation({
    mutationFn: ({ vals, noReturn = noReturnOnChange }) => api.insertBulk(beforeSave(vals), noReturn),
    onSuccess: () => {
      invalidate();
      if (afterSave) {
        return afterSave(data);
      }
      apiSuccess("Created!");
    }
  });
  const upsert = (async) => (vals, done = () => {
  }, isSelf, noReturn) => {
    if (vals.id) {
      if (async) {
        return updateAsync({ id: vals.id, data: vals, isSelf, noReturn });
      }
      update(
        { id: vals.id, data: vals, isSelf, noReturn },
        {
          onSuccess: (d) => done(d)
        }
      );
    } else {
      if (async) {
        return addAsync({ vals, isSelf, noReturn });
      }
      add(
        { vals, isSelf, noReturn },
        {
          onSuccess: (d) => done(d)
        }
      );
    }
  };
  const interceptors = {
    updateSelf: (async) => (vals, opts) => async === true ? updateAsync({ ...vals, isSelf: true }, opts) : update({ ...vals, isSelf: true }, opts),
    add: (async) => (vals, opts, noReturn) => async ? addAsync({ vals, isSelf: false, noReturn }, opts) : add({ vals, isSelf: false, noReturn }, opts),
    clone: (async) => (id2, opts, noReturn) => async ? cloneAsync({ id: id2, isSelf: false, noReturn }, opts) : clone({ id: id2, isSelf: false, noReturn }, opts),
    addSelf: (async) => (vals, opts, noReturn) => async ? addAsync({ vals, isSelf: true, noReturn }, opts) : add({ vals, isSelf: true, noReturn }, opts),
    upsertSelf: (async) => (vals, done, noReturn) => upsert(async)({ ...vals }, done, true, noReturn),
    bulkAdd: (async) => (vals, opts, noReturn) => async ? bulkAddAsync({ vals, noReturn }, opts) : bulkAdd({ vals, noReturn }, opts)
  };
  return {
    ...query,
    ...infiniteQuery,
    hasValidData,
    data: query.data,
    infiniteData: infiniteQuery.data,
    meta,
    counts,
    queryError: query.error,
    error: addError || cloneError || removeError || bulkAddError || updateError || bulkUpdateError || bulkRemoveError,
    isLoading: enableMemo && query.isLoading || countEnableMemo && isCountsLoading,
    remove,
    isRemoving,
    update,
    upsert: upsert(false),
    bulkUpdate,
    isBulkUpdating,
    isUpdating,
    isAdding,
    isCloning,
    isUpserting: isUpdating || isAdding,
    isBulkAdding,
    isBulkRemoving,
    // ...interceptors,
    add: interceptors.add(false),
    addSelf: interceptors.addSelf(false),
    updateSelf: interceptors.updateSelf(false),
    clone: interceptors.clone(false),
    bulkAdd: interceptors.bulkAdd(false),
    upsertSelf: interceptors.upsertSelf(false),
    addAsync: mutateAsyncToast(interceptors.add(true)),
    addSelfAsync: mutateAsyncToast(interceptors.addSelf(true)),
    updateSelfAsync: mutateAsyncToast(interceptors.updateSelf(true)),
    cloneAsync: mutateAsyncToast(interceptors.clone(true)),
    bulkAddAsync: mutateAsyncToast(interceptors.bulkAdd(true)),
    upsertSelfAsync: mutateAsyncToast(interceptors.upsertSelf(true)),
    removeAsync: mutateAsyncToast(removeAsync),
    updateAsync: mutateAsyncToast(updateAsync),
    bulkUpdateAsync: mutateAsyncToast(bulkUpdateAsync),
    upsertAsync: mutateAsyncToast(upsert(true)),
    bulkRemove,
    bulkRemoveAsync: mutateAsyncToast(bulkRemoveAsync)
  };
};
export const defaultCrudHookBuilder = (key, api, defaultProps) => ({
  countBy,
  ...props
} = {}) => {
  const builder = createBuilder(key, api, {
    ...defaultProps,
    ...props
  });
  return builder(countBy);
};
const createBuilder = (key, api, props) => {
  return (countBy) => {
    return useDefaultCrud(key, api, {
      ...props,
      countBy
    });
  };
};
export default useDefaultCrud;
