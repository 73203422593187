import { useQuery } from '@tanstack/react-query';
import { getDomainConfigs } from 'apis/flex/reporting';
import { RoleDomain } from 'apis/flex/users';
import useUserRoles from 'hooks/useUserRoles';
import { useMemo } from 'react';
export type DbAccessString =
  | `${string}`
  | `${string}.${string}`
  | `${string}.${string}.${string}`;
export default () => {
  const { userRoles } = useUserRoles();
  const { data: domainConfig } = useQuery({
    queryKey: ['DomainConfigs'],
    queryFn: () => getDomainConfigs(),
    staleTime: Infinity
  });
  const canAccess = (db: string, table?: string, field?: string) => {
    return (
      userRoles.super ||
      Object.keys(userRoles).some((domain: RoleDomain) =>
        domainConfig?.[domain]?.access.some(
          a =>
            a === db + '.' + table + '.' + field ||
            a === db + '.' + table ||
            a === db
        )
      )
    );
  };
  const getRequiredRoles = (db: string, table?: string, field?: string) => {
    return (
      domainConfig &&
      Object.keys(domainConfig)
        .filter((d: RoleDomain) =>
          domainConfig[d].access.some(
            a =>
              a === db + '.' + table + '.' + field ||
              a === db + '.' + table ||
              a === db
          )
        )
        .map(d => `${d}.view`)
    );
  };
  return useMemo(
    () => ({
      canAccess,
      getRequiredRoles
    }),
    [domainConfig, userRoles]
  );
};
