import { AppNotification, appNotificationsApi } from 'apis/flex/notifications';
import useDefaultCrud, { UseCrudProps } from 'hooks/useDefaultCrud';

export default <T = AppNotification[],>(
  props: UseCrudProps<AppNotification, T> = {}
) => {
  return useDefaultCrud('AppNotifications', appNotificationsApi, {
    staleTime: 1000 * 60 * 5,
    ...props
  });
};
