import React from 'react';
import {
  ProcessedColumn,
  useAdvanceTable
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import { HTMLProps } from 'react';
import { camelToSentence, getCommonDataValues } from 'helpers/utils';
import { Row } from '@tanstack/react-table';
import { ResponsiveModal } from 'components/common/Modals';
import { Button, Modal } from 'react-bootstrap';
import DomainItemIcon from 'components/common/DomainItemIcon';
import { useQuery } from '@tanstack/react-query';
import { FilterValueInput } from 'components/common/advance-table-v2/AdvanceTableFilters';
import Flex from 'components/common/Flex';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { FormProvider, useFormContext, useWatch } from 'react-hook-form';
import useMediaForm from 'hooks/useMediaForm';
import { domainConfigs } from 'components/notification/config';
import { EventPrefix } from 'apis/flex/notifications';
import LoadingButton from '../LoadingButton';
import { DefaultCrudData, UseDefaultCrudReturn } from 'hooks/useDefaultCrud';
import { CrudHook } from '../DomainItemSelector';
const useColumnOptions = <TData extends { id?: number } = any>(
  column: ProcessedColumn<TData>
) => {
  const def = column?.columnDef.meta;
  const query = def?.optionsQuery;
  const options = def?.options;
  return useQuery({
    queryKey: ['SqlOptions', column?.columnDef.id, query, options],
    ...(query || {}),
    initialData: options,
    enabled: !!query && !!column,
    staleTime: Infinity
  });
};
const EditorField = <TData extends { id?: number } = any>({
  field,
  inputProps,
  ...rest
}: {
  field: string;
  inputProps?: WizardInputProps;
} & HTMLProps<HTMLDivElement>) => {
  const { getColumn } = useAdvanceTable();
  const column = getColumn(field) as ProcessedColumn<TData>;
  const { data: options } = useColumnOptions(column);
  const active = useWatch({ name: field + '.active' });
  const { setValue } = useFormContext();
  // console.log('rendering editing field', field, column);
  if (!column) return null;
  return (
    <Flex alignItems={'start'} className={'w-100 position-relative'} {...rest}>
      <WizardInput
        name={field + '.active'}
        type="switch"
        registerProps={{ required: false }}
        hideLabel
      />
      {(typeof column.columnDef.meta.editor === 'function' &&
        column.columnDef.meta.editor?.({
          inputProps: { ...inputProps, name: field + '.value' },
          options,
          active
        })) || (
        <FilterValueInput
          label={camelToSentence(field)}
          hideLabel={false}
          formGroupProps={{ className: 'flex-1' }}
          column={{
            id: field,
            inputType: column.columnDef.meta.type,
            ...column.columnDef.meta,
            filter:
              typeof column.columnDef.meta.filter === 'boolean'
                ? undefined
                : column.columnDef.meta.filter
          }}
          size="md"
          options={options}
          getFilterName={x => field + `.${x}`}
          {...inputProps}
          type={
            typeof column.columnDef.meta.editor === 'string'
              ? column.columnDef.meta.editor
              : column.columnDef.meta.type
          }
          multiple={!!column.columnDef.meta.multiple}
        />
      )}
      {!active && (
        <div
          className="position-absolute w-100 h-100 bg-300 bg-opacity-10"
          onClick={() => {
            setValue(field + '.active', true);
            // setValue(getFilterName('type'), types[0].value);
            // setFocus(getFilterName('name'));
          }}
        ></div>
      )}
    </Flex>
  );
};
type EditorValue<TData extends { id?: number } = any> = {
  active: boolean;
  value: TData;
  type: '==';
};
export type AdvanceEditorProps<
  TData extends DefaultCrudData = any,
  TFormData extends { id?: number } = TData
> = {
  data?: TData[];
  crudHook?: CrudHook<TData>;
  domain?: EventPrefix;
  onUpdate?: (rows: Partial<Row<TData>>[], data: Partial<TData>) => void;
  isUpdating?: boolean;
  fields?: (keyof TFormData & string)[];
  loadMutator?: (data: TData) => TFormData;
  saveMutator?: (data: TFormData & { id: number }) => TData;
};
const AdvanceEditor = <TData extends DefaultCrudData = any, TFormData = TData>({
  loadMutator = d => d as unknown as TFormData,
  saveMutator = d => d as unknown as TData,
  domain,
  data,
  onUpdate,
  isUpdating,
  crudHook,
  fields
}: AdvanceEditorProps<TData, TFormData>) => {
  const domainConfig = domainConfigs[domain];
  const { editing, setEditing } = useAdvanceTable<TData>();
  const rows = editing;
  const commonData = getCommonDataValues(
    rows?.map(x => loadMutator(x.original))
  );
  const defaultValues = fields.reduce(
    (a, b) => ({
      ...a,
      [b]: {
        active: false,
        value: commonData[b] || '',
        type: '=='
      }
    }),
    {}
  );
  // console.log('loading edit values', rows, commonData, defaultValues);
  const methods = useMediaForm<Record<string, EditorValue<TFormData>>>({
    values: defaultValues,
    defaultValues
  });
  const apiUpdater = crudHook();
  const getUpdater = () => {
    if (onUpdate) {
      return v => onUpdate(rows, saveMutator(v));
    } else {
      return v =>
        apiUpdater.bulkUpdate(
          { ids: rows.map(r => r.original.id), data: saveMutator(v) },
          { onSuccess: () => setEditing([]) }
        );
    }
  };
  const handleSubmit = methods.handleSubmit(vals => {
    const activeVals = Object.keys(vals).reduce((a, b) => {
      if (vals[b].active) {
        a[b] = vals[b].value;
      }
      return a;
    }, {} as Partial<TData>);
    const updater = getUpdater();
    updater(activeVals);
  }, console.error);
  // console.log('editing in editor', rows);
  return (
    <ResponsiveModal wide show={rows?.length > 0} onHide={() => setEditing([])}>
      <FormProvider {...methods}>
        <Modal.Body>
          <div className="p-3">
            <h6>
              {'Editing ' +
                (rows?.length || 0) +
                ' items' +
                (rows?.length === 1 ? '' : 's')}
            </h6>
            {domain && (
              <DomainItemIcon
                size="sm"
                domain={domain}
                data={rows?.map(r => domainConfig.format(r.original))}
              />
            )}
          </div>
          {fields?.map(field => (
            <EditorField key={field} field={field} />
          ))}
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button variant="link" onClick={() => setEditing([])}>
            Cancel
          </Button>
          <LoadingButton
            loading={isUpdating || apiUpdater?.isBulkUpdating}
            onClick={handleSubmit}
          >
            Update
          </LoadingButton>
        </Modal.Footer>
      </FormProvider>
    </ResponsiveModal>
  );
};
export default AdvanceEditor;
