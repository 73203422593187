import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import AdvanceTableProvider, {
  Action,
  AdvanceTableProviderProps,
  BulkAction,
  Column
} from 'components/common/advance-table-v2/AdvanceTableProvider';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CrudFilters,
  DefaultCrudData,
  defaultCrudHookBuilder,
  getCrudFilterFields,
  mergeFilters,
  UseCrudProps
} from 'hooks/useDefaultCrud';
import AdvanceEditor, {
  AdvanceEditorProps
} from 'components/common/advance-table-v2/AdvanceEditor';
import { domainConfigs } from 'components/notification/config';
import { DomainConfig } from 'config';
import { EventPrefix } from 'apis/flex/notifications';
import { useNavigate } from 'react-router-dom';
import {
  faCopy,
  faEdit,
  faEye,
  faEyeSlash,
  faTrash,
  faTrashRestore,
  faUndoAlt
} from '@fortawesome/free-solid-svg-icons';
import { domainToSentence } from './DomainTimeline';
import AdvanceTableTabProvider, {
  NavItem,
  NavItemProps,
  TabItem
} from './advance-table-v2/AdvanceTableTabProvider';
import CrudTableProvider, {
  CrudTableProps,
  CrudTabNavItem
} from './advance-table-v2/CrudTableProvider';
import { getDomainHome, getDomainItemUrl } from 'hooks/useDomainRouter';
import { Row } from '@tanstack/react-table';
import CustomTabs from './CustomTabs';
import { ApiField } from 'apis/types';
import classNames from 'classnames';

type DomainTableBaseProps<TData extends DefaultCrudData, TFormData = TData> = {
  tabs?: (Omit<TabItem<TData, TData>, 'crudFilter'> & {
    crudFilter: CrudFilters<TData>;
  })[];
  editFields?: (keyof TFormData & string)[];
  bulkEditFields?: (keyof TFormData & string)[];
  domain: EventPrefix;
  columns: Column<TData>[];
  crudHook?: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps?: Partial<UseCrudProps<TData, TData[]>>;
  editorProps?: Partial<AdvanceEditorProps<TData, TFormData>>;
  actions?: Action<TData>[] | ((data: TData[]) => Action<TData>[]);
  bulkActions?: BulkAction<TData>[] | ((data: TData[]) => BulkAction<TData>[]);
  selectAll?: boolean;
};
export type DomainTableProps<
  TData extends DefaultCrudData = DefaultCrudData,
  TFormData = TData
> = DomainTableBaseProps<TData, TFormData> &
  Partial<
    Omit<
      AdvanceTableProviderProps<TData>,
      'tabs' | 'remoteData' | 'actions' | 'bulkActions'
    >
  >;

export type RemoteDomainTableProps<
  TData extends DefaultCrudData,
  TFormData
> = Omit<DomainTableBaseProps<TData, TFormData>, 'columns'> &
  Omit<
    CrudTableProps<TData>,
    'tabs' | 'remoteData' | 'actions' | 'bulkActions'
  >;

export const RemoteDomainTable = <
  TData extends DefaultCrudData = any,
  TFormData = TData
>({
  domain,
  crudHook,
  editFields,
  crudProps,
  editorProps,
  tabs,
  // remote,
  actions,
  bulkActions,
  isLoading,
  selectAll,
  ...props
}: RemoteDomainTableProps<TData, TFormData>) => {
  const tableProps = useDomainTableProps<TData>({
    ...props,
    domain,
    actions,
    bulkActions,
    crudHook,
    crudProps,
    selectAll,
    columns: props.columns,
    isLoading,
    editFields
  });
  return (
    <CrudTableProvider<TData>
      isLoading={isLoading}
      tabs={tabs}
      {...tableProps}
      {...props}
      crudHook={tableProps.crudHook}
    >
      <AdvanceTable />
      {editFields && (
        <AdvanceEditor
          domain={domain as EventPrefix}
          crudHook={crudHook}
          fields={editFields}
          {...editorProps}
        />
      )}
    </CrudTableProvider>
  );
};
const useDomainTableProps = <
  TData extends { id: number; deletedDate?: Date; deletedBy?: number } = {
    id: number;
    deletedDate?: Date;
    deletedBy?: number;
  }
>({
  columns,
  domain,
  actions = [],
  bulkActions = [],
  crudHook: passedCrudHook,
  crudProps: passedCrudProps,
  selectAll,
  isLoading,
  editFields,
  editable,
  tableSettings,
  bulkEditFields
}: Pick<
  DomainTableProps<TData, any>,
  | 'domain'
  | 'actions'
  | 'bulkActions'
  | 'crudHook'
  | 'crudProps'
  | 'selectAll'
  | 'columns'
  | 'isLoading'
  | 'editFields'
  | 'editable'
  | 'tableSettings'
  | 'bulkEditFields'
>): Partial<CrudTableProps<TData>> => {
  const config = domainConfigs[domain] as DomainConfig<TData>;
  const crudHook = useMemo(
    () => passedCrudHook ?? config.crudHook,
    [config, passedCrudHook]
  );
  const nav = useNavigate();
  const handleEdit = useCallback(
    (row: Row<TData>) => {
      nav(
        getDomainItemUrl(domain, row.original) ||
          getDomainHome(domain, row.original) + '/' + row.original.id.toString()
      );
    },
    [domain]
  );
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const crudProps: Partial<UseCrudProps<TData, TData[]>> = useMemo(
    () => ({
      ...passedCrudProps,
      includeDeleted,
      useFilter: true,
      asList: !columns && !selectAll,
      enabled:
        !isLoading && (!passedCrudProps || passedCrudProps?.enabled !== false),
      columns: selectAll
        ? undefined
        : columns
        ? (columns
            .map(c => (typeof c === 'string' ? c : c.id))
            .concat(
              getCrudFilterFields(passedCrudProps?.filters).map(
                c => c.split('.')[0] as ApiField<TData>
              )
            )
            .concat(
              passedCrudProps?.customFilter?.flatMap(f =>
                Object.keys(
                  f.map(ff => ff.question.split('.')[0] as ApiField<TData>)
                )
              )
            )
            .filter(Boolean) as ApiField<TData>[])
        : // .map(c => c.split('.')[0] as ApiField<TData>)
          undefined
    }),
    [columns, passedCrudProps, includeDeleted]
  );
  const {
    cloneAsync,
    removeAsync,
    bulkRemoveAsync,
    bulkUpdateAsync,
    updateAsync,
    meta
  } = crudHook({
    noReturnOnChange: crudProps.noReturnOnChange,
    afterSave: crudProps.afterSave,
    invalidate: crudProps.invalidate,
    data: false
  });
  // console.log('editFields in useProps', editFields);
  return useMemo(
    () => ({
      title: domainToSentence(domain) + 's',
      autoFocus: true,
      sqlDb: config?.sqlDb,
      sqlTables: [config.sqlTable],
      domain,
      tableSettings: meta?.columns.find(c => c.COLUMN_NAME === 'deletedBy')
        ? [
            {
              label: 'Show deleted',
              value: includeDeleted,
              onChange: b => {
                // console.log('show deleted changed', b);
                setIncludeDeleted(b);
              }
            },
            ...(tableSettings || [])
          ]
        : tableSettings,
      columns: [
        {
          id: 'isActive',
          header: '',
          maxSize: 30,
          formatter: v => {
            return v() === undefined ? (
              <></>
            ) : (
              <div
                className={classNames(
                  `bg-${v() ? 'success' : 'secondary'}`,
                  'rounded-circle'
                )}
              />
            );
          }
        } as Column<TData>
      ].concat(columns),
      crudProps,
      crudHook,
      editable: editable || !!editFields || !!bulkEditFields,
      globalSearchFields: config.searchFields as (keyof TData & string)[],
      actions: data => [
        {
          name: 'Edit',
          onClick: editFields ? undefined : handleEdit,
          icon: faEdit,
          isEdit: true
        },
        {
          name: 'Duplicate',
          onClick: row => cloneAsync(row.original.id),
          icon: faCopy
        },
        {
          name: r => (r.original.deletedDate ? 'Restore' : 'Delete'),
          variant: 'danger',
          confirm: r =>
            (r.original.deletedDate ? 'Restore' : 'Delete') + ' this record?',
          onClick: (row, done) => {
            if (row.original.deletedDate) {
              updateAsync(
                {
                  id: row.original.id,
                  data: { deletedDate: null, deletedBy: null } as Partial<TData>
                },
                { onSuccess: done }
              );
            } else {
              removeAsync(row.original.id, { onSuccess: done });
            }
          },
          icon: r => (r.original.deletedDate ? faTrashRestore : faTrash)
        },
        ...(typeof actions === 'function' ? actions(data) : actions)
      ],
      bulkActions: data => [
        {
          name: rows =>
            rows.every(r => !!r.original.deletedDate) ? 'Restore' : 'Delete',
          confirm: rows =>
            (rows.every(r => !!r.original.deletedDate) ? 'Restore' : 'Delete') +
            ' all ' +
            rows.length +
            ' records?',
          actionFn: (rows, done) => {
            if (rows.every(r => !!r.original.deletedDate)) {
              return bulkUpdateAsync(
                {
                  ids: rows.map(r => r.original.id),
                  data: { deletedDate: null, deletedBy: null } as TData
                },
                { onSuccess: done }
              );
            } else {
              // console.log('deleting', rows);
              return bulkRemoveAsync(
                rows.map(r => r.original.id),
                { onSuccess: done }
              );
            }
          },
          icon: rows =>
            rows.every(r => !!r.original.deletedDate) ? faTrashRestore : faTrash
        },
        ...(typeof bulkActions === 'function' ? bulkActions(data) : bulkActions)
      ],
      onRowClick: editFields ? undefined : handleEdit,
      onNewClick: editFields
        ? undefined
        : defaultValue => {
            if (defaultValue) {
              delete defaultValue.id;
            }
            //need a defaultValue in case things like parentId are required to resolve domain home
            nav(getDomainHome(domain, defaultValue) + '/new');
          }
    }),
    [domain, handleEdit, crudProps, editFields, includeDeleted, meta]
  );
};
export const DomainTable = <
  TData extends DefaultCrudData = any,
  TFormData = TData
>({
  domain,
  crudHook: passedCrudHook,
  editFields,
  crudProps: passedCrudProps,
  editorProps,
  tabs,
  // remote,
  actions,
  bulkActions,
  isLoading: passedIsLoading,
  selectAll,
  ...props
}: DomainTableProps<TData, TFormData>) => {
  const { crudProps, crudHook } = useDomainTableProps<TData>({
    ...props,
    domain,
    actions,
    bulkActions,
    crudHook: passedCrudHook,
    crudProps: passedCrudProps,
    selectAll,
    columns: props.columns,
    isLoading: passedIsLoading,
    editFields
  });
  return passedIsLoading ? (
    <AdvanceTableProvider data={[]} columns={['id']} isLoading>
      <AdvanceTable isLoading />
    </AdvanceTableProvider>
  ) : (
    <CustomTabs
      alignment="top"
      showTabs={!!tabs?.length}
      items={(
        tabs || [
          {
            label: 'All',
            crudFilter: {}
          }
        ]
      ).map(t => ({
        id: t.label.toLowerCase(),
        title: () => (
          <CrudTabNavItem
            label={t.label}
            crudHook={crudHook}
            crudProps={crudProps}
            filters={t.crudFilter}
          />
        ),
        content: () => (
          <DomainTableProvider
            domain={domain}
            actions={actions}
            bulkActions={bulkActions}
            tabs={tabs}
            editFields={editFields}
            crudHook={passedCrudHook}
            crudProps={{
              ...crudProps,
              filters: mergeFilters(passedCrudProps?.filters, t.crudFilter)
            }}
            isLoading={passedIsLoading}
            selectAll={selectAll}
            editorProps={editorProps}
            {...props}
          >
            <AdvanceTable />
          </DomainTableProvider>
        )
      }))}
    />
  );
};
export const DomainTableProvider = <
  TData extends { id: number } = any,
  TFormData = TData
>({
  domain,
  actions: passedActions,
  bulkActions: passedBulkActions,
  tabs,
  crudHook: passedCrudHook,
  crudProps: passedCrudProps,
  isLoading: passedIsLoading,
  selectAll,
  editFields,
  editorProps,
  children,
  bulkEditFields,
  ...props
}: DomainTableProps<TData, TFormData>) => {
  const { crudProps, crudHook, actions, bulkActions, ...tableProps } =
    useDomainTableProps<TData>({
      ...props,
      domain,
      actions: passedActions,
      bulkActions: passedBulkActions,
      crudHook: passedCrudHook,
      crudProps: passedCrudProps,
      selectAll,
      columns: props.columns,
      isLoading: passedIsLoading,
      editFields
    });
  // console.log('props in tabcontent', tableProps, props, editFields);
  const { isLoading, data } = crudHook({
    ...crudProps,
    useFilter: true
  });
  return (
    <AdvanceTableProvider<TData>
      {...tableProps}
      {...props}
      actions={actions?.(data)}
      bulkActions={bulkActions?.(data)}
      tabs={tabs}
      data={data}
      isLoading={isLoading || passedIsLoading}
      columns={tableProps.columns.concat(
        (crudProps?.includeDeleted
          ? ['deletedDate', { id: 'deletedBy', domain: 'user' }]
          : []) as Column<TData>[]
      )}
    >
      <>
        {children}
        {(editFields || bulkEditFields) && (
          <AdvanceEditor
            domain={domain as EventPrefix}
            crudHook={crudHook}
            fields={editFields || bulkEditFields}
            {...editorProps}
          />
        )}
      </>
    </AdvanceTableProvider>
  );
};
export default DomainTable;
