import { getItemFromStore } from "helpers/utils";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
export default ({
  targetHref,
  targetStoreName,
  data
}) => {
  const [popoutData, setPopoutData] = useState(null);
  const [popout, setPopout] = useState(null);
  const getVals = () => data;
  const updateData = debounce(() => {
    setPopoutData(getVals());
  }, 2e3);
  useEffect(() => {
    updateData();
    return updateData.cancel;
  }, [data]);
  const updatePopout = () => {
    if (popout && popout.sessionStorage) {
      popout.sessionStorage.setItem(
        "previewer-" + targetStoreName,
        JSON.stringify(popoutData)
      );
      popout.dispatchEvent(new CustomEvent("dataUpdated"));
    }
  };
  useEffect(() => {
    updatePopout();
  }, [popoutData, popout]);
  let interval;
  const handlePopout = () => {
    const newWindow = window.open(
      targetHref,
      "Preview",
      "width=600,height=660"
    );
    interval = setInterval(() => {
      if (!newWindow.name) {
        setPopout(null);
        clearInterval(interval);
      }
    }, 500);
    setTimeout(() => {
      updateData();
    }, 2e3);
    setPopout(newWindow);
  };
  return {
    show: handlePopout,
    isShown: !!popout,
    hide: () => setPopout(null),
    window: popout
  };
};
export const usePopoutData = ({ name: storeName }) => {
  const [returnData, setReturnData] = useState();
  const updateFromStore = () => {
    const data = getItemFromStore("previewer-" + storeName, {}, sessionStorage);
    setReturnData(data);
  };
  useEffect(() => {
    window.addEventListener("dataUpdated", updateFromStore);
    return () => window.removeEventListener("dataUpdated", updateFromStore);
  }, []);
  useEffect(() => {
    const store = getItemFromStore(
      "previewer-" + storeName,
      {},
      sessionStorage
    );
    setReturnData(store);
  }, []);
  return { data: returnData };
};
