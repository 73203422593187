import React from 'react';
import { Questionnaire } from 'apis/flex/projects';
import { getDomainInput } from 'components/common/DomainInput';
import useLanguages from './useLanguages';
import { SelectorItem } from 'components/common/ItemSelector';
import { Col, Row } from 'react-bootstrap';
import DomainDetail from 'components/common/DomainDetail';
import useQuestionnaires from './useQuestionnaires';
import DetailCard from 'components/common/detail/DetailCard';
import QuestionnaireOutputs from './QuestionnaireOutputs';
import { DomainItemSelector } from 'components/common/DomainItemSelector';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
const Input = getDomainInput<Questionnaire>();
const Parent = () => {
  const quoteId = useWatch({ name: 'quoteId' });
  return quoteId ? (
    <DomainItemSelector readOnly name="quoteId" label="Quote" domain="quote" />
  ) : (
    <DomainItemSelector
      readOnly
      name="projectId"
      label="Project"
      domain="project"
    />
  );
};
export default () => {
  const { data: languages } = useLanguages<SelectorItem>({
    select: d =>
      Object.keys(d).map(k => ({
        label: d[k].name + ' (' + d[k].nativeName + ')',
        value: k
      }))
  });
  const { projectId, quoteId } = useParams();
  return (
    <DomainDetail
      defaultValues={{ projectId, quoteId }}
      domain="questionnaire"
      crudHook={useQuestionnaires}
    >
      <DetailCard id="info" title="Questionnaire">
        <Parent />
        <Input name="name" />
        <Input
          name="fileId"
          multiple
          type="file"
          label="Word document"
          pluginProps={{
            accept: {
              'application/msword': ['.doc', '.docx'],
              'application/pdf': ['.pdf']
            },
            maxFiles: 1
          }}
          registerProps={{ required: false }}
        />
      </DetailCard>
      <DetailCard id="details" title="Details">
        <Row>
          <Col xs={12} md={6}>
            <Input
              name="expectedIncidence"
              label="Expected incidence"
              type="number"
              formControlProps={{ step: 1 }}
              suffix="%"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name="expectedDuration"
              label="Expected duration"
              type="number"
              formControlProps={{ step: 0.1 }}
              suffix="mins"
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name="sponsorIdentified"
              label="Sponsor identified"
              type="select"
              options={['intro', 'midway', 'end', 'never']}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name="translations"
              label="Translations"
              multiple
              type="select"
              options={languages}
              registerProps={{ required: false }}
            />
          </Col>
          <Col xs={12} md={6}>
            <Input
              name="verbatimCodingQuestions"
              type="tags"
              registerProps={{ required: false }}
            />
          </Col>
          <Col xs={12}>
            <Input
              name="notes"
              type="textarea"
              registerProps={{ required: false }}
            />
          </Col>{' '}
        </Row>
      </DetailCard>
      <DetailCard id="outputs" title="Outputs">
        <QuestionnaireOutputs />
      </DetailCard>
    </DomainDetail>
  );
};
