import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import icon from 'assets/img/animated-icons/coming-soon.json';
import companyConfig from 'companyConfig';
import Lottie from 'lottie-react';

const ComingSoon = () => {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <Lottie animationData={icon} />
          </Col>
          <Col lg={6} className="ps-lg-4 my-5 text-center text-lg-left">
            <h3>Coming Soon!</h3>
            <p className="lead">
              This page is under construction and will be available in the near
              future!
            </p>
            <p>
              In the meantime, if you have any questions or suggestions, feel
              free to contact support:{' '}
              <a href={'mailto:' + companyConfig.supportEmail}>
                {companyConfig.supportEmail}
              </a>
              .
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default ComingSoon;
