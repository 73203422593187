import { customQuestionTypes } from "components/common/customForms/Editor/CustomFormQuestionEditor";
import { weekdays } from "helpers/dates";
import { camelToSentence } from "helpers/utils";
const baseAggregationOptions = [
  { value: "count", label: "Count" },
  { value: "countDistinct", label: "Unique count" }
];
export const getAggregationOptions = (inputType) => {
  if (inputType === "number") {
    return [
      ...baseAggregationOptions,
      { value: "sum", label: "Sum" },
      { value: "avg", label: "Average" },
      { value: "min", label: "Minimum" },
      { value: "max", label: "Maximum" }
    ];
  }
  if (inputType === "date") {
    return [
      ...baseAggregationOptions,
      { value: "min", label: "Minimum" },
      { value: "max", label: "Maximum" }
    ];
  }
  return baseAggregationOptions;
};
export const getRuleOptions = (col) => {
  const options = () => {
    const opts = col.options;
    if (opts?.length > 0 || col.inputType === "multiSelect" || col.inputType === "select") {
      return [
        { value: "in", label: "Has one of" },
        { value: "allin", label: "Has all of" },
        { value: "!in", label: "Has none of" }
      ];
    }
    const questionSubItems = customQuestionTypes.find(
      (q) => q.value === col.inputType
    )?.subItems;
    if (questionSubItems?.length) {
      const subOptions = questionSubItems.flatMap(
        (t) => getRuleOptions({ inputType: t.inputType }).map((o) => ({
          value: o.value + "." + t.value,
          label: camelToSentence(t.value) + " " + o.label
        }))
      );
      return subOptions;
    }
    switch (col.inputType) {
      case "select":
      case "multiSelect":
        return [];
      case "number":
        return [
          { value: "==", label: "Equal to" },
          { value: "!=", label: "Not equal to" },
          { value: "<", label: "Less than" },
          { value: ">", label: "Greater than" },
          { value: "between", label: "Between" },
          { value: "!between", label: "Not between" }
        ];
      case "score":
      case "interview":
      case "transcribe":
      case "speaking":
        return [
          { value: "<.score", label: "Score less than" },
          { value: ">.score", label: "Score greater than" },
          { value: "between.score", label: "Score between" },
          { value: "!between.score", label: "Score not between" },
          { value: "contains.text", label: "Response contains" },
          { value: "!contains.text", label: "Response does not contain" }
        ];
      case "date":
      case "datetime":
        return [
          { value: "between", label: "Between" },
          { value: "!between", label: "Not between" },
          { value: "<", label: "Before" },
          { value: ">", label: "After" },
          { value: "==", label: "Equal to" },
          { value: "!=", label: "Not equal to" },
          { value: "lastYears", label: "In last X years" },
          { value: "lastDays", label: "In last X days" },
          { value: "lastHours", label: "In last X hours" },
          { value: "!lastYears", label: "Not in last X years" },
          { value: "!lastDays", label: "Not in last X days" },
          { value: "!lastHours", label: "Not in last X hours" }
        ];
      case "time":
        return [
          { value: "<", label: "Before" },
          { value: ">", label: "After" },
          { value: "==", label: "Equal to" },
          { value: "!=", label: "Not equal to" },
          { value: "lastMinutes", label: "In last X minutes" },
          { value: "lastHours", label: "In last X hours" },
          { value: "!lastMinutes", label: "Not in last X minutes" },
          { value: "!lastHours", label: "Not in last X hours" },
          { value: "between", label: "Between" },
          { value: "!between", label: "Not between" }
        ];
      case "checkbox":
      case "bit":
      case "boolean":
        return [
          { value: "==", label: "Is" },
          { value: "!=", label: "Is not" }
        ];
      case "text":
      case "email":
      case "textarea":
        return [
          { value: "contains", label: "Contains" },
          { value: "!contains", label: "Does not contain" },
          { value: "==", label: "Equals" },
          { value: "!=", label: "Does not equal" },
          { value: "in", label: "Is one of" },
          { value: "!in", label: "Is not one of" }
        ];
      case "image":
      case "file":
        return [
          { value: ">count", label: "More than X files" },
          { value: "<count", label: "Less than X files" }
        ];
      case "video":
      case "audio":
        return [
          { value: ">count", label: "More than X files" },
          { value: "<count", label: "Less than X files" }
          // { value: '>duration', label: 'Longer than' },
          // { value: '<duration', label: 'Shorter than' }
        ];
      case "shifts":
        return [
          { value: ">shifts", label: "More than X shifts" },
          { value: "<shifts", label: "Less than X shifts" },
          { value: ">shiftMinHours", label: "Min hours more than" },
          { value: "<shiftMinHours", label: "Min hours less than" },
          { value: ">shiftMaxHours", label: "Max hours more than" },
          { value: "<shiftMaxHours", label: "Max hours less than" }
        ];
      default:
        return [
          { value: "contains", label: "Contains" },
          { value: "!contains", label: "Does not contain" }
        ];
    }
  };
  return options().concat([
    { value: "empty", label: "Is empty" },
    { value: "!empty", label: "Is not empty" }
  ]);
};
export const isRuleMultiple = (ruleType) => {
  return ["in", "!in", "allin", "!allin"].includes(ruleType);
};
export const getRuleInputType = (inputType, filterType) => {
  if (filterType && filterType.indexOf("empty") >= 0) {
    return null;
  }
  const customSubItems = customQuestionTypes.find(
    (q) => q.value === inputType
  )?.subItems;
  const isCustomSubItemFilter = filterType?.includes(".") && customSubItems?.length;
  const subItem = filterType?.split(".")[1];
  const subItemFilter = filterType?.split(".")[0];
  const type = isCustomSubItemFilter ? customSubItems.find((q) => q.value === subItem)?.inputType : inputType;
  const derivedFilterType = isCustomSubItemFilter && subItemFilter || filterType;
  switch (type) {
    case "file":
    case "video":
    case "audio":
    case "media":
    case "speaking":
    case "transcribe":
    case "interview":
      if (derivedFilterType?.toLowerCase().includes("text")) {
        return "text";
      }
      if (derivedFilterType?.toLowerCase().includes("score")) {
        if (derivedFilterType?.toLowerCase().includes("between")) {
          return "range";
        }
        return "number";
      }
      return "number";
    case "shifts":
      switch (derivedFilterType) {
        case "in":
        case "!in":
          return weekdays;
        default:
          return "number";
      }
    case "date":
    case "datetime":
      if (derivedFilterType?.toLowerCase().includes("last")) {
        return "number";
      }
      switch (derivedFilterType) {
        case "between":
        case "!between":
          return "daterange";
        default:
          return type;
      }
    case "time":
      if (derivedFilterType?.toLowerCase().includes("last")) {
        return "number";
      }
      switch (derivedFilterType) {
        case "between":
        case "!between":
          return "timerange";
        default:
          return type;
      }
    case "number":
    case "score":
      switch (derivedFilterType) {
        case "between":
        case "!between":
          return "range";
        default:
          return "number";
      }
    case "checkbox":
    case "bit":
    case "boolean":
      return "checkbox";
    case "textarea":
    case "email":
    case "name":
    case "postcode":
    case "postalAddress":
    case "hardware":
    case "mobile":
    case "landline":
    case "text":
      if (isRuleMultiple(derivedFilterType)) return "tags";
      return "text";
    default:
      if (isRuleMultiple(derivedFilterType)) return "multiSelect";
      return type;
  }
};
