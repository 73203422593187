import React, { useState } from 'react';
import AdvanceTableProvider from 'components/common/advance-table-v2/AdvanceTableProvider';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import { Button, Col, Row } from 'react-bootstrap';
import { TrainingCourse } from 'apis/flex/hr';
import { getMediaStreamUrl } from 'helpers/utils';
import AuthorStamp from 'components/common/AuthorStamp';
import coverSrc from 'assets/img/generic/training.jpg';
import Skeleton from 'react-loading-skeleton';
import useCurrentUserTraining from '../hooks/useCurrentUserTraining';
import DomainAvatar from 'components/common/DomainAvatar';
import { faEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { CourseEnrollModal } from './Enroll';
import { Row as RowType } from '@tanstack/react-table';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';

export const CourseItemContent = ({
  data,
  isLoading,
  href = '' + data?.id
}: {
  data: TrainingCourse;
  isLoading?: boolean;
  href?: string;
}) => {
  const src = data?.profileBackground
    ? getMediaStreamUrl(data.profileBackground)
    : coverSrc;
  return (
    <Flex alignItems={'center'}>
      <DomainAvatar domain="training-course" src={src} isLoading={isLoading} />
      <div>
        {isLoading ? (
          <Skeleton width={100} count={2} />
        ) : (
          <>
            <Link to={href} className="stretched-link">
              <h6 className="mb-0 fw-semi-bold fs-1">{data.name}</h6>
            </Link>
            <AuthorStamp
              className={'d-none d-lg-flex'}
              userId={data.createdBy}
              date={data.createdDate}
            />
          </>
        )}
      </div>
    </Flex>
  );
};
const CourseItem = ({
  data,
  isLoading = false
}: {
  data: TrainingCourse;
  isLoading?: boolean;
}) => {
  const nav = useNavigate();
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'between'}
      className="p-2 d-flex p-2 px-lg-4 hover-actions-trigger"
    >
      <CourseItemContent data={data} isLoading={isLoading} href="#" />
      {/* <div>
        <p>{data.resources?.length || 0} modules</p>
      </div> */}
      <div className="hover-actions end-0 top-50 translate-middle-y me-2">
        <Button
          disabled={isLoading}
          onClick={() => nav(data.id + '')}
          size="sm"
          variant="falcon-primary"
        >
          Edit
        </Button>
      </div>
    </Flex>
  );
};
export const CoursesLayout = ({
  rows,
  isLoading,
  children
}: {
  rows: RowType<TrainingCourse>[];
  isLoading: boolean;
  children: (row: TrainingCourse) => React.ReactNode;
}) => {
  return (
    <Row className="g-2 px-2 py-4">
      {isLoading
        ? [0, 0, 0, 0].map((x, i) => (
            <Col key={i} xs={12} lg={6} xxl={4}>
              <CourseItem key={i} data={null} isLoading={true} />
            </Col>
          ))
        : rows.map(row => (
            <Col
              xs={12}
              lg={6}
              xxl={4}
              key={row.id}
              className="d-inline-flex align-items-center"
            >
              {children(row.original)}
            </Col>
          ))}
    </Row>
  );
};

const CoursesTable = () => {
  const { courses, isLoading, users } = useCurrentUserTraining();
  const nav = useNavigate();
  const [showEnroll, setShowEnroll] = useState<{
    courseIds: number[];
    userIds?: number[];
  }>(null);
  return (
    <AdvanceTableProvider
      title="Training courses"
      onNewClick={() => nav('new')}
      sqlDb="HR"
      sqlTables={['TrainingCourses']}
      columns={[
        {
          id: 'profileBackground',
          header: ' ',
          maxSize: 35,
          cellProps: {
            className: 'ps-2 pe-0'
          },
          headerProps: {
            className: 'ps-2 pe-0'
          },
          enablePinning: false,
          enableColumnFilter: false,
          formatter: d => {
            const src = d() ? getMediaStreamUrl(d()[0]) : coverSrc;
            return (
              <DomainAvatar size="sm" domain="training-course" src={src} />
            );
          }
        },
        'name',
        { id: 'resources', accessorFn: d => d.resources?.length || 0 },
        'isPublic',
        'createdDate',
        { id: 'createdBy', options: users }
      ]}
      data={courses}
      isLoading={isLoading}
      onRowClick={row => nav(row.original.id + '')}
      actions={[
        {
          name: 'Edit',
          icon: faEdit,
          onClick: row => nav(row.original.id + '')
        },
        {
          name: 'Enroll trainess',
          icon: faUserPlus,
          onClick: row => setShowEnroll({ courseIds: [row.original.id] })
        }
      ]}
      bulkActions={[
        {
          name: 'Enroll trainess',
          icon: faUserPlus,
          actionFn: async data =>
            setShowEnroll({ courseIds: data.map(r => r.original.id) })
        }
      ]}
    >
      <AdvanceTable />
      <CourseEnrollModal
        courseIds={typeof showEnroll !== 'boolean' && showEnroll?.courseIds}
        show={!!showEnroll}
        setShow={setShowEnroll}
      />
    </AdvanceTableProvider>
  );
};
export default () => {
  return (
    <>
      <CoursesTable />
    </>
  );
};
