import { getItemFromStore, setItemToStore } from 'helpers/utils';
import React, { useEffect } from 'react';
import { useLocation, useMatches } from 'react-router-dom';
import { Route } from './siteMaps';
export default () => {
  const paths = useMatches();
  const typedPaths = paths?.map(p => ({ ...p, handle: p?.handle as Route }));
  const location = useLocation();
  const crumbs = typedPaths.filter(
    p => p && p.handle && p.handle.name && !p.handle.index
  );
  useEffect(() => {
    document.title =
      (crumbs[crumbs.length - 1]?.handle?.name || 'Flex') + ' | Flex';
    const history = getItemFromStore('visitedPages', {}, localStorage);
    if (crumbs.length) {
      history[location.pathname] = {
        url: location.pathname,
        crumbs: crumbs.map(c => c.handle.name),
        title: crumbs[crumbs.length - 1].handle.name,
        visitedDate: new Date()
      };
      setItemToStore('visitedPages', history, localStorage);
    }
  }, [location.pathname]);
  return <></>;
};
