import React from 'react';
import AdvanceTableProvider from 'components/common/advance-table-v2/AdvanceTableProvider';
import useCurrentUserTraining from '../hooks/useCurrentUserTraining';
import { CourseItemContent, CoursesLayout } from '.';
import { Button, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import TrainingCoursePicker from '../widgets/TrainingCoursePicker';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import useUserTraining from '../hooks/useUserTraining';
import { ResourcePreview } from '../client/TrainingClient';
import { TrainingCourse, UserTrainingCourse } from 'apis/flex/hr';
import Background from 'components/common/Background';
import { getMediaStreamUrl } from 'helpers/utils';
import coverSrc from 'assets/img/generic/training.jpg';
import { Link } from 'react-router-dom';

const NewCoursePicker = ({ myTrainingLookup }) => {
  return (
    <TrainingCoursePicker
      name="courseId"
      label="Enroll on a new course"
      registerProps={{ required: false }}
      confirmText="Take a look"
      mutator={course => ({
        locked:
          course.prerequisites &&
          !course.prerequisites?.some(p =>
            myTrainingLookup
              .get(p.prerequisiteId)
              ?.resources?.some(r => !r.approvedDate)
          )
      })}
      filter={t => !myTrainingLookup.get(t.id)}
    />
  );
};
const CourseProgress = ({ userCourse }: { userCourse: UserTrainingCourse }) => {
  const numberOfResources = userCourse?.resources?.length;
  const resourcesDone = userCourse?.resources?.filter(
    r => r?.approvedDate
  ).length;
  return (
    <ProgressBar
      className="w-100"
      variant={numberOfResources !== resourcesDone ? 'warning' : 'success'}
      min={0}
      animated={numberOfResources !== resourcesDone}
      now={resourcesDone}
      max={numberOfResources}
    />
  );
};
const MyCoursesTable = ({
  myTrainingLookup,
  courses,
  isLoading
}: {
  myTrainingLookup: Map<number, UserTrainingCourse>;
  courses: TrainingCourse[];
  isLoading: boolean;
}) => {
  return (
    <AdvanceTableProvider
      columns={['name', 'isPublic', 'createdDate']}
      title="My courses"
      data={courses?.filter(c => myTrainingLookup.has(c.id))}
      isLoading={isLoading}
    >
      {({ rows, isLoading: tableLoading }) => (
        <>
          <CoursesLayout rows={rows} isLoading={tableLoading}>
            {row => (
              <Flex
                alignItems={'start'}
                wrap={'wrap'}
                justifyContent={'between'}
                className="flex-column p-2 d-flex p-2 w-100"
              >
                <Flex className={'hover-actions-trigger w-100'}>
                  <CourseItemContent
                    href={'' + row.id}
                    data={row}
                    isLoading={tableLoading}
                  />
                  <div className="hover-actions end-0 top-50 translate-middle-y me-2">
                    <Link to={'' + row.id}>
                      <Button size="sm" variant="falcon-primary">
                        {!myTrainingLookup.get(row.id)?.resources?.length
                          ? 'Get started'
                          : myTrainingLookup
                              .get(row.id)
                              ?.resources?.every(r => r?.finishedDate)
                          ? 'Review'
                          : 'Continue'}
                      </Button>
                    </Link>
                  </div>
                </Flex>
                <Flex className={'gap-2 py-2 w-100'}>
                  {myTrainingLookup
                    .get(row.id)
                    ?.resources?.every(r => r?.finishedDate) ? (
                    <ProgressBar
                      className="w-100"
                      variant="success"
                      now={1}
                      max={1}
                      label="Completed"
                    />
                  ) : (
                    <CourseProgress userCourse={myTrainingLookup.get(row.id)} />
                  )}
                </Flex>
              </Flex>
            )}
          </CoursesLayout>
        </>
      )}
    </AdvanceTableProvider>
  );
};
const CoursePreview = ({
  course,
  isLoading,
  onConfirm
}: {
  course: TrainingCourse;
  isLoading: boolean;
  onConfirm: () => void;
}) => {
  return (
    <Flex
      alignItems={'start'}
      wrap={'wrap'}
      justifyContent={'between'}
      className="flex-column p-2 d-flex p-2"
    >
      <Flex className={'w-100 position-relative  mb-4'} style={{ height: 120 }}>
        {/* <CourseItemContent
          //   href={'' + course.id}
          data={course}
          isLoading={isLoading}
        /> */}
        <Background
          isLoading={isLoading}
          image={
            course.profileBackground
              ? getMediaStreamUrl(course.profileBackground)
              : coverSrc
          }
          className="rounded-3"
        />
        <Card
          className="position-absolute start-0 m-2 px-3 py-2"
          style={{ bottom: -28 }}
        >
          <h5>{course.name}</h5>
        </Card>
      </Flex>
      <p
        className="text-muted mt-2 fs--1 ps-2"
        dangerouslySetInnerHTML={{ __html: course.description }}
      />
      <Flex className={'gap-2 p-2 w-100 flex-column'}>
        <h6>Modules</h6>
        <div className="ps-2">
          {course.resources.map(r => (
            <ResourcePreview key={r.id} resource={r} />
          ))}
        </div>
      </Flex>
      <Button
        onClick={onConfirm}
        className="align-self-end mt-2"
        variant="primary"
      >
        Enroll
      </Button>
    </Flex>
  );
};
const CourseEnroll = ({ onEnrolled }) => {
  const { myTrainingLookup, courses, isLoading, userId } =
    useCurrentUserTraining();
  const newId = useWatch({ name: 'courseId' });
  const newCourse = courses?.find(c => c.id === Number(newId));
  const { addSelf } = useUserTraining();
  const handleEnroll = () => {
    addSelf(
      { courseId: newCourse.id, userId },
      { onSuccess: () => onEnrolled() }
    );
  };
  return (
    <Card>
      <Card.Body>
        <NewCoursePicker myTrainingLookup={myTrainingLookup} />
        {newCourse && (
          <CoursePreview
            course={newCourse}
            isLoading={isLoading}
            onConfirm={handleEnroll}
          />
        )}
      </Card.Body>
    </Card>
  );
};
export default () => {
  const { myTrainingLookup, courses, isLoading } = useCurrentUserTraining();
  const methods = useForm();
  return (
    <Row className="gap-3">
      <Col xs={12}>
        <MyCoursesTable {...{ myTrainingLookup, courses, isLoading }} />
      </Col>
      <Col>
        <FormProvider {...methods}>
          <CourseEnroll onEnrolled={methods.reset} />
        </FormProvider>
      </Col>
    </Row>
  );
};
