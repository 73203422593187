import React from 'react';
import { useParams } from 'react-router-dom';
import TrainingClient from './TrainingClient';
import { useUser } from 'hooks/useUser';

export default () => {
  const { id } = useParams();
  const { id: userId } = useUser();
  return <TrainingClient courseId={Number(id)} userId={Number(userId)} />;
};
