import WizardInput from 'components/wizard/WizardInput';
import useMediaForm from 'hooks/useMediaForm';
import { FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import React from 'react';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import is from 'is_js';
import { WizardFilePluginProps } from '../wizard/inputs/WizardFile';
import { RoleDomain } from 'apis/flex/users';

//takes a string for imageValue which fetches an image stream
//when the image is changed, it returns a file object via the onChange callback
//the caller should convert the file object to a string when the onChange callback is fired
//if the caller passes a file object to imageValue, it will cause a recursive render
export const UploadableMedia = ({
  image: imageValue = '',
  onChange = src => console.log(src),
  accept = { 'image/*': [] },
  inputProps,
  children,
  settings,
  authItemCollection,
  authItemId,
  className,
  name = 'image',
  onClick = null,
  upload = true
}: {
  image?: string | string[];
  onChange?: (src: string) => void;
  accept?: any;
  inputProps?: any;
  settings?: WizardFilePluginProps;
  authItemCollection?: RoleDomain;
  authItemId?: number;
  className?: string;
  name?: string;
  onClick?: any;
  children?: any;
  upload?: boolean;
}) => {
  const methods = useMediaForm({
    authItemCollection: authItemCollection,
    authItemId,
    liveUpload: !!upload
  });
  const isFieldDirty = !!methods.formState.dirtyFields[name];
  const inputValue = methods.watch(name, imageValue);
  const { files, isLoading } = useFileLoader({
    value: inputValue
  });
  const image = is.string(inputValue) ? { preview: inputValue } : files[0];
  const [isUpdating, setIsUpdating] = useState(false);
  useEffect(() => {
    if (isFieldDirty) {
      if (!upload) {
        onChange(inputValue);
        return;
      }
      setIsUpdating(true);
      methods.handleSubmit(
        data => {
          setIsUpdating(false);
          onChange(data[name]);
        },
        err => {
          setIsUpdating(false);
          console.error(err);
        }
      )();
    }
  }, [image, isFieldDirty]);
  useEffect(() => {
    if (!imageValue) {
      return methods.resetField(name, {
        defaultValue: imageValue
      });
    }
    // console.log('imageValue changed', imageValue);
    if (
      imageValue &&
      (Array.isArray(imageValue) || is.string(imageValue)) &&
      imageValue.length > 0
    ) {
      methods.resetField(name, {
        defaultValue: imageValue
      });
    }
  }, [imageValue]);
  const imageSrc = image ? image.preview : null;
  const [confirmModal, setConfirmModal] = useState(false);
  // console.log(
  //   imageSrc,
  //   image,
  //   imageValue,
  //   inputValue,
  //   files,
  //   methods.formState.dirtyFields
  // );
  return (
    <FormProvider {...methods}>
      {children({ isLoading: isLoading || isUpdating, src: imageSrc })}
      <WizardInput
        type="file"
        pluginProps={{
          maxFiles: 1,
          accept,
          hidden: true,
          ...settings
        }}
        registerProps={{
          required: false
        }}
        formGroupProps={{
          className: classNames(
            className,
            'position-absolute w-100 h-100 top-0 start-0'
          ),
          onClick
        }}
        name={name}
        hideLabel={inputProps?.disabled}
        label={
          <div className="d-block position-absolute start-0 top-0 w-100 h-100 cursor-pointer">
            {/* <Button
              onClick={e => {
                e.preventDefault();
                setConfirmModal(true);
              }}
              className="position-absolute top-0 start-50 translate-middle-x"
              variant="falcon-danger"
              size="sm"
            >
              <FontAwesomeIcon icon={faUndo} />
            </Button> */}
          </div>
          // <div
          //   className={classNames(
          //     className,
          //     'm-2 opacity-75 align-items-center cursor-pointer d-flex align-items-center justify-content-start position-absolute start-0 top-0 gap-2'
          //   )}
          // >
          //   <Button variant="falcon-primary" size="sm">
          //     <FiUpload />
          //   </Button>
          // </div>
        }
        {...inputProps}
      />

      <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modal.Body>
          Are you sure you want to reset this back to default?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => setConfirmModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              methods.setValue(name, null);
              onChange(null);
              setConfirmModal(false);
            }}
          >
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </FormProvider>
  );
};
