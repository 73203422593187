import Background from 'components/common/Background';
import cornerBg from 'assets/img/illustrations/corner-2.png';
import profileImg from 'assets/img/team/avatar.png';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import { Applicant, ApplicantResponse } from 'apis/flex/recruitment';

const InfoItem = ({ title, isLast, content = '', className }) => {
  return (
    <div className={classNames({ 'mb-4': !isLast }, className)}>
      <h6
        className={classNames({
          'mb-1': title === 'Email' || title === 'Phone Number'
        })}
      >
        {title}
      </h6>
      {title === 'Email' && (
        <a className="fs--1" href={`mailto: ${content}`}>
          {content}
        </a>
      )}
      {title === 'Phone Number' && (
        <a className="fs--1" href={`tel: ${content?.replace(/\s+/g, '')}`}>
          {content}
        </a>
      )}
      {title !== 'Email' && title !== 'Phone Number' && (
        <p className="mb-0 text-700 fs--1">{content}</p>
      )}
    </div>
  );
};

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLast: PropTypes.bool
};
const StatusBadge = ({ bg, children, ...rest }) => {
  const icon =
    bg === 'success'
      ? faCheckCircle
      : bg === 'warning'
      ? faExclamationTriangle
      : bg === 'danger'
      ? faTimesCircle
      : null;
  return (
    <SoftBadge bg={bg} pill {...rest}>
      <FontAwesomeIcon icon={icon} size="sm" className="me-2" />
      {children}
    </SoftBadge>
  );
};
StatusBadge.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.node
};
const ContactHeader = ({ data }: { data: Applicant }) => {
  const stage = data?.currentStage;
  return (
    <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
      <Background image={cornerBg} className="bg-card" />
      <Card.Body className="row g-0 flex-column flex-sm-row flex-lg-column flex-xxl-row z-index-1 align-items-center">
        <Col xs="auto" className="text-center me-sm-x1 me-lg-0 me-xxl-x1 ">
          <img className="ticket-preview-avatar" src={profileImg} alt="" />
        </Col>
        <Col sm={8} className="ps-sm-1 ps-lg-0 ps-xxl-1">
          <p className="fw-semi-bold mb-0 text-800 text-center text-sm-start text-lg-center text-xxl-start  mb-3 mt-3 mt-sm-0 mt-lg-3 mt-xxl-0">
            {data?.firstName + ' ' + data?.surname}
          </p>
          <Flex
            wrap={'wrap'}
            className={
              'justify-content-center justify-content-sm-start justify-content-lg-center justify-content-xxl-start gap-1'
            }
          >
            {data?.acceptReject === 'accepted' && (
              <StatusBadge bg={'success'}>Accepted</StatusBadge>
            )}
            {data?.user?.newStarterResponseId && (
              <StatusBadge bg={'success'}>Profile complete</StatusBadge>
            )}
            {!data?.user?.newStarterResponseId &&
              data?.user?.newStarterFormId && (
                <StatusBadge bg={'warning'}>Profile incomplete</StatusBadge>
              )}
            {data.acceptReject === 'rejected' && (
              <StatusBadge bg={'danger'}>Rejected</StatusBadge>
            )}
            {stage && (
              <>
                {!stage.completedDate &&
                  (!stage.stageFormId || !stage.dueDate) && (
                    <StatusBadge bg={'secondary'}>
                      In '{stage.stageName}'
                    </StatusBadge>
                  )}
                {!stage.completedDate &&
                  !!stage.stageFormId &&
                  !!stage.dueDate &&
                  new Date(stage.dueDate) > new Date() && (
                    <StatusBadge bg={'secondary'}>
                      {stage.stageName} due by{' '}
                      {format(new Date(stage.dueDate), 'do MMM HH:mm')}
                    </StatusBadge>
                  )}
                {!stage.completedDate &&
                  !!stage.stageFormId &&
                  !!stage.dueDate &&
                  new Date(stage.dueDate) < new Date() && (
                    <StatusBadge bg={'warning'}>
                      {stage.stageName} overdue
                    </StatusBadge>
                  )}
                {stage.completedDate && !stage.reviewDate && (
                  <StatusBadge bg={'info'}>
                    {stage.stageName} needs review
                  </StatusBadge>
                )}
                {stage.reviewDate && (
                  <StatusBadge bg={stage.isPass ? 'success' : 'danger'}>
                    {stage.stageName} {stage.isPass ? 'passed' : 'failed'}
                  </StatusBadge>
                )}
              </>
            )}
          </Flex>
        </Col>
      </Card.Body>
    </Card>
  );
};
const Contact = ({
  data,
  isLoading
}: {
  data: Applicant;
  isLoading?: boolean;
}) => {
  return (
    <>
      {isLoading ? (
        <>
          <Skeleton height={200} />
          <Skeleton count={2} />
        </>
      ) : (
        <>
          <ContactHeader data={data} />
          <Row className="border rounded-3 p-x1 mt-3 bg-white dark__bg-1000 mx-0 g-0">
            <Col md={6} xl={12} xxl={6} className="pe-xxl-4">
              <InfoItem title="Email" content={data?.email} />
              <InfoItem title="Phone Number" content={data?.telephone} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Contact;
