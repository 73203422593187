import React, { useEffect, useMemo, useState } from 'react';
import useReportConfigs from './useReportConfigs';
import ReportTile from './ReportTile';
import { Button, Card } from 'react-bootstrap';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import {
  canConfigGetData,
  getGroupByOptionsByType,
  mergeFilters,
  needsAnXAxis
} from './helpers';
import { groupBy, startCase } from 'lodash';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import useColumnInfo from 'components/common/advance-table-v2/useColumnInfo';
import { camelToSentence } from 'helpers/utils';
import {
  CustomQuestionRules,
  FieldArrayList,
  RuleQuestion
} from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import { getSqlInputType } from 'components/common/advance-table-v2/sql';
import { ReportConfig } from 'apis/flex/reporting';
import LoadingButton from 'components/common/LoadingButton';
import SettingsBox from 'components/common/SettingsBox';
import Debouncer, { useDebounce } from 'components/common/Debouncer';
import { CustomRule } from 'helpers/validation/validate';
import useDomainAccess from './useDomainAccess';
const typeOptions = ['line', 'bar', 'pie', 'stackedLine', 'stackedBar'];
const dbOptions = [
  'Users',
  'System',
  'Recruitment',
  'Forms',
  'HR',
  'Notifications',
  'Support'
];
const SeriesItem = ({ index, variables }) => {
  return (
    <>
      <WizardInput name={`series.${index}.name`} label="Name" />
      <FilterConfig
        dbField={'db'}
        tableField={'table'}
        name={`series.${index}.filters`}
        variables={variables}
      />
      {/* <AggregationDefinition namePrefix={`series.${index}.`} /> */}
    </>
  );
};
const SeriesArray = ({ variables }) => {
  const { fields, remove, append } = useFieldArray({ name: 'series' });
  return (
    <FieldArrayList
      fields={fields}
      remove={remove}
      defaultValues={[{ field: '', name: 'New Series', filters: [] }]}
      append={v => append(v)}
      item={(field, index) => (
        <SeriesItem index={index} variables={variables} />
      )}
    />
  );
};
const AggregationDefinition = ({
  namePrefix = '',
  dbField,
  tableField
}: {
  namePrefix?: string;
  dbField?: string;
  tableField?: string;
}) => {
  const { extendedColumns } = useExtendedColumns({ dbField, tableField });
  const aggregation = useWatch({ name: `aggregation` });
  const aggCols = extendedColumns?.filter(
    c => aggregation === 'count' || c.inputType === 'number'
  );
  return (
    <>
      <WizardInput
        type="select"
        label="Aggregation"
        name={namePrefix + 'aggregation'}
        instruction="Defaults to 'count' if left blank"
        registerProps={{ required: false }}
        options={['count', 'sum', 'avg']}
      />
      {aggregation && (
        <WizardInput
          type="select"
          label={startCase(aggregation) + ' of'}
          name={namePrefix + 'aggregationField'}
          instruction={
            aggregation === 'count'
              ? 'If specified, the count will be the number of non-empty values in this column'
              : 'The numeric column to use for the ' + aggregation
          }
          registerProps={{ required: aggregation && aggregation !== 'count' }}
          options={aggCols?.map(c => ({
            value: c.value,
            label: camelToSentence(c.value)
          }))}
        />
      )}
    </>
  );
};
const SeriesDefinition = ({ variables }) => {
  const { extendedColumns } = useExtendedColumns();
  const seriesSplitBy = useWatch({ name: `seriesSplitBy` });
  return (
    <>
      <WizardInput
        name={`seriesSplitBy`}
        label="Split By"
        type="select"
        registerProps={{ required: false }}
        options={extendedColumns
          ?.map(c => ({ label: camelToSentence(c.label), value: c.label }))
          .concat({
            label: 'Custom',
            value: 'custom',
            description: 'Define your own custom series'
          } as any)}
      />
      {seriesSplitBy === 'custom' && <SeriesArray variables={variables} />}
      <AggregationDefinition />
    </>
  );
};
const useExtendedColumns = ({ dbField = 'db', tableField = 'table' } = {}) => {
  const db = useWatch({ name: dbField, disabled: !dbField });
  const { data: columns } = useColumnInfo({
    db,
    enabled: !!db && typeof db === 'string'
  });
  const { canAccess } = useDomainAccess();
  const tableLookup = groupBy(
    (columns || []).filter(col =>
      canAccess(db, col.TABLE_NAME, col.COLUMN_NAME)
    ),
    'TABLE_NAME'
  );
  const table = useWatch({ name: tableField, disabled: !tableField });
  const extendedColumns: (RuleQuestion & { value: string })[] = tableLookup?.[
    table
  ]?.map(col => ({
    inputType: getSqlInputType(col),
    label: col.COLUMN_NAME,
    questionText: camelToSentence(col.COLUMN_NAME),
    value: col.COLUMN_NAME,
    ...col
  }));
  const extendedColumnMap = new Map(extendedColumns?.map(c => [c.value, c]));
  return useMemo(
    () => ({
      tableLookup,
      table,
      extendedColumns,
      extendedColumnMap,
      columnOptions: tableLookup[table]?.map(c => ({
        value: c.COLUMN_NAME,
        label: camelToSentence(c.COLUMN_NAME)
      }))
    }),
    [columns, table, db]
  );
};
const GroupBy = ({
  fieldToGroup,
  ...rest
}: { fieldToGroup: string } & Partial<WizardInputProps>) => {
  const { extendedColumnMap } = useExtendedColumns();
  const columnName = useWatch({ name: fieldToGroup });
  const column = extendedColumnMap.get(columnName);
  const options = useMemo(
    () => getGroupByOptionsByType(column?.inputType),
    [column?.inputType]
  );
  const { resetField } = useFormContext();
  useEffect(() => {
    resetField('xAxes.0.groupBy');
  }, [columnName]);
  return (
    !!options.length && (
      <WizardInput
        name="xAxes.0.groupBy"
        label="Group by"
        type="select"
        options={options}
        {...rest}
        registerProps={{ shouldUnregister: true }}
      />
    )
  );
};
const DBSelector = ({ name, ...rest }: Partial<WizardInputProps>) => {
  const { canAccess } = useDomainAccess();
  return (
    <WizardInput
      type="select"
      options={dbOptions.filter(d => canAccess(d))}
      name={name}
      {...rest}
    />
  );
};
const DbTableSelector = ({
  name,
  dbField,
  ...rest
}: { dbField: string } & Partial<WizardInputProps>) => {
  const { tableLookup } = useExtendedColumns({ dbField });
  return (
    <WizardInput
      name={name}
      type="select"
      options={Object.keys(tableLookup)?.map(t => ({
        value: t,
        label: camelToSentence(t)
      }))}
      {...rest}
    />
  );
};
const AxisSelector = ({
  name,
  label,
  tableField,
  dbField,
  ...rest
}: { tableField: string; dbField: string } & Partial<WizardInputProps>) => {
  const { extendedColumns } = useExtendedColumns({ tableField, dbField });
  const chartType = useWatch({ name: 'type' });
  return (
    <WizardInput
      type={'select'}
      options={extendedColumns
        ?.filter(
          c =>
            chartType?.toLowerCase().includes('bar') ||
            c.inputType === 'date' ||
            c.inputType === 'datetime'
        )
        ?.map(c => ({ value: c.value, label: camelToSentence(c.value) }))}
      name={name}
      label={label}
      {...rest}
    />
  );
};
const DataConfig = ({ defaultValues, variables }) => {
  const { table } = useExtendedColumns();
  const chartType = useWatch({ name: 'type' });
  return (
    <SettingsBox title="Data" className="d-flex flex-column gap-2">
      {!defaultValues?.db && <DBSelector name="db" />}
      {!defaultValues?.table && <DbTableSelector dbField={'db'} name="table" />}
      {table && (
        <>
          {needsAnXAxis(chartType) && (
            <>
              <AxisSelector
                dbField={'db'}
                tableField={'table'}
                label={
                  chartType?.toLowerCase().includes('bar')
                    ? 'Categories'
                    : 'Date'
                }
                registerProps={{ shouldUnregister: true, required: false }}
                name="xAxes.0.field"
              />
              <GroupBy fieldToGroup={'xAxes.0.field'} />
            </>
          )}
          <SeriesDefinition variables={variables} />
        </>
      )}
    </SettingsBox>
  );
};
const FilterConfig = ({ name, dbField, tableField, variables }) => {
  const { extendedColumns } = useExtendedColumns({ dbField, tableField });
  return (
    <SettingsBox title="Filters">
      <CustomQuestionRules
        variables={variables}
        placeholder={'Field...'}
        excludeTypes={['allin', '!allin', '>count', '<count']}
        name={name}
        questions={extendedColumns}
      />
    </SettingsBox>
  );
};
const CompareToConfig = ({ variables }) => {
  const hasCompare = useWatch({ name: 'compareTo' });
  const chartType = useWatch({ name: 'type' });
  return (
    hasCompare && (
      <SettingsBox title="Compare">
        <DBSelector name="compareTo.db" label="DB" />
        <DbTableSelector
          name="compareTo.table"
          label="Table"
          dbField={'compareTo.db'}
        />
        {needsAnXAxis(chartType) && (
          <>
            <AxisSelector
              dbField={'compareTo.db'}
              tableField={'compareTo.table'}
              label={chartType === 'bar' ? 'Categories' : 'Date'}
              registerProps={{ shouldUnregister: true, required: false }}
              name="compareTo.xAxes.0.field"
            />
          </>
        )}
        <AggregationDefinition
          namePrefix="compareTo."
          dbField={'compareTo.db'}
          tableField={'compareTo.table'}
        />
        <FilterConfig
          name="compareTo.filters"
          dbField={'compareTo.db'}
          tableField={'compareTo.table'}
          variables={variables}
        />
        <WizardInput
          name="compareTo.calculation"
          type="select"
          label="Calculation"
          options={['Percentage', 'Difference']}
        />
      </SettingsBox>
    )
  );
};
const ConfigForm = ({ defaultValues, variables }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap gap-3">
        <WizardInput name="title" />
        <WizardInput
          type="select"
          options={typeOptions.map(t => ({
            value: t,
            label: camelToSentence(t)
          }))}
          name="type"
        />
      </div>
      <WizardInput
        name="description"
        instruction="Will be shown on the Quick Chart tile"
        registerProps={{ required: false }}
        type="textarea"
      />
      <DataConfig defaultValues={defaultValues} variables={variables} />
      <FilterConfig
        dbField={'db'}
        tableField={'table'}
        name="filters"
        variables={variables}
      />
      <WizardInput
        name="compareTo"
        label="Comparison"
        type="switch"
        registerProps={{ required: false }}
      />
      <CompareToConfig variables={variables} />
      <WizardInput
        name="realTime"
        label="Update in real time"
        instruction="Too many real-time charts could cause performance issues. Only choose this option if the data needs to be continually updated. If not selected, the chart will be updated each time the page loads."
        type="switch"
      />
    </div>
  );
};
const ReportPreview = ({ reportId, control, variables }) => {
  const config = useWatch<ReportConfig>({ control });
  const { data } = useDebounce({ ms: 3000, data: config });
  const canGetData = canConfigGetData(data as Partial<ReportConfig>);
  return useMemo(
    () =>
      (data || reportId) &&
      canGetData && (
        <div className="w-100 p-4">
          <ReportTile
            reportId={reportId}
            isEditing
            config={data as ReportConfig}
            variables={variables}
          />
        </div>
      ),
    [data, reportId, canGetData]
  );
};
const ReportBuilder = ({
  reportId,
  config: initialConfig,
  onSave,
  onCancel,
  defaultValues,
  filters,
  variables
}: {
  reportId?: number;
  config?: ReportConfig;
  onSave?: (d: ReportConfig) => void;
  onCancel?: () => void;
  defaultValues?: Partial<ReportConfig>;
  filters?: CustomRule;
  variables?: Record<string, any>;
}) => {
  const {
    data: dbConfig,
    isUpserting,
    upsert
  } = useReportConfigs({
    id: reportId,
    enabled: !initialConfig,
    beforeSave: d => ({ ...d, location: location.pathname }),
    afterSave: d => {
      onSave && onSave(d);
    }
  });
  const methods = useForm<ReportConfig, any, ReportConfig>({ defaultValues });
  useEffect(() => {
    const toUse = initialConfig || dbConfig?.[0];
    if (toUse) {
      methods.reset({
        ...toUse,
        filters: mergeFilters(toUse.filters, filters)
      });
      return;
    }
  }, [dbConfig, initialConfig]);
  const handleSave = methods.handleSubmit(data => {
    upsert({ ...data, filters: mergeFilters(data.filters, filters) });
  });
  // console.log('config changed', config);
  return (
    <Card>
      <Card.Header>
        <Card.Title>Quick Chart Config</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-between flex-column">
          <FormProvider {...methods}>
            <ConfigForm defaultValues={defaultValues} variables={variables} />
          </FormProvider>
          <ReportPreview
            variables={variables}
            control={methods.control}
            reportId={reportId}
          />
        </div>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <Button variant="link" onClick={() => onCancel()}>
          Cancel
        </Button>
        <LoadingButton loading={isUpserting} onClick={() => handleSave()}>
          Save
        </LoadingButton>
      </Card.Footer>
    </Card>
  );
};
export default ReportBuilder;
