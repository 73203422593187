import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getMedia, getMediaStream } from "apis/flex/helpers";
import { blobIcon } from "helpers/utils";
import useArray from "hooks/useArray";
import is from "is_js";
import { groupBy } from "lodash";
import { useMemo } from "react";
export default ({
  value
}) => {
  const fileIds = useArray(value);
  const shouldBeFetched = groupBy(
    fileIds,
    (v) => typeof v === "string" && !v.startsWith("http") && !v.startsWith("blob") || typeof v !== "string" && is.object(v) && "uploadPath" in v && //check if value is a false file object, e.g. a form value loaded from cache or otherwise serialised
    !("webkitRelativePath" in v) && !(v.uploadPath || v.preview).startsWith("http") && !(v.uploadPath || v.preview).startsWith("blob")
  );
  const toFetch = shouldBeFetched.true?.map(
    (v) => typeof v === "string" ? v : v.uploadPath || v.preview
  ) || [];
  const files = shouldBeFetched.false || [];
  const queryClient = useQueryClient();
  const { data: fetched, isLoading } = useQuery({
    queryKey: ["fileLoader", ...toFetch],
    queryFn: () => {
      const files2 = Promise.all(
        toFetch.map(async (id) => {
          const alreadyFetched = queryClient.getQueryData([
            "fileLoader_inner",
            id
          ]);
          if (alreadyFetched) return alreadyFetched;
          const [info, blob] = await Promise.all([
            getMedia(id),
            getMediaStream(id)
          ]);
          const url = URL.createObjectURL(blob);
          const file = {
            type: info.mimeType,
            name: info.name,
            uploadPath: id,
            uploaded: true,
            icon: blobIcon({ type: info.mimeType }),
            preview: url,
            size: info.size
            // blob
          };
          queryClient.setQueryData(["fileLoader_inner", id], file);
          return file;
        })
      );
      return files2;
    }
  });
  const memo = useMemo(() => {
    return [...files || [], ...fetched || []];
  }, [files, fetched]);
  return { isLoading, files: memo };
};
