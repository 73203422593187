import { useEffect, useState } from "react";
import useStages from "./useStages";
export default ({ campaignId }) => {
  const [items, setItems] = useState([]);
  const { data } = useStages({
    filters: { campaignId: Number(campaignId) },
    useFilter: true
  });
  const basicItems = [
    {
      title: "New",
      filter: { acceptReject: "null", stageId: "null" }
    },
    {
      title: "Accepted",
      filter: { acceptReject: "accepted" }
    },
    {
      title: "Rejected",
      filter: { acceptReject: "rejected" }
    }
  ];
  useEffect(() => {
    const newNavItems = [...basicItems];
    data?.forEach((stage) => {
      newNavItems.splice(1, 0, {
        title: stage.name,
        filter: { acceptReject: "null", stageId: stage.id }
      });
    });
    setItems([...newNavItems]);
  }, [data]);
  return items;
};
