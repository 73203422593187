import useCampaigns from "./useCampaigns";
export default ({
  id,
  select = (d) => d,
  ...rest
}) => {
  return useCampaigns({
    id,
    ...rest,
    select: (d) => {
      return d && select(d)[0];
    }
  });
};
