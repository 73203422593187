import React from 'react';
import { useState } from 'react';
import { extractTextFromChildren } from 'helpers/strings';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

export default ({ children, maxCharacters, buttonProps = null }) => {
  const [show, setShow] = useState(false);
  const text = extractTextFromChildren(children);
  const isTextLong = text.length > maxCharacters;
  const allButtonProps = {
    variant: 'link',
    style: { verticalAlign: 'text-top' },
    ...buttonProps,
    className: classNames('p-0 ms-1', buttonProps?.className)
  };
  const displayedText =
    show || !isTextLong ? (
      children
    ) : (
      <>
        {text.substring(0, maxCharacters)}...
        <Button {...allButtonProps} onClick={() => setShow(!show)}>
          Read More
        </Button>
      </>
    );
  return (
    <>
      {show ? (
        <>
          {children}
          <Button {...allButtonProps} onClick={() => setShow(!show)}>
            Show Less
          </Button>
        </>
      ) : (
        displayedText
      )}
    </>
  );
};
