import { Card, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/common/IconButton';
import { useCurrentPageReports } from './usePageReports';
import useReportConfigs from './useReportConfigs';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import ReportBuilder from './ReportBuilder';
import { ResponsiveModal } from 'components/common/Modals';
import { ReportConfig } from 'apis/flex/reporting';
import { CustomRule } from 'helpers/validation/validate';
import { CrudFilters } from 'hooks/useDefaultCrud';

export const ReportSelector = ({
  indexForNewReport,
  isLoading,
  reportFilters,
  location,
  section
}) => {
  const {
    add,
    data: pageReports,
    isAdding
  } = useCurrentPageReports({
    afterSave: () => methods.reset(),
    location,
    section
  });
  const { data: reportConfigs } = useReportConfigs({
    useFilter: true,
    filters: reportFilters,
    select: d => {
      return (
        d
          ?.filter(d => !pageReports?.map(r => r.reportId).includes(d.id))
          ?.map(d => ({
            value: d.id,
            label: d.title,
            description:
              d.table +
              ': ' +
              (d.seriesGroupBy || d.series?.map(d => d.name).join('/')) +
              ' x ' +
              d.xAxes?.map(x => x.field).join('/')
          })) || []
      );
    }
  });
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <WizardInput
        name="reportId"
        label="Quick Chart"
        type="select"
        hideLabel
        loading={isLoading || isAdding}
        placeholder="Select a Quick Chart"
        options={reportConfigs}
        registerProps={{
          onChange: d =>
            d.target.value &&
            add({ reportId: d.target.value, index: indexForNewReport })
        }}
      />
    </FormProvider>
  );
};

export default ({
  indexForNewReport,
  defaultValues,
  filters,
  reportFilters,
  variables,
  location,
  section
}: {
  indexForNewReport: number;
  defaultValues?: Partial<ReportConfig>;
  filters: CustomRule;
  reportFilters?: CrudFilters<ReportConfig>;
  variables?: Record<string, any>;
  location?: string;
  section?: string;
}) => {
  const [editing, setEditing] = useState(false);
  const { add, isAdding } = useCurrentPageReports({
    afterSave: () => setEditing(false)
  });
  return (
    <Card className="h-100 bg-transparent shadow-none border-dashed border border-3">
      <Card.Body className="overflow-hidden">
        <ReportSelector
          isLoading={isAdding}
          indexForNewReport={indexForNewReport}
          reportFilters={reportFilters}
          location={location}
          section={section}
        />
        <IconButton
          justify="start"
          icon={faPlus}
          variant="falcon-default"
          loading={isAdding}
          // iconSize="1x"
          // size="lg"
          onClick={() => setEditing(true)}
        >
          Create
        </IconButton>
      </Card.Body>
      <ResponsiveModal wide show={editing} onHide={() => setEditing(false)}>
        <ReportBuilder
          filters={filters}
          defaultValues={defaultValues}
          onSave={d => add({ reportId: d.id, index: indexForNewReport })}
          onCancel={() => setEditing(false)}
          variables={variables}
        />
      </ResponsiveModal>
    </Card>
  );
};
