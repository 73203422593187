import React from 'react';
import DomainDetail from 'components/common/DomainDetail';
import useTargetGroups from '../useTargetGroups';
import DetailCard from 'components/common/detail/DetailCard';
import { TargetGroup } from 'apis/flex/projects';
import DomainTags from 'components/common/DomainTags';
import ProjectCostCenterSelector from '../costs/ProjectCostCenterSelector';
import WithFormValues from 'components/wizard/WithFormValues';
import QuestionnaireSelector from '../questionnaires/QuestionnaireSelector';
import RpuTable from './RpuTable';
import { ResourceGroupSelector } from '../resourceGroups/ResourceGroupSelector';
import DependancySelect from 'components/common/DependancySelect';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { useWatch } from 'react-hook-form';
import { DomainItemSelector } from 'components/common/DomainItemSelector';
const Parent = () => {
  const quoteId = useWatch({ name: 'quoteId' });
  return quoteId ? (
    <DomainItemSelector readOnly name="quoteId" label="Quote" domain="quote" />
  ) : (
    <DomainItemSelector
      readOnly
      name="projectId"
      label="Project"
      domain="project"
    />
  );
};
const Info = () => {
  return (
    <>
      <Parent />
      <WizardInput name="name" />
      <QuestionnaireSelector label="Questionnaire" name="questionnaireId" />
      <Row>
        <Col xs={6}>
          <Input name="startDate" type="date" />
        </Col>
        <Col xs={6}>
          <Input
            name="endDate"
            type="date"
            registerProps={{
              validate: (v, d) =>
                !v ||
                !d?.startDate ||
                new Date(v) > new Date(d.startDate) ||
                'End date must be after start date'
            }}
          />
        </Col>
        <Col xs={4}>
          <DomainTags domain="target-group" />
        </Col>
        <Col xs={4}>
          <DomainTags
            domain="target-group"
            tagField="audiences"
            tagIdField="audienceId"
            tableName="ProjectAudiences"
          />
        </Col>
        <Col xs={4}>
          <DomainTags
            domain="target-group"
            tagField="methodologies"
            tagIdField="methodologyId"
            tableName="ProjectMethodologies"
          />
        </Col>
      </Row>
    </>
  );
};
const Input = (props: WizardInputProps<TargetGroup>) => {
  return <WizardInput {...props} />;
};
export default () => {
  const { projectId: rawId } = useParams();
  const projectId = rawId && !isNaN(Number(rawId)) ? Number(rawId) : undefined;
  return (
    <DomainDetail
      defaultValues={{ projectId }}
      domain="target-group"
      crudHook={useTargetGroups}
    >
      <DetailCard id="info" title="Info">
        <Info />
      </DetailCard>
      <DetailCard id="resources" title="Resources">
        <DependancySelect
          name="resourceGroups"
          localKey="targetGroupId"
          foreignKey="resourceGroupId"
        >
          {props => (
            <ResourceGroupSelector
              {...props}
              multiple={false}
              label="Resource Group"
            />
          )}
        </DependancySelect>
        <Row>
          <Col xs={6}>
            <Input
              name="unitOfWork"
              type="select"
              label="Target type"
              options={['interview', 'hour', 'day', 'sale', 'input']}
            />
          </Col>
          <Col xs={6}>
            <WithFormValues fields={['unitOfWork']}>
              {values =>
                !!values.unitOfWork && (
                  <Input
                    name="target"
                    type="number"
                    suffix={values.unitOfWork + 's'}
                  />
                )
              }
            </WithFormValues>
          </Col>
          <Col xs={6}>
            <Input
              name="targetPerHour"
              type="number"
              suffix="ph"
              formControlProps={{ step: 0.01 }}
              registerProps={{ required: false }}
            />
          </Col>
          <Col xs={6}>
            <Input
              name="targetPerDay"
              type="number"
              suffix="pd"
              formControlProps={{ step: 0.01 }}
              registerProps={{ required: false }}
            />
          </Col>
        </Row>
      </DetailCard>
      <DetailCard id="sampleCard" title="Sample">
        <Row>
          <Col xs={6}>
            <Input
              name="sampleType"
              type="select"
              options={['freefind', 'provided']}
            />
          </Col>
          <Col xs={6}>
            <Input
              name="expectedSampleStrikeRate"
              type="number"
              formControlProps={{ step: 0.01 }}
              suffix=":1"
            />
          </Col>
        </Row>
      </DetailCard>
      <DetailCard id="costCentersCard" title="Cost Centers">
        <ProjectCostCenterSelector
          label="Revenues"
          instruction="Select which project cost center(s) will provide this target group's revenue. This will be used to calculate the target group's revenue and profitability."
        />
        <RpuTable />
      </DetailCard>
    </DomainDetail>
  );
};
