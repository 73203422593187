import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import {
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { Project, ProjectRole } from 'apis/flex/projects';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ResponsiveModal } from 'components/common/Modals';
import { getDomainItemSelector } from 'components/common/DomainItemSelector';
import { EmployeeSelector } from 'components/app/hr/staff/widgets/EmployeeSelector';
import useEmployees from 'components/app/hr/staff/hooks/useEmployees';
import blankUser from 'assets/img/team/avatar.png';
import IconButton from 'components/common/IconButton';
import Skeleton from 'react-loading-skeleton';
import useTeamRoles from '../roles/useTeamRoles';
import WithFormValues from 'components/wizard/WithFormValues';

const RoleSelector = getDomainItemSelector('team-role');
const Follower = ({ follower }: { follower: ProjectRole }) => {
  const { employeeId, roleId } = follower;
  const { data: employee, isLoading } = useEmployees({
    id: employeeId,
    select: d => d[0]
  });
  const { data: role, isLoading: roleLoading } = useTeamRoles({
    id: roleId,
    select: d => d[0]
  });
  return (
    <div className="bg-white dark__bg-1100 p-3 h-100">
      {/* <Link to="/user/profile"> */}
      {isLoading ? (
        <Skeleton className="mb-3 shadow-sm" height={100} width={100} circle />
      ) : (
        <Image
          thumbnail
          fluid
          roundedCircle
          className="mb-3 shadow-sm"
          src={employee?.avatar || blankUser}
          width={100}
        />
      )}
      {/* </Link> */}
      <h6 className="mb-1">
        {isLoading ? (
          <Skeleton />
        ) : (
          employee?.firstName + ' ' + employee?.surname
        )}
        {/* </Link> */}
      </h6>
      <p className="fs--2 mb-1 text-700 d-flex justify-content-center">
        {roleLoading ? <Skeleton /> : role?.name}
      </p>
    </div>
  );
};
const Followers = ({
  colBreakpoints = { xs: 6, md: 4, lg: 3, xxl: 2 }
}: {
  colBreakpoints?: Record<string, number>;
}) => {
  const { fields, replace, remove } = useFieldArray<Project, 'team'>({
    name: 'team'
  });
  const { getValues, setValue } = useFormContext();
  console.log('fields', fields, getValues('team'));
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState<number>();
  // const vals = useWatch({ name: 'team' });
  // useEffect(() => {
  //   replace(vals);
  // }, [vals]);
  // const remove = i => {
  //   const current = getValues('team');
  //   setValue(
  //     'team',
  //     current.filter((_, index) => index !== i)
  //   );
  // };
  return (
    <Row className="gx-0 gy-1 text-center">
      {fields.map((follower, i) => (
        <Col
          className="cursor-pointer hover-bg-200"
          onClick={() => setIsEditing(i)}
          key={follower.id}
          {...colBreakpoints}
        >
          <Follower follower={follower} />
        </Col>
      ))}
      <div className="d-flex justify-content-end">
        <IconButton
          variant="falcon-primary"
          icon={faPlus}
          onClick={() => {
            setIsAdding(true);
            // append({
            //   id: undefined,
            //   employeeid: undefined,
            //   roleid: undefined,
            //   projectId,
            //   createdBy: null,
            //   createdDate: null
            // });
          }}
        >
          Add Team Member
        </IconButton>
      </div>
      <ResponsiveModal
        show={isAdding || !!isEditing}
        onHide={() => {
          setIsAdding(false);
          setIsEditing(undefined);
        }}
        title={isEditing ? 'Edit Team Member' : 'Add Team Member'}
      >
        <Modal.Body>
          {(isAdding || !!isEditing) && (
            <EditTeamMemberForm index={isEditing || fields.length} />
          )}
        </Modal.Body>
        <Modal.Footer>
          {isEditing && (
            <Button
              variant="danger"
              className="me-2"
              onClick={() => {
                remove(isEditing);
                setIsEditing(undefined);
              }}
            >
              Remove
            </Button>
          )}
          <WithFormValues fields={['team.' + (isEditing || fields.length)]}>
            {values => {
              const vals = values?.['team.' + (isEditing || fields.length)];
              console.log('setting vals...', vals);
              return (
                <Button
                  variant="primary"
                  disabled={!vals?.roleId || !vals?.employeeId}
                  onClick={() => {
                    if (isAdding) {
                      replace(getValues('team'));
                    }
                    setIsEditing(undefined);
                    setIsAdding(false);
                  }}
                >
                  Done
                </Button>
              );
            }}
          </WithFormValues>
        </Modal.Footer>
      </ResponsiveModal>
    </Row>
  );
};
const EditTeamMemberForm = ({ index }) => {
  return (
    <div>
      <RoleSelector name={`team.${index}.roleId`} label="Role" />
      <EmployeeSelector name={`team.${index}.employeeId`} label="Employee" />
    </div>
  );
};
export default () => {
  return <Followers />;
};
