import Lottie, { LottieComponentProps } from 'lottie-react';
import React from 'react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
export default ({
  style,
  size = 'sm',
  variant,
  ...props
}: Partial<Omit<LottieComponentProps, 'size' | 'variant'>> & {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'success' | 'danger' | 'warning' | 'info';
}) => {
  const width = size === 'sm' ? 2.2 : size === 'md' ? 3 : 4;
  const mb = size === 'sm' ? 0.7 : size === 'md' ? 1 : 1.5;
  const ml = size === 'sm' ? 0.6 : size === 'md' ? 0.8 : 1;
  const hue =
    variant === 'primary'
      ? 0
      : variant === 'success'
      ? 270
      : variant === 'danger'
      ? 130
      : variant === 'warning'
      ? 158
      : variant === 'info'
      ? 317
      : 0;
  return (
    <div
      className="position-relative d-inline-block overflow-visible"
      style={{
        width: width + 'rem',
        height: '1rem',
        marginLeft: '-' + ml + 'rem',
        marginRight: '-' + ml + 'rem'
      }}
    >
      <Lottie
        animationData={checkPrimaryLight}
        // loop={true}
        loop={false}
        style={{
          height: width + 'rem',
          marginBottom: -1,
          bottom: '-' + mb + 'rem',
          position: 'absolute',
          left: 0,
          filter: `hue-rotate(${hue}deg)`,
          ...style
        }}
        {...props}
      />
    </div>
  );
};
