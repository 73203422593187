import React, { useMemo, useState } from 'react';
import useAppRoles from './useAppRoles';
import useUserRoles from 'hooks/useUserRoles';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import WizardInput, {
  WizardInputOptions,
  WizardInputProps
} from 'components/wizard/WizardInput';
import { startCase, truncate, uniqBy } from 'lodash';
import LoadingButton from 'components/common/LoadingButton';
import { UserView } from 'apis/flex/users';
import { replaceHandlebarsWithValues } from 'helpers/strings';
import useAccessGroups from './useAccessGroups';
import WithFormValues from 'components/wizard/WithFormValues';
import { useGuard } from 'hooks/useGuard';
import { AppRole } from 'apis/flex/auth';
import useUsers from 'components/app/users/hooks/useUsers';
import { Accordion, Col, Row } from 'react-bootstrap';
import DomainIcon from 'components/common/DomainIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { SearchInput } from 'components/navbar/top/SearchBox';
import Search from 'components/common/Search';
import { domainConfigs } from 'components/notification/config';
import Skeleton from 'react-loading-skeleton';
import {
  faEdit,
  faEye,
  faPlusCircle,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
export const AccessGroupSelect = (props: Partial<WizardInputProps>) => {
  const { data: options } = useAccessGroups<WizardInputOptions>({
    useFilter: true,
    select: d =>
      d
        .map(a => ({
          value: a.id,
          label: a.name,
          description: truncate(a.description, { length: 100 }),
          exclusive: undefined
        }))
        .concat([
          { value: 0, label: 'Custom', description: null, exclusive: true }
        ])
  });
  const { isAdmin } = useGuard({ roles: ['user'] });
  return (
    <WizardInput
      name="accessGroups"
      type="select"
      label="Flex Roles"
      multiple
      readOnly={!isAdmin}
      options={options}
      {...props}
    />
  );
};
export const useAppRoleOptions = () => {
  const { data: roles = [], isLoading } = useAppRoles();
  const { userRoles } = useUserRoles();
  const isAdmin = domain => userRoles[domain] && userRoles[domain].admin;
  const groupedOptions = useMemo(
    () =>
      uniqBy(roles, 'collectionId').map(collection => ({
        label: startCase(collection.collectionName),
        options: uniqBy(roles, 'levelId').map(role => ({
          value: collection.collectionId + '#' + role.levelId,
          label: startCase(collection.collectionName) + ' ' + role.levelName,
          description: replaceHandlebarsWithValues(role.description, {
            collection: collection.collectionName
          }),
          disabled: !isAdmin(collection.collectionName) && !isAdmin('super')
        }))
      })),
    [roles, isAdmin]
  );
  return { data: groupedOptions, isLoading };
};
const levelIcons = [faEye, faEdit, faPlusCircle, faUserTie];
const GridItem = ({
  domain,
  namePrefix
}: {
  namePrefix: string;
  domain: {
    label: string;
    options: {
      value: string;
      label: string;
      description: any;
      disabled: boolean;
    }[];
  };
}) => {
  const value = useWatch({
    name: domain.options.map(o => namePrefix + o.value)
  });
  const selected = Object.keys(value).filter(k => value[k]);
  const highestLevelId = selected?.length && Math.max(...selected.map(Number));
  return (
    <Accordion.Item eventKey={domain.label}>
      <Accordion.Header className={classNames('pe-2')}>
        <Row className="w-100 align-items-center">
          <Col xs={2}>
            <DomainIcon
              size="md"
              domain={domain.label.toLowerCase()}
              bg={!selected.length ? 'light' : undefined}
              color={!selected.length ? '500' : undefined}
            />
          </Col>
          <Col xs={8}>{startCase(domain.label)}</Col>
          <Col xs={2}>
            {!!selected.length && (
              // <SoftBadge bg="info">
              <FontAwesomeIcon
                className="text-info"
                size="lg"
                icon={levelIcons[highestLevelId]}
              />
              // </SoftBadge>
            )}
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          {domain.options.map(role => (
            <Col xs={12} lg={4} xl={12} key={role.value}>
              <WizardInput
                name={namePrefix + role.value}
                type="checkbox"
                label={role.label}
                instruction={role.description}
                disabled={role.disabled}
              />
            </Col>
          ))}
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};
export const PermissionsGrid = ({
  namePrefix = ''
}: {
  namePrefix?: string;
}) => {
  const { data: options, isLoading } = useAppRoleOptions();
  return isLoading ? (
    <Accordion>
      {[0, 0, 0, 0].map((_, i) => (
        <Accordion.Item key={i} eventKey={i.toString()}>
          <Skeleton />
        </Accordion.Item>
      ))}
    </Accordion>
  ) : (
    <>
      <Search data={options} keys={['label']} className={'mb-2'}>
        {display => (
          <Accordion>
            <Row>
              {display.map(domain => (
                <Col xs={12} xl={6} key={domain.label}>
                  <GridItem domain={domain} namePrefix={namePrefix} />
                </Col>
              ))}
            </Row>
          </Accordion>
        )}
      </Search>
    </>
  );
};
export const PermissionsSelect = ({ ...rest }) => {
  const { data: options, isLoading } = useAppRoleOptions();
  return (
    <WizardInput
      type="select"
      name="rolesSelect"
      label="Custom Permissions"
      options={options}
      loading={isLoading}
      multiple
      registerProps={{
        required: false
      }}
      {...rest}
    />
  );
};

export const convertUserToPermissionsForm = (
  user: UserView,
  appRoles: AppRole[]
) => {
  const collectionLookup = new Map(appRoles?.map(r => [r.collectionName, r]));
  const levelLookup = new Map(appRoles?.map(r => [r.levelName, r]));
  if (!user) return {};
  return {
    accessGroups: user.accessGroups?.map(g => g.groupId) || [],
    rolesSelect: Object.keys(user.roles).flatMap(domain => {
      return Object.keys(user.roles[domain]).map(
        l =>
          `${collectionLookup.get(domain)?.collectionId}.${
            levelLookup.get(l)?.levelId
          }`
      );
    })
  };
};
export const convertPermissionsFormToUser = (
  vals: {
    accessGroups: number[];
    rolesSelect: string[];
  },
  userId?: number
) => {
  return {
    accessGroups: vals.accessGroups.map(a => ({
      id: undefined,
      userId,
      groupId: a
    })),
    rolesArray: vals.rolesSelect.map(r => ({
      collectionId: Number(r.split('#')[0]),
      levelId: Number(r.split('#')[1])
    }))
  };
};
export const UserPermissionsForm = ({ isLoading = false }) => {
  return (
    <>
      <AccessGroupSelect loading={isLoading} />
      <WithFormValues fields={['accessGroups']}>
        {({ accessGroups }) =>
          !!accessGroups?.length &&
          !accessGroups?.[0] && <PermissionsSelect loading={isLoading} />
        }
      </WithFormValues>
    </>
  );
};
export const UserPermissions = ({
  isLoading,
  userId
}: {
  isLoading?: boolean;
  userId: number;
}) => {
  const { data: appRoles } = useAppRoles();
  const {
    update,
    isUpdating,
    data: user,
    isLoading: userLoading
  } = useUsers({ id: userId, select: d => d[0] });
  const methods = useForm({
    values: convertUserToPermissionsForm(user, appRoles)
  });
  const handleSubmit = methods.handleSubmit(v => {
    update({
      id: user?.id,
      data: convertPermissionsFormToUser(
        { accessGroups: v.accessGroups, rolesSelect: v.rolesSelect },
        userId
      )
    });
  }, console.error);
  return (
    <FormProvider {...methods}>
      <UserPermissionsForm isLoading={isLoading || userLoading} />
      <div className="text-end">
        <LoadingButton loading={isUpdating} onClick={handleSubmit}>
          Update
        </LoadingButton>
      </div>
    </FormProvider>
  );
};
