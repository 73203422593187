import { pageReportsApi } from "apis/flex/reporting";
import { defaultCrudHookBuilder } from "hooks/useDefaultCrud";
const usePageReports = defaultCrudHookBuilder("pageReports", pageReportsApi, {
  staleTime: Infinity
});
export default usePageReports;
export const useCurrentPageReports = ({
  filters,
  section = "",
  beforeSave = (d) => d,
  location: customLocation,
  ...props
} = {}) => {
  return usePageReports({
    useFilter: true,
    filters: {
      ...filters,
      location: (customLocation || location.pathname) + "#" + section
    },
    beforeSave: (d) => ({
      ...beforeSave(d),
      location: (customLocation || location.pathname) + "#" + section
    }),
    ...props
  });
};
