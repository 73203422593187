import React from 'react';
import ProjectsTable from './ProjectsTable';

export default () => {
  return (
    <>
      <ProjectsTable />
    </>
  );
};
