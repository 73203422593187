import {
  faArrowLeft,
  faArrowRight,
  faCalendarCheck,
  faCalendarDay,
  faCheckSquare,
  faPen,
  faPlus,
  faSignature,
  faTrash,
  faUndo,
  faUserTag
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import PageLoader from 'components/common/PageLoader';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import React, {
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Button, CloseButton, Dropdown, Pagination } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import styled from 'styled-components';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import PropTypes from 'prop-types';
import { FileId } from 'apis/types';
import { ensureArray, isReactRenderable } from 'helpers/utils';
import useArray from 'hooks/useArray';
import LightboxImage from 'components/common/LightboxImage';
import ModalVideoContent from 'components/app/recruitment/campaigns/ModalVideoContent';
import classNames from 'classnames';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';

const PDFDocumentWrapper = styled.div`
  canvas {
    width: auto !important;
    height: auto !important;
    max-height: 100vh !important;
    max-width: 100% !important;
  }
`;
export type PdfViewerProps = Omit<
  HTMLProps<HTMLDivElement>,
  'onClick' | 'children' | 'width' | 'height'
> & {
  fileId: FileId | FileId[];
  onRendered?: ({
    pdfFile,
    doc
  }: {
    pdfFile: File;
    doc: DocumentCallback;
  }) => void;
  height?: number;
  width?: number;
  onClick?: () => void;
  navStyle?: 'bottom' | 'side';
  boxRef?: any;
  children?:
    | React.ReactNode
    | ((props: {
        page: number;
        setPage: (page: number) => void;
        file: File;
      }) => React.ReactNode);
};
const PdfViewer = ({
  fileId,
  onRendered,
  children,
  height,
  width,
  onClick,
  navStyle = 'bottom',
  boxRef: _boxRef,
  ...rest
}: PdfViewerProps) => {
  const fileIdArr = useArray(fileId);
  const { files, isLoading } = useFileLoader({ value: fileIdArr });
  const [pdfFile, setPdfFile] = useState<any>();
  const [page, setPage] = useState(1);
  const boxRef = _boxRef || useRef(null);
  useEffect(() => {
    if (
      files?.[0]?.preview &&
      !pdfFile &&
      files[0].preview !== pdfFile?.preview &&
      !isLoading
    ) {
      setPdfFile({
        ...files[0],
        url: files[0].preview
      });
    }
  }, [files]);
  const [doc, setDoc] = useState<DocumentCallback>();
  const onLoaded = doc => {
    setDoc(doc);
  };
  //   const [loading, setLoading] = useState(false);
  //   const [docViewport, setDocViewport] = useState<{
  //     width: number;
  //     height: number;
  //   }>(null);
  return (
    <div
      className="mb-5 rounded p-3 pt-4 d-flex justify-content-center align-items-center"
      {...rest}
    >
      <div
        className="position-relative h-100 d-inline-block"
        // style={{ minHeight: 250 }}
        ref={boxRef}
      >
        <PDFDocumentWrapper className={'d-inline-block'}>
          {pdfFile && (
            <Document
              className={classNames('d-inline-block rounded d-flex', {
                'flex-column': navStyle === 'bottom'
              })}
              onLoadSuccess={onLoaded}
              file={pdfFile}
            >
              {navStyle === 'side' && (
                <Button
                  variant="link"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                  className="btn-lg fs-4"
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              )}
              <Page
                className={classNames('d-inline-block shadow mb-2', {
                  'cursor-pointer': !!onClick
                })}
                pageNumber={page}
                height={height}
                width={width}
                onClick={onClick}
                onRenderSuccess={() => onRendered({ pdfFile, doc })}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />

              {navStyle === 'side' && (
                <Button
                  variant="link"
                  onClick={() => setPage(page + 1)}
                  disabled={page === doc?.numPages}
                  className="btn-lg fs-4"
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              )}
              {navStyle === 'bottom' && (
                <Flex justifyContent={'between'} className={'w-100'}>
                  <Button
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                    size="sm"
                  >
                    Prev
                  </Button>
                  <Button
                    onClick={() => setPage(page + 1)}
                    disabled={!doc || page === doc?.numPages}
                    size="sm"
                  >
                    Next
                  </Button>
                </Flex>
              )}
            </Document>
          )}
        </PDFDocumentWrapper>
        {isLoading && (
          <div className="w-100 h-100 position-absolute start-0 top-0 bg-100 d-flex opacity-75">
            <PageLoader height={'100%'} />
          </div>
        )}
        {typeof children === 'function'
          ? children({ page, setPage, file: pdfFile })
          : children}
      </div>
    </div>
  );
};
export default ({ children, height, width, ...props }: PdfViewerProps) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <ModalVideoContent show={show} setShow={setShow}>
        <PdfViewer navStyle="side" style={{ maxHeight: '90vh' }} {...props}>
          {children}
        </PdfViewer>
      </ModalVideoContent>
      <PdfViewer
        onClick={() => setShow(true)}
        {...props}
        width={width}
        height={height}
      >
        {children}
      </PdfViewer>
    </div>
  );
};
