import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import LoadingButton from 'components/common/LoadingButton';
import { downloadCSV } from 'helpers/utils';
import useCampaignLink from './hooks/useCampaignLink';

const BatchInviteModal = ({ show, setShow, campaign }) => {
  const { link } = useCampaignLink({ campaign });
  const methods = useForm();
  const [database, setDatabase] = useState([]);
  const [headers, setHeaders] = useState([]);
  const csv = methods.watch('emailsCsv');
  const emailField = methods.watch('emailField');
  useEffect(() => {
    if (csv) {
      const reader = new FileReader();
      reader.readAsText(csv[0]);
      reader.onload = () => {
        const csvData = reader.result;
        const lines = csvData.split('\n');
        const headers = lines[0].split(',');
        headers.push('InviteLink');
        setHeaders(headers);
        const data = lines.map(line => {
          const values = line.split(',');
          const obj = {};
          headers.forEach((header, index) => {
            if (header === emailField) {
              obj.email = values[index];
            } else {
              obj[header] = values[index];
            }
          });
          obj.InviteLink = link;
          return obj;
        });
        setDatabase(data);
      };
    }
  }, [csv, emailField, link]);
  const handleInvite = () => {
    downloadCSV(database, 'batch-invite');
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="overflow-hidden"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Batch invite candidates</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light p-4">
        <FormProvider {...methods}>
          <WizardInput
            type="file"
            pluginProps={{ maxFiles: 1, accept: { 'text/csv': [] } }}
            name="emailsCsv"
            registerProps={{ required: false }}
          />
          <WizardInput type="select" name="emailField" options={headers} />
          <WizardInput
            type="textarea"
            name="emailBody"
            label="Message (optional)"
            registerProps={{ required: false }}
          />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <LoadingButton disabled={!database.length} onClick={handleInvite}>
          Invite {database.length} candidates
        </LoadingButton>
      </Modal.Footer>
    </Modal>
  );
};

BatchInviteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  campaign: PropTypes.object
};

export default BatchInviteModal;
