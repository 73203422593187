import React, { useEffect, useMemo, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

export default <TData,>({
  data,
  getNext,
  children,
  totalPages,
  isLoadingNext,
  ...rest
}: {
  data: (TData[] | 'loading')[];
  getNext: () => void;
  isLoadingNext?: boolean;
  children: (props: { data: TData[]; isLoading: boolean }) => React.ReactNode;
  totalPages: number;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'onChange'>) => {
  const listRef = useRef(null);
  const handleScroll = () => {
    const container = listRef.current;
    if (
      container.scrollTop + container.clientHeight >
        container.scrollHeight * 0.9 &&
      !isLoadingNext
    ) {
      getNext();
    }
  };
  useEffect(() => {
    listRef.current?.addEventListener('scroll', handleScroll);
    return () => listRef.current?.removeEventListener('scroll', handleScroll);
  }, [listRef.current]);
  const childrenMemo = useMemo(() => {
    return children({
      isLoading: !data?.[0] || data[0] === 'loading',
      data: data?.filter(d => d !== 'loading')?.flat() as TData[]
    });
  }, [children, data]);
  return (
    <div ref={listRef} {...rest}>
      {childrenMemo}
      {isLoadingNext && <Skeleton height={50} />}
    </div>
  );
};
