import React, { useEffect, useRef } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
export default ({ children, direction, motionKey }) => {
  const variants = {
    exit: p =>
      p === 'backward' ? { x: '150%', opacity: 0 } : { x: '-150%', opacity: 0 }
  };
  const [keyedChildren, setKeyedChildren] = React.useState({});
  useEffect(() => {
    if (keyedChildren?.[motionKey]) return;
    setKeyedChildren(c => ({
      ...c,
      [motionKey]: (
        <motion.div
          custom={direction}
          key={motionKey}
          id={motionKey}
          // layoutScroll
          initial={{
            x: direction === 'backward' ? '-150%' : '150%',
            opacity: 0
          }}
          animate={{ x: 0, opacity: 1 }}
          variants={variants}
          exit={'exit'}
          // className="position-relative w-100"
          // style={{ transition: 'height 0.5s' }}
          transition={{ ...transitions.lightBounce, damping: 17 }}
          // transition={{ duration: 2 }}
        >
          {children}
        </motion.div>
      )
    }));
  }, [motionKey]);
  const prevChildrenRef = useRef(children);

  // Update the ref only after the exit animation has completed
  useEffect(() => {
    prevChildrenRef.current = children;
  }, [motionKey]);

  // Use the previous children during exit animation
  const renderChildren =
    motionKey === prevChildrenRef.current ? prevChildrenRef.current : children;

  // console.log('slider children', keyedChildren, keyedChildren?.[motionKey]);
  return (
    <AnimatePresence mode="popLayout" initial={false} custom={direction}>
      {/* {keyedChildren?.[motionKey]} */}
      <motion.div
        custom={direction}
        key={motionKey}
        id={motionKey}
        // layoutScroll
        initial={{
          x: direction === 'backward' ? '-150%' : '150%',
          opacity: 0
        }}
        animate={{ x: 0, opacity: 1 }}
        variants={variants}
        exit={'exit'}
        // className="position-relative w-100"
        // style={{ transition: 'height 0.5s' }}
        transition={{ ...transitions.lightBounce, damping: 17 }}
        // transition={{ duration: 2 }}
      >
        {renderChildren}
      </motion.div>
    </AnimatePresence>
  );
};
