import { useQuery } from "@tanstack/react-query";
import {
  getAllUserNotifications,
  getUserNotifications
} from "apis/flex/notifications";
export default ({
  id,
  filters,
  enabled = true,
  ...rest
}) => {
  return useQuery({
    queryKey: ["Notifications", id, filters],
    queryFn: () => getUserNotifications(1, id, filters),
    enabled,
    ...rest
  });
};
export const useNewDomainNotifications = ({
  domain,
  ...rest
}) => {
  return useQuery({
    queryKey: ["NewNotifications", domain],
    queryFn: () => getAllUserNotifications({ domain, actioned: "null" }),
    staleTime: 1e3 * 20,
    ...rest
  });
};
