import React, { useReducer, Suspense } from 'react';
import AppContext from 'context/Context';
import { CookiesProvider } from 'react-cookie';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import PageLoader from 'components/common/PageLoader';
import 'react-loading-skeleton/dist/skeleton.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';

import { Chart as ChartJS, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import { atomWithStorage } from 'jotai/utils';
import { apiError } from 'apis/errors';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

ChartJS.register(...registerables);

const Main = () => {
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    isDemo: getItemFromStore('isDemo', settings.isDemo)
  };
  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryOnMount: false,
        networkMode: 'always'
      },
      mutations: {
        onError: apiError,
        networkMode: 'always'
      }
    }
  });
  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'isDemo',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };
  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />

      <AppContext.Provider value={{ config, setConfig, configDispatch }}>
        <CookiesProvider>
          <Suspense fallback={<PageLoader />}>
            <App />
          </Suspense>
        </CookiesProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};
export const returnToAtom = atomWithStorage('returnTo', null, sessionStorage);
export default Main;
