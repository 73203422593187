import EChartsReactCore from 'echarts-for-react/lib/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import React, { forwardRef } from 'react';
import { ReportComponentProps } from '../helpers';
import { getChartOptions } from './chartOptions';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

export default forwardRef<EChartsReactCore, ReportComponentProps>(
  (
    { type, series = [], xAxes = [], yAxes = [], simple, height, stacked },
    ref
  ) => {
    return (
      <>
        <ReactEChartsCore
          ref={ref}
          echarts={echarts}
          option={getChartOptions(type, stacked)(simple, xAxes, yAxes, series)}
          style={{
            height: height || (simple ? 87 : '20rem'),
            width: '100%'
          }}
        />
      </>
    );
  }
);
