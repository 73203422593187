import CustomReportTiles from 'components/app/reporting/CustomReportTiles';
import FileExplorer from 'components/files/FileExplorer';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
export default () => {
  return (
    <Row>
      <Col>
        <CustomReportTiles
          reportFilters={{ db: 'System', table: 'FileStorage' }}
          defaultValues={{ db: 'System', table: 'FileStorage' }}
        />
      </Col>
      <Col xs={12}>
        <FileExplorer />
      </Col>
    </Row>
  );
};
