import React from 'react';
import {
  CrudItemSelectorProps,
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
import { Department } from 'apis/flex/hr';
import { SelectedDomainItemsProps } from 'components/app/users/widgets/selector/UserSelector';

export const DepartmentSelector = ({
  filter,
  ...rest
}: Omit<CrudItemSelectorProps<Department>, 'domain' | 'crudHook'>) => {
  const Selector = getDomainItemSelector('department');
  return (
    <Selector
      name="departmentId"
      label="Department"
      placeholder="Select a department"
      filter={filter}
      {...rest}
    />
  );
};
export const SelectedDepartment = ({
  ...props
}: Omit<SelectedDomainItemsProps, 'domain'>) => {
  const Selected = getSelectedDomainItems('department');
  return <Selected {...props} />;
};
