import React from 'react';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Employee } from 'apis/flex/hr';
import { Row } from '@tanstack/react-table';
import useEmployees from './hooks/useEmployees';
import { faPassport } from '@fortawesome/free-solid-svg-icons';
import useJobRoles from '../jobRoles/useJobRoles';
import useUrlParamFilters from 'components/common/advance-table-v2/useUrlParamFilters';
import { NewPrescreeningModal } from '../prescreening/NewPrescreening';
import DomainTable, { DomainTableProps } from 'components/common/DomainTable';
import DomainAvatar from 'components/common/DomainAvatar';
import blankUser from 'assets/img/team/avatar.png';

export const EmployeesTable = (props: Partial<DomainTableProps<Employee>>) => {
  const nav = useNavigate();
  const handleRowClick = (r: Row<Employee>) => {
    nav('/hr/staff/' + r.original.id);
  };
  const { data: jt } = useJobRoles<{ value: number; label: string }[]>({
    useFilter: true,
    select: d => d.map(d => ({ value: d.id, label: d.jobTitle }))
  });
  const [showCheck, setShowCheck] = React.useState<number[]>(null);
  return (
    <DomainTable
      domain="employee"
      crudHook={useEmployees}
      actions={[
        {
          name: 'Request screening',
          onClick: row => setShowCheck([row.original.id]),
          icon: faPassport
        }
      ]}
      bulkActions={[
        {
          name: 'Request screening',
          actionFn: async rows =>
            setShowCheck(rows.map(row => Number(row.original.id))),
          icon: faPassport
        }
      ]}
      columns={[
        {
          id: 'avatar',
          enableColumnFilter: false,
          maxSize: 50,
          header: '',
          formatter: v => (
            <DomainAvatar size="sm" src={v() || blankUser} domain="user" />
          )
        },
        'firstName',
        'surname',
        'email',
        { id: 'jobTitleId', options: jt, header: 'Job Title' },
        'onboardDate',
        'mobileNumber'
      ]}
      onRowClick={handleRowClick}
      {...props}
    >
      <AdvanceTable />
      <NewPrescreeningModal
        show={!!showCheck}
        defaultValues={{ employeeIds: showCheck }}
        setShow={setShowCheck}
      />
    </DomainTable>
  );
};

const Employees = () => {
  const filters = useUrlParamFilters<Employee>();
  return (
    <Tabs defaultActiveKey={0}>
      <Tab title="All" eventKey={0}>
        <EmployeesTable initialFilters={filters} />
      </Tab>
    </Tabs>
  );
};
export default Employees;
