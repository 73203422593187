import {
  faCheck,
  faEnvelope,
  faFastForward,
  faFilter,
  faRedo,
  faThumbsDown,
  faThumbsUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import DomainTimeline, {
  DomainTimelineFilter,
  formatDomainEvent
} from 'components/common/DomainTimeline';
import { camelToSentence } from 'helpers/utils';
import usePagedDomainEvents from 'hooks/usePagedDomainEvents';
import React, { useState } from 'react';
import useApplicantPipelines from '../hooks/useApplicantPipelines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomTooltip from 'components/common/Tooltip';
import { TimelineItem } from 'components/common/Timeline';
import LoadingButton from 'components/common/LoadingButton';
import { ModalConfirm } from 'components/common/Modals';
import { Applicant } from 'apis/flex/recruitment';
import PipelineResults from '../../campaigns/create/PipelineResults';
import InfoBadge from 'components/common/InfoBadge';
import { UserIconsTooltip } from 'components/app/users/widgets/UserIcons';
import CustomPopover from 'components/common/CustomPopover';
const actionTypes = {
  accept: { icon: faThumbsUp, label: 'Accept applicant', variant: 'success' },
  reject: { icon: faThumbsDown, label: 'Reject applicant', variant: 'danger' },
  stage: { icon: faFastForward, label: 'Move to stage', variant: 'info' }
};
const PipelineTitle = ({ pipelineId, pipelineName }) => {
  const { run, isRunning } = useApplicantPipelines();
  return (
    <div className="d-flex justify-content-between align-items-baseline">
      <div className="d-flex flex-column">
        <div>Campaign pipeline</div>
        <div className="fs-0 fw-semi-bold mb-2 mt-1 text-500">
          {pipelineName}
        </div>
      </div>
      <CustomTooltip content={'Re-run pipeline'}>
        <div>
          <ModalConfirm
            title="Re-run pipeline"
            body="Are you sure you want to re-run this pipeline? Any actions it triggers will be carried out"
            onConfirm={done => {
              done();
              run(pipelineId);
            }}
          >
            <LoadingButton
              loading={isRunning}
              variant="falcon-default"
              size="sm"
            >
              {!isRunning && <FontAwesomeIcon icon={faRedo} />}
            </LoadingButton>
          </ModalConfirm>
        </div>
      </CustomTooltip>
    </div>
  );
};
export const useApplicantTimeline = ({
  applicant,
  filters
}: {
  applicant: Applicant;
  filters: DomainTimelineFilter;
}) => {
  const { data: responseEvents } = usePagedDomainEvents({
    domain: 'form-response',
    itemId: applicant?.responseId + '',
    enabled: !!applicant,
    ...filters
  });
  const timeline =
    responseEvents?.pages?.flatMap(page => page.map(formatDomainEvent)) || [];
  let stageEvents = [];
  applicant?.stages?.forEach(stage => {
    stageEvents.push({
      title: 'Moved to ' + stage.stageName,
      date: stage.startDate,
      icon: stage.sendEmail ? faEnvelope : faFastForward
    });
    if (stage.completedDate) {
      stageEvents.push({
        title: stage.stageName + ' completed',
        date: stage.completedDate,
        icon: stage.sendEmail ? faEnvelope : faFastForward
      });
    }
    if (stage.reviewDate) {
      stageEvents.push({
        title: stage.stageName + ' ' + stage.isPass ? 'passed' : 'failed',
        date: stage.reviewDate,
        icon: stage.isPass ? faCheck : faTimes,
        userId: stage.reviewedBy
      });
    }
  });
  if (applicant?.acceptRejectDate) {
    stageEvents.push({
      title: camelToSentence(applicant.acceptReject),
      date: applicant.acceptRejectDate,
      icon: applicant.acceptReject === 'accepted' ? faCheck : faTimes,
      userId: applicant.acceptRejectBy
    });
  }
  stageEvents = stageEvents.map(ev => ({
    ...ev,
    date: new Date(ev.date)
  }));
  const { data: pipelines } = useApplicantPipelines<TimelineItem[]>({
    applicantId: applicant?.id,
    select: d =>
      d.map(p => ({
        title: (
          <PipelineTitle pipelineId={p.id} pipelineName={p.pipeline.name} />
        ),
        date: new Date(p.runDate),
        icon: faFilter,
        description: (
          <>
            <h6 className="mt-3">Results</h6>
            {p.cancelledDate ? (
              <div className="d-flex align-items-center">
                <CustomTooltip
                  content={<UserIconsTooltip ids={[p.cancelledBy]} />}
                >
                  <div>Cancelled</div>
                </CustomTooltip>
                <InfoBadge>
                  This could be due the applicant being accepted/rejected by a
                  previous pipeline, or by a user manually cancelling
                </InfoBadge>
              </div>
            ) : !p.filterResults ? (
              'Awaiting...'
            ) : (
              <>
                <CustomPopover
                  body={<PipelineResults results={p.filterResults} />}
                >
                  {/* <div className="mt-3 ms-1"> */}
                  <a href="javascript:void(0)">
                    {p.isTriggered ? 'Filters passed' : 'Filters not passed'}
                  </a>
                  {/* </div> */}
                </CustomPopover>
                <h6 className="mt-3">Actions</h6>
                {(p.isTriggered
                  ? p.pipeline.actions
                  : p.pipeline.elseActions
                )?.map((a, i) => (
                  <div key={i}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon={actionTypes[a.type]?.icon}
                    />
                    {actionTypes[a.type]?.label}
                  </div>
                ))}
                {!(p.isTriggered ? p.pipeline.actions : p.pipeline.elseActions)
                  ?.length && <div className="text-muted">No actions</div>}
              </>
            )}
          </>
        )
      }))
  });

  // console.log('getting applicant pipeline', applicant?.id, pipelines);
  return { events: timeline?.concat(stageEvents).concat(pipelines) };
};
export default ({ applicant }) => {
  const [eventFilters, setEventFilters] = useState<DomainTimelineFilter>({});
  const { events } = useApplicantTimeline({ applicant, filters: eventFilters });
  return (
    <DomainTimeline
      domain="applicant"
      moreEvents={events}
      itemId={applicant.id}
      onFilter={setEventFilters}
    />
  );
};
