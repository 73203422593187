import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { sentryInstance } from 'index';
import useSiteRoutes from 'hooks/useSiteRoutes';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import LocationListener from './LocationListener';
const FalconRoutes = () => {
  const { allRoutes } = useSiteRoutes();
  const sentryCreateBrowserRouter =
    sentryInstance.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter([
    {
      element: (
        <>
          <ToastContainer
            closeButton
            stacked
            icon={false}
            position={'bottom-left'}
            draggablePercent={50}
            containerId={'default'}
            limit={10}
          />
          <ToastContainer
            closeButton
            stacked
            position={'top-right'}
            draggablePercent={50}
            containerId={'notifications'}
            limit={10}
          />
          <LocationListener />
          <Outlet />
        </>
      ),
      children: allRoutes
    }
  ]);
  return <RouterProvider router={router} />;
};
export default FalconRoutes;
