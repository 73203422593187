import { useQuery } from "@tanstack/react-query";
export default ({ select }) => {
  return useQuery({
    queryKey: ["questionnaire", "languages"],
    queryFn: async () => {
      const res = await fetch(
        "https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation"
      );
      const data = await res.json();
      return data.translation;
    },
    select
  });
};
