export const blobUrlToFile = async (blobUrl, fileName, fileConfig = {}) => {
  try {
    const response = await fetch(blobUrl);
    if (!response.ok) throw new Error("Failed to fetch blob");
    const blobData = await response.blob();
    return new File([blobData], fileName, fileConfig);
  } catch (error) {
    console.error("Error converting blob URL to file:", error);
    throw error;
  }
};
