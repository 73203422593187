import React from 'react';
import PageHeader from 'components/common/PageHeader';
import { Accordion, Card } from 'react-bootstrap';
import ComingSoon from 'components/extra/ComingSoon';

const Faq = () => {
  return (
    <>
      <PageHeader
        title="FAQ"
        description="Below you'll find answers to the questions we get asked the most about with Flex"
        className="mb-3"
      />
      <Card>
        <Card.Body>
          <Accordion
            id="accordionFaq"
            className="border rounded overflow-hidden"
          >
            <ComingSoon />
            {/* {faqs.map((faq, index) => (
              <FaqAccordionItem
                key={faq.id}
                faq={faq}
                isFirst={index === 0}
                isLast={index === faqs.length - 1}
              />
            ))} */}
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
};

export default Faq;
