import { useState, useEffect } from 'react';

const useVisibilityObserver = (element, rootMargin, threshold = 0) => {
  const [isVisible, setState] = useState(false);
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const checkVisibility = () => {
      if (element.current) {
        const rect = element.current.getBoundingClientRect();
        const inViewport =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= window.innerHeight &&
          rect.right <= window.innerWidth;
        setState(inViewport);
      }
    };

    // Check visibility immediately
    checkVisibility();
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log('visibility', element.current, entry, entry.isIntersecting);
        setState(entry.isIntersecting);
      },
      { rootMargin, threshold }
    );

    setObserver(observer);

    element.current && observer.observe(element.current);
    return () => {
      if (element.current) {
        observer.unobserve(element.current);
      }
    };
  }, [element.current]);

  return { isVisible, observer };
};

export default useVisibilityObserver;
