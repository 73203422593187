import React from 'react';
import AdvanceTableProvider from 'components/common/advance-table-v2/AdvanceTableProvider';
import AdvanceTable from 'components/common/advance-table-v2/AdvanceTable';
import useUserTraining from '../hooks/useUserTraining';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
export default ({
  courseId,
  userId
}: {
  courseId?: number;
  userId?: number;
}) => {
  const nav = useNavigate();
  const { data, isLoading, error } = useUserTraining({
    useFilter: true,
    filters: { courseId, userId }
  });
  return (
    <AdvanceTableProvider
      error={error}
      title="Sessions"
      sqlDb="HR"
      sqlTables={['UserTrainingCourses']}
      onRowClick={r => {
        nav('/hr/training/courses/sessions/' + r.original.id);
      }}
      columns={[
        !userId && { id: 'userId', header: 'Trainee', domain: 'user' },
        !courseId && {
          id: 'courseId',
          header: 'Course',
          domain: 'training-course'
        },
        { id: 'enrolledDate', header: 'Enrolled' },
        { id: 'deadlineDate', header: 'Deadline' },
        { id: 'lastSubmittedDate', header: 'Last Submitted' },
        {
          id: 'status',
          header: 'Status',
          formatter(valuefn) {
            const v = valuefn();
            return (
              <>
                {v === 'Pending approval' && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-warning me-2"
                  />
                )}
                {v}
              </>
            );
          },
          accessorFn: d =>
            d.resources?.every(r => r.approvedDate)
              ? 'Completed'
              : d.resources?.some(r => r.failedDate)
              ? 'Failed'
              : d.resources?.some(
                  r => r.finishedDate && !r.approvedDate && !r.failedDate
                )
              ? 'Pending approval'
              : 'In Progress'
        }
      ]}
      data={data}
      isLoading={isLoading}
    >
      <AdvanceTable />
    </AdvanceTableProvider>
  );
};
