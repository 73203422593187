import DomainDetail from 'components/common/DomainDetail';
import React from 'react';
import useShifts from './useShifts';
import WizardInput from 'components/wizard/WizardInput';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import DetailCard from 'components/common/detail/DetailCard';
import {
  AvailabilityRulesForm,
  convertShiftRulesFromForm,
  convertShiftRulesToForm
} from './AvailabilityRules';

export default () => {
  return (
    <DomainDetail
      domain="shift"
      crudHook={useShifts}
      saveMutator={d => ({
        ...d,
        availabilityRules: convertShiftRulesFromForm(d.id, d.availabilityRules)
      })}
      loadMutator={d => ({
        ...d,
        availabilityRules: convertShiftRulesToForm(d.availabilityRules)
      })}
    >
      <DetailCard id="shiftDetail" title="Detail">
        <ResourceGroupSelector name="resourceGroupId" label="Resource Group" />
        <WizardInput type="time" name="startTime" label="Start Time" />
        <WizardInput type="time" name="endTime" label="End Time" />
      </DetailCard>
      <DetailCard
        id="shiftAvailabilityRules"
        title="Availability Rules"
        subtitle="Rules that define who is able to book this shift"
      >
        <AvailabilityRulesForm name="availabilityRules" />
      </DetailCard>
    </DomainDetail>
  );
};
