import api from ".";
import CrudApi from "./CrudApi";
const baseUrl = "pm/";
export const clientsApi = new CrudApi(baseUrl + "clients");
export const clientContactsApi = new CrudApi(
  baseUrl + "clients/contacts"
);
export const projectsApi = new CrudApi(baseUrl + "projects");
export const quotesApi = new CrudApi(baseUrl + "quotes");
export const questionnaireApi = new CrudApi(
  baseUrl + "questionnaires"
);
export const targetGroupsApi = new CrudApi(
  baseUrl + "target-groups"
);
export const resourceGroupsApi = new CrudApi(
  baseUrl + "resource-groups"
);
export const teamRolesApi = new CrudApi(baseUrl + "team-roles");
export const projectRolesApi = new CrudApi(
  baseUrl + "project-roles"
);
export const targetGroupStatsApi = {
  rpu: (targetGroupDefinition) => api.get(`${baseUrl}target-groups/rpu`, {
    params: targetGroupDefinition
  }).then((d) => d.data)
};
