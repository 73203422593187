import {
  faGripVertical,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Wrapper from 'helpers/Wrapper';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProps
} from 'react-beautiful-dnd';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { BottomBarAction } from './detail/DetailPage';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import WatchedValue from 'components/wizard/WatchedValue';
import IconButton from './IconButton';
import { resolveOptionalBooleanProperty } from './BottomBar';
import SettingsBox from './SettingsBox';
import Skeleton from 'react-loading-skeleton';
import { FormPlaceholder } from 'components/wizard/FormWizard';
import { motion } from 'framer-motion';
import { transitions } from 'helpers/animations';
type PropToCall<T> = T | ((value: any, index: number, item: TabItem) => T);
export type TabItem = {
  id: any;
  index?: number;
  tabClass?: PropToCall<string>;
  disabled?: PropToCall<boolean>;
  title: PropToCall<ReactNode>;
  content: PropToCall<ReactNode>;
  icon?: PropToCall<IconProp>;
  draggable?:
    | {
        draggableId: string;
      }
    | boolean;

  /**
   * Watch this form field for updates to UI
   */
  formField?: string;
};

const NavLink = ({ item, index, dragHandleProps, tabWidth }) => {
  return (
    <Wrapper
      condition={!!item.formField}
      wrapper={children => (
        <WatchedValue name={item.formField} mutator={val => children(val)} />
      )}
    >
      {val => {
        const callProp = prop => {
          return typeof prop === 'function' ? prop(val, index, item) : prop;
        };
        return (
          <Nav.Link
            eventKey={item.id}
            disabled={callProp(item.disabled)}
            className={classNames(
              'd-flex align-items-center pe-4',
              callProp(item.tabClass)
            )}
            style={{ isolation: 'auto' }}
          >
            {!!item.draggable && (
              <div {...dragHandleProps}>
                <FontAwesomeIcon
                  className="me-2 text-500 "
                  icon={faGripVertical}
                  size="1x"
                />
              </div>
            )}
            {item.icon && callProp(item.icon) && (
              <FontAwesomeIcon icon={callProp(item.icon)} className="me-2" />
            )}
            <div
              className="d-block overflow-hidden"
              style={{
                maxWidth: tabWidth,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {callProp(item.title)}
            </div>
          </Nav.Link>
        );
      }}
    </Wrapper>
  );
};
const AddLink = ({ addBtn, onClick }) => {
  return (
    <IconButton
      onClick={() => {
        if (typeof addBtn !== 'boolean') {
          addBtn.fn();
        }
        onClick();
      }}
      variant="falcon-primary"
      icon={faPlusCircle}
    >
      {(typeof addBtn !== 'boolean' && addBtn.label) || 'Add'}
    </IconButton>
  );
};
export default ({
  items,
  droppable,
  activeKey,
  actions,
  addBtn,
  alignment,
  setActiveKey,
  tabTitle,
  isLoading,
  showTabs = true
}: {
  tabTitle?: string;
  items: TabItem[];
  droppable?:
    | (Omit<DroppableProps, 'children'> & { dropOnTop?: boolean })
    | true;
  activeKey?: any;
  actions?: BottomBarAction[];
  alignment?: 'side' | 'top';
  formField?: string;
  setActiveKey?: (key: any) => void;
  addBtn?:
    | {
        label?: ReactNode;
        fn: () => void;
      }
    | true;
  isLoading?: boolean;
  showTabs?: boolean;
}) => {
  const { breakpoints } = useBreakpoints();
  const tabWidth = '10vw';
  const firstItemId = !!items && items[0]?.id;
  const [active, setActive] = useState<any>(activeKey || firstItemId);
  const [activateLast, setActivateLast] = useState(false);
  useEffect(() => {
    const lastItemId = !!items && items[items.length - 1]?.id;
    //if item is deleted, default to last or first
    if (!items?.some(i => i.id == activeKey || i.id == active)) {
      if (activateLast) {
        setActivateLast(false);
        return (setActiveKey || setActive)(lastItemId);
      }
      return (setActiveKey || setActive)(firstItemId);
    }
  }, [items, activateLast]);
  const handleAdd = () => {
    (setActiveKey || setActive)('');
    setActivateLast(true);
  };
  const memoItems = useMemo(() => {
    return items;
  }, [items]);
  const shouldBeSide =
    showTabs &&
    (alignment === 'side' || (breakpoints.up('sm') && alignment !== 'top'));
  // console.log(
  //   'shouldBeSide',
  //   shouldBeSide,
  //   alignment,
  //   breakpoints.up('sm'),
  //   tabWidth
  // );
  return (
    <Tab.Container
      defaultActiveKey={activeKey || firstItemId}
      activeKey={activeKey || active || firstItemId}
      mountOnEnter
      onSelect={setActiveKey || setActive}
    >
      <Row
        className={classNames('g-3 p-2 mt-2', {
          'flex-nowrap': shouldBeSide
        })}
      >
        <motion.div
          className={classNames('overflow-hidden col mt-0', {
            'col-auto': alignment === 'side',
            'col-12': !shouldBeSide,
            'col-lg-auto': alignment !== 'top',
            'd-none': !showTabs
          })}
          initial={{ width: shouldBeSide ? tabWidth : '100%' }}
          whileHover={{ width: shouldBeSide ? '20vw' : '100%' }}
          transition={transitions.lightBounce}
        >
          {/* <Col
            sm={alignment === 'side' ? 'auto' : 12}
            lg={alignment === 'top' ? null : 'auto'}
            className="mt-0"
            style={{
              maxWidth: shouldBeSide ? tabWidth : '100%'
            }}
          > */}
          <Wrapper
            condition={!droppable}
            wrapper={children => (
              <DragDropContext onDragEnd={null}>{children}</DragDropContext>
            )}
          >
            <Droppable
              isDropDisabled={!droppable}
              direction={
                breakpoints.down('lg') || alignment === 'top'
                  ? 'horizontal'
                  : 'vertical'
              }
              droppableId={
                (droppable &&
                  typeof droppable !== 'boolean' &&
                  droppable?.droppableId) ||
                'navDrop'
              }
              type="droppableItem"
              {...(droppable === true ? {} : droppable)}
            >
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {/* <Card.Header> */}
                  <Wrapper
                    condition={!!tabTitle}
                    wrapper={children => (
                      <SettingsBox title={tabTitle}>{children}</SettingsBox>
                    )}
                  >
                    <Nav
                      // variant="pills"
                      as="ul"
                      className={classNames(
                        'align-items-stretch nav-tabs border-0 chart-tab pb-2 gap-1',
                        {
                          'flex-nowrap overflow-x-auto w-100':
                            breakpoints.down('lg') || alignment === 'top',
                          'flex-column':
                            breakpoints.up('lg') && alignment !== 'top'
                        }
                      )}
                    >
                      {isLoading
                        ? [0, 0, 0, 0].map((_, i) => (
                            <Nav.Item
                              as="li"
                              key={i}
                              style={{
                                maxWidth: '100%',
                                position: 'relative'
                              }}
                            >
                              <Skeleton width={200} height={27} />
                            </Nav.Item>
                          ))
                        : memoItems?.map((item, i) => (
                            <Nav.Item
                              key={
                                (typeof item.draggable !== 'boolean' &&
                                  item.draggable?.draggableId) ||
                                item.id
                              }
                              as="li"
                              style={{
                                maxWidth: '100%',
                                position: 'relative'
                              }}
                            >
                              <Draggable
                                draggableId={'dragItem' + item.id}
                                index={i}
                                isDragDisabled={!item.draggable}
                                {...(item.draggable === true
                                  ? {}
                                  : item.draggable)}
                              >
                                {provided => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      className={classNames({
                                        'bg-primary-subtle text-primary border-bottom':
                                          droppable !== true &&
                                          droppable?.dropOnTop
                                      })}
                                      style={
                                        droppable !== true &&
                                        droppable?.dropOnTop
                                          ? { cursor: 'copy' }
                                          : provided.draggableProps.style
                                      }
                                    >
                                      <NavLink
                                        item={item}
                                        index={i}
                                        tabWidth={
                                          shouldBeSide ? '50vw' : '100%'
                                        }
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                              {/* {droppable !== true && droppable.dropOnTop && (
                          <Droppable droppableId="dropToParent">
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="position-absolute w-100 h-100 start-0 top-0"
                                style={{
                                  pointerEvents: 'none'
                                }}
                              >
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        )} */}
                            </Nav.Item>
                          ))}
                      {droppable !== true && droppable?.dropOnTop
                        ? null
                        : provided.placeholder}
                      {!!addBtn && (
                        <AddLink addBtn={addBtn} onClick={handleAdd} />
                      )}
                      {actions &&
                        actions.map(a => (
                          <IconButton
                            key={a.label}
                            variant="falcon-primary"
                            size="sm"
                            onClick={() => {
                              a.fn();
                            }}
                            disabled={resolveOptionalBooleanProperty(
                              a.disabled
                            )}
                            icon={a.icon}
                          >
                            {a.label}
                          </IconButton>
                        ))}
                    </Nav>
                  </Wrapper>
                  {/* </Card.Header> */}
                </div>
              )}
            </Droppable>
          </Wrapper>
          {/* </Col> */}
        </motion.div>
        {/* <motion.div > */}
        <Col
          sm={alignment === 'side' ? 'auto' : 12}
          lg={alignment === 'top' ? null : 'auto'}
          className={classNames('flex-1 m-0', {
            'px-2': !!showTabs
          })}
          // style={{
          //   maxWidth:
          //     alignment === 'side' || breakpoints.up('sm')
          //       ? 'calc(100% - 17vw)'
          //       : '100%'
          // }}
        >
          <Tab.Content>
            {isLoading ? (
              <Tab.Pane eventKey={activeKey || active || firstItemId}>
                <Card>
                  <FormPlaceholder />
                </Card>
              </Tab.Pane>
            ) : (
              items?.map((item, i) => (
                <Tab.Pane eventKey={item.id} key={item.id}>
                  {typeof item.content === 'function'
                    ? item.content(item, i, item)
                    : item.content}
                </Tab.Pane>
              ))
            )}
          </Tab.Content>
        </Col>
        {/* </motion.div> */}
      </Row>
    </Tab.Container>
  );
};
