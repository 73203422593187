import { useQuery } from "@tanstack/react-query";
import { customFormQuestionsApi } from "apis/flex/customForms";
import { useMemo } from "react";
export default ({
  formId,
  select,
  ...rest
}) => {
  const enabled = useMemo(() => !!formId, [formId]);
  return useQuery({
    queryKey: ["CustomForms", "CustomFormQuestions", formId],
    queryFn: async () => {
      if (!formId) return Promise.resolve([]);
      return await customFormQuestionsApi.get({
        first: "formId",
        second: formId
      });
    },
    enabled,
    select,
    staleTime: Infinity,
    ...rest
  });
};
