import {
  faCalendarCheck,
  faCalendarDay,
  faCheckSquare,
  faPen,
  faPlus,
  faSignature,
  faTrash,
  faUndo,
  faUserTag
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import PageLoader from 'components/common/PageLoader';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  CloseButton,
  Dropdown,
  DropdownButton,
  DropdownItem
} from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import styled from 'styled-components';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import PropTypes from 'prop-types';
import FilePdfViewer from 'components/files/FilePdfViewer';
import { camelToSentence } from 'helpers/utils';

const PDFDocumentWrapper = styled.div`
  canvas {
    width: auto !important;
    height: auto !important;
    max-height: 100vh !important;
    max-width: 100% !important;
  }
`;

const signWellPlaceholderDimensions = {
  width: 112,
  height: 32
};
const OverlayItem = ({
  page,
  onPosition,
  onRemove,
  position,
  containerRef,
  docViewport,
  color,
  icon
}) => {
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0, page });
  const [dragging, setDragging] = useState(false);
  const sigRef = useRef(null);
  const rect = containerRef.current?.getBoundingClientRect();
  const getMousePosition = e => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (!rect) return { x: 0, y: 0, page };
    const sig = sigRef.current.getBoundingClientRect();
    const pos = {
      x: Math.min(rect.width - sig.width, Math.max(0, e.clientX - rect.left)),
      y: Math.min(rect.height - sig.height, Math.max(0, e.clientY - rect.top)),
      page
    };
    return pos;
  };
  const applyMousePosition = e => {
    setImagePosition(getMousePosition(e));
  };
  const saveMousePosition = e => {
    const pos = getMousePosition(e);
    // console.log('saving position', pos);
    onPosition(pos);
    setDragging(false);
  };
  useEffect(() => {
    if (dragging) {
      window.addEventListener('mousemove', applyMousePosition);
      window.addEventListener('mouseup', saveMousePosition);
    } else {
      window.removeEventListener('mousemove', applyMousePosition);
      window.removeEventListener('mouseup', saveMousePosition);
    }
    return () => {
      window.removeEventListener('mousemove', applyMousePosition);
      window.removeEventListener('mouseup', saveMousePosition);
    };
  }, [dragging]);
  useEffect(() => {
    // console.log('ext position changed', position);
    if (position) {
      setImagePosition(position);
    }
  }, [position]);
  const handleDragStart = () => {
    // console.log('drag started');
    setDragging(true);
  };
  return (
    <div
      ref={sigRef}
      className={`bg-${color}-subtle border border-2 border-dotted border-${color} d-flex fs--2 position-absolute ps-3 opacity-75 pe-1 rounded-2 small user-select-none z-1 justify-content-between align-items-center`}
      onMouseDown={handleDragStart}
      style={{
        left: imagePosition.x,
        top: imagePosition.y,
        height:
          signWellPlaceholderDimensions.height *
          ((rect?.height || 1) / docViewport.height),
        width:
          signWellPlaceholderDimensions.width *
          ((rect?.width || 1) / docViewport.width),
        cursor: 'move'
      }}
    >
      <FontAwesomeIcon icon={icon} className="fs-1" />
      <CloseButton
        className="ms-2"
        onMouseDown={e => {
          e.stopPropagation();
          e.preventDefault();
          onRemove();
        }}
      />
    </div>
  );
};
OverlayItem.propTypes = {
  page: PropTypes.number,
  onPosition: PropTypes.func,
  onRemove: PropTypes.func,
  position: PropTypes.object,
  containerRef: PropTypes.object,
  docViewport: PropTypes.object
};
const signWellDimensions = {
  width: 816,
  height: 1056
};
const typeConfigs = [
  {
    type: 'signature',
    label: 'Signature',
    icon: faSignature,
    color: 'warning'
  },
  {
    type: 'checkbox',
    label: 'Checkbox',
    icon: faCheckSquare,
    color: 'success'
  },
  { type: 'text', label: 'Text', icon: faPen, color: 'primary' },
  { type: 'name', label: 'Employee name', icon: faUserTag, color: 'info' },
  {
    type: 'signDate',
    label: 'Date Signed',
    icon: faCalendarCheck,
    color: 'secondary'
  },
  {
    type: 'createdDate',
    label: 'Date Created',
    icon: faCalendarDay,
    color: 'info'
  }
];
type FieldItem = {
  x: number;
  y: number;
  page: number;
  type: string;
};
const PdfOverlayer = ({ pdf, name, onReset }) => {
  // const { files } = useFileLoader({ value: pdf });
  // const [pdfFile, setPdfFile] = useState<any>();
  const boxRef = useRef(null);
  // useEffect(() => {
  //   if (
  //     files?.[0]?.preview &&
  //     !pdfFile &&
  //     files[0].preview !== pdfFile?.preview
  //   ) {
  //     setPdfFile({
  //       ...files[0],
  //       url: files[0].preview
  //     });
  //   }
  // }, [files]);
  const { fields, append, remove, update, replace } = useFieldArray<{
    [key: string]: FieldItem[];
  }>({ name });
  const { resetField, getValues } = useFormContext();
  const handleItemPosition = i => async pos => {
    const posActual = await convertToActual(pos);
    update(i, posActual);
  };
  const [loading, setLoading] = useState(false);
  const [docViewport, setDocViewport] = useState<{
    width: number;
    height: number;
  }>(null);
  const convertToActual = pos => {
    const viewport = docViewport;
    if (isNaN(pos.x) || isNaN(pos.y) || !viewport)
      return { x: 0, y: 0, p: pos.p };
    const box = boxRef.current.getBoundingClientRect();
    return {
      ...pos,
      x: (pos.x / box.width) * viewport?.width,
      y: (pos.y / box.height) * viewport?.height,
      page: pos.page
    };
  };
  const setDocBoundaries = useCallback(async ({ pdfFile }) => {
    if (!pdfFile?.url) return;
    setLoading(true);
    const docMeta = await pdfjs.getDocument(pdfFile).promise;
    const page = await docMeta.getPage(1);
    const viewport = page.getViewport({ scale: 1 });
    if (viewport.height > viewport.width) {
      //is portrait
      setDocViewport(signWellDimensions);
    } else {
      //is landscape
      setDocViewport({
        height: signWellDimensions.width,
        width: signWellDimensions.height
      });
    }
    setLoading(false);
  }, []);
  // useEffect(() => {
  //   if (pdfFile) {
  //     setDocBoundaries();
  //   }
  // }, [pdfFile, setDocBoundaries]);
  useEffect(() => {
    const current = getValues(name);
    resetField(name, { defaultValue: current });
    replace(current);
  }, [docViewport]);
  const convertFromActual = pos => {
    const viewport = docViewport;
    if (isNaN(pos.x) || isNaN(pos.y) || !viewport || !boxRef.current)
      return { x: 0, y: 0, p: pos.page };
    const box = boxRef.current.getBoundingClientRect();
    return {
      ...pos,
      x: (pos.x / viewport.width) * box.width,
      y: (pos.y / viewport.height) * box.height,
      page: pos.page
    };
  };
  return (
    <FilePdfViewer boxRef={boxRef} fileId={pdf} onRendered={setDocBoundaries}>
      {({ page, setPage }) =>
        loading || !docViewport || !boxRef.current ? (
          <div className="w-100 h-100 position-absolute start-0 top-0 bg-100 d-flex opacity-75">
            <PageLoader height={'100%'} />
          </div>
        ) : (
          <>
            <div
              style={{ top: -10, left: 5 }}
              className="position-absolute fs--1"
            >
              <Dropdown className="d-inline">
                <Dropdown.Toggle
                  as={IconButton}
                  icon={faPlus}
                  size="sm"
                  variant="falcon-default"
                >
                  Add
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {typeConfigs.map((type, i) => (
                    <Dropdown.Item key={i}>
                      <IconButton
                        icon={type.icon}
                        size="sm"
                        variant={`falcon-default`}
                        className="w-100 text-start"
                        onClick={() =>
                          append({ x: 0, y: 35, page, type: type.type })
                        }
                      >
                        {type.label}
                      </IconButton>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {!!fields.length && (
                <Dropdown className="d-inline ms-2">
                  <Dropdown.Toggle size="sm" variant="falcon-primary">
                    {fields.length} fields
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fields.map((field, i) => (
                      <DropdownItem
                        key={field.id}
                        className="d-flex align-items-center"
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          setPage(getValues(name + '.' + i).page);
                        }}
                      >
                        {camelToSentence(getValues(name + '.' + i).type)} (p.
                        {getValues(name + '.' + i).page})
                      </DropdownItem>
                    ))}
                    <Dropdown.Item
                      onClick={() => replace([])}
                      className="text-danger"
                    >
                      <FontAwesomeIcon className="me-2" icon={faTrash} />
                      Remove all
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                // <IconButton
                //   icon={faTrash}
                //   size="sm"
                //   variant="danger"
                //   className="ms-2"
                //   onClick={() => replace([])}
                // >
                //   Remove {fields.length} fields
                // </IconButton>
              )}
              {onReset && (
                <IconButton
                  icon={faUndo}
                  size="sm"
                  variant="falcon-default"
                  className="ms-2"
                  onClick={() => {
                    onReset();
                    replace([]);
                  }}
                >
                  Change PDF
                </IconButton>
              )}
            </div>
            {fields.map((field, i) => {
              return (
                field.page === page && (
                  <OverlayItem
                    key={field.id}
                    color={typeConfigs.find(t => t.type === field.type)?.color}
                    icon={typeConfigs.find(t => t.type === field.type)?.icon}
                    page={page}
                    onPosition={pos =>
                      handleItemPosition(i)({ ...pos, type: field.type })
                    }
                    onRemove={() => remove(i)}
                    position={convertFromActual(field)}
                    containerRef={boxRef}
                    docViewport={docViewport}
                  />
                )
              );
            })}
          </>
        )
      }
    </FilePdfViewer>
  );
};
PdfOverlayer.propTypes = {
  pdf: PropTypes.array,
  name: PropTypes.string,
  onReset: PropTypes.func
};
export default PdfOverlayer;
