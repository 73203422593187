import { useMutation, useQueryClient } from '@tanstack/react-query';
import LoadingButton from 'components/common/LoadingButton';
import CustomFormViewer from 'components/common/customForms/Reporting/CustomFormViewer';
import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import UserIcon from 'components/app/users/widgets/UserIcon';
import { Applicant, applicantStagesApi } from 'apis/flex/recruitment';
import useApplicantStages from '../hooks/useApplicantStages';
import { useUser } from 'hooks/useUser';
const ReviewStage = ({
  applicant,
  applicantStageId
}: {
  applicant: Applicant;
  applicantStageId: number;
}) => {
  const applicantStage = applicant.stages?.find(s => s.id == applicantStageId);
  const { update, isUpdating } = useApplicantStages();
  const { id: userId } = useUser();
  const handleReview = (isPass: boolean) => {
    update({
      id: applicantStage.id,
      data: {
        reviewDate: new Date(),
        isPass,
        reviewedBy: userId
      }
    });
  };
  return (
    !!applicantStage &&
    !!applicantStage?.stageFormId && (
      <>
        <CustomFormViewer
          formId={applicantStage?.stageFormId}
          responseId={applicantStage.responseId}
        />
        <Card.Footer>
          <div className="d-flex w-100 justify-content-between">
            <LoadingButton
              disabled={applicantStage.reviewDate && !applicantStage.isPass}
              loading={isUpdating}
              onClick={() => handleReview(false)}
              variant="danger"
            >
              Fail
            </LoadingButton>
            {applicantStage.reviewDate && (
              <div className="justify-content-center align-items-center form-text d-flex">
                <UserIcon id={applicantStage.reviewedBy} className="me-1" />
                {applicantStage.isPass ? 'Passed' : 'Failed'} on{' '}
                {format(
                  new Date(applicantStage.reviewDate),
                  'dd/MM/yyyy HH:mm'
                )}
              </div>
            )}
            <LoadingButton
              disabled={applicantStage.reviewDate && applicantStage.isPass}
              loading={isUpdating}
              onClick={() => handleReview(true)}
              variant="success"
            >
              Pass
            </LoadingButton>
          </div>
        </Card.Footer>
      </>
    )
  );
};
ReviewStage.propTypes = {
  applicant: PropTypes.object,
  applicantStageId: PropTypes.string
};
export default ReviewStage;
