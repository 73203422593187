import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useAccessGroups from '../team/permissions/useAccessGroups';
import { useAppRoleOptions } from '../team/permissions/UserPermissions';

export default () => {
  const { data: appRoles } = useAppRoleOptions();
  return (
    <DomainTable
      domain="access-group"
      crudHook={useAccessGroups}
      editFields={['name', 'description', 'users', 'roles']}
      columns={[
        'name',
        'description',
        {
          id: 'users',
          domain: 'user',
          accessorFn: d => d?.users?.map(u => u.userId)
        },
        {
          id: 'roles',
          options: appRoles,
          editor: 'multi-select',
          accessorFn: d => d?.roles?.map(r => r.collectionId + '#' + r.levelId)
        }
      ]}
    />
  );
};
