import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import useResourceStatus from './hooks/useResourceStatus';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faClock,
  faFile,
  faTimes,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import DetailCard from 'components/common/detail/DetailCard';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { TrainingResource } from 'apis/flex/hr';
import FileIcon from 'components/files/FileIcon';
import useResourceValues from './hooks/useResourceValues';
export const StatusBadge = ({ children, icon, variant, description = '' }) => {
  return (
    <>
      <SoftBadge bg={variant} className="mb-3 fs-2">
        <Flex justifyContent={'center'}>
          <FontAwesomeIcon icon={icon} className="me-1" /> {children}
        </Flex>
        {description && (
          <div className="d-block fs-0 mt-3 fw-normal">{description}</div>
        )}
      </SoftBadge>
    </>
  );
};
const ItemRow = ({ icon, label, value }) => {
  return (
    <Row className={'mt-1 align-items-center fs--1'}>
      <Col className="text-center" xs={1}>
        <FontAwesomeIcon icon={icon} />
      </Col>
      <Col className="flex-grow-1">
        {label}: <b>{value || <i>N/A</i>}</b>
      </Col>
    </Row>
  );
};
export default ({
  resource,
  index,
  review,
  onBegin,
  onReview
}: {
  resource: TrainingResource;
  index: number;
  review: boolean;
  onBegin: () => void;
  onReview: () => void;
}) => {
  const { isComplete, isPending, isFailed } = useResourceStatus({
    index
  });
  const { getValue } = useResourceValues({ index });
  const attempts = getValue('quizAttempts') || [];
  const attemptsLeft = resource.allowedAttempts - attempts?.length;
  // console.log(attempts);
  return (
    <DetailCard
      animate={false}
      id="resource"
      colProps={{ xs: 12 }}
      title={resource.name}
      subtitle={
        <div dangerouslySetInnerHTML={{ __html: resource.description }} />
      }
      className={classNames({
        'badge-soft-success': isComplete,
        'badge-soft-danger': isFailed,
        'badge-soft-info': isPending
      })}
    >
      <Row className="align-items-center">
        <Col xs={12} className="text-center">
          {isComplete ? (
            <StatusBadge variant="success" icon={faCheck}>
              Completed
            </StatusBadge>
          ) : isPending ? (
            <StatusBadge variant="info" icon={faClock}>
              Approval pending
            </StatusBadge>
          ) : (
            isFailed && (
              <>
                <StatusBadge
                  variant="danger"
                  description={getValue('failedReason')}
                  icon={faTimes}
                >
                  Failed
                </StatusBadge>
              </>
            )
          )}
        </Col>
        <Col className="d-flex justify-content-center">
          <Row className="align-items-center justify-content-center w-100">
            <Col
              lg={6}
              className="d-none d-lg-flex p-3 px-4 align-items-center justify-content-center"
            >
              <FileIcon
                height={60}
                url={resource.url}
                file={
                  resource.fileId ||
                  resource.videoId ||
                  (resource.videoUrl && { type: 'video' })
                }
              />
            </Col>
            <Col lg={6} xs={9} className="d-flex flex-column align-items-start">
              <ItemRow icon={faFile} label="Type" value={resource.type} />
              <ItemRow
                icon={faCheckDouble}
                label="Requires approval"
                value={resource.autoApprove ? 'No' : 'Yes'}
              />
              <ItemRow
                icon={faCheckSquare}
                label="Confirmation"
                value={resource.confirmationTypes.join(', ')}
              />
              <ItemRow
                icon={faUserCircle}
                label="Attempts remaining"
                value={
                  <span
                    className={classNames({
                      'text-danger': attemptsLeft === 1
                    })}
                  >
                    {attemptsLeft}
                  </span>
                }
              />
            </Col>
          </Row>
        </Col>
        <Flex className="pt-5 pb-3 w-100" justifyContent={'center'}>
          {(!isFailed && (isComplete || isPending)) || review ? (
            <Button variant="link" onClick={onReview}>
              Review
            </Button>
          ) : (
            !isFailed && (
              <Button onClick={onBegin}>
                {attempts?.length ? 'Retry' : 'Begin'}
              </Button>
            )
          )}
        </Flex>
      </Row>
    </DetailCard>
  );
};
