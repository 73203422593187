import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import {
//   ApplicantOutcomes,
//   ApplicantsCard,
//   CampaignClicks,
//   TotalApplicants,
//   WeeklyApplicants
// } from './Applicants';
import { useParams } from 'react-router-dom';
import ShareCampaign from '../campaigns/InvitePeople';
import DomainReportTiles from 'components/app/reporting/DomainReportTiles';
import useCampaign from '../campaigns/hooks/useCampaign';
import ApplicantsTable from './ApplicantsTable';

export default () => {
  const { campaignId } = useParams();
  return (
    <Row className="g-3">
      <Col xs={12}>
        <ShareCampaign campaignId={campaignId} />
      </Col>
      {/* 
      <Col md={6} xl={3}>
        <CampaignClicks campaignId={Number(id)} className="h-100" />
      </Col>
      <Col md={6} xl={3}>
        <TotalApplicants campaignId={id} className="h-100" />
      </Col>
      <Col md={6} xl={3}>
        <WeeklyApplicants campaignId={id} className="h-100" />
      </Col>
      <Col md={6} xl={3}>
        <ApplicantOutcomes campaignId={id} className="h-100" />
      </Col> */}
      <Col>
        <CampaignReportTiles campaignId={Number(campaignId)} />
      </Col>
      <Col xs={12}>
        <ApplicantsTable campaignId={Number(campaignId)} />
      </Col>
    </Row>
  );
};
const CampaignReportTiles = ({ campaignId }) => {
  const { data: campaign } = useCampaign({
    id: campaignId,
    staleTime: Infinity
  });
  return (
    <DomainReportTiles
      domain="recruitment-campaign"
      location="view-campaign"
      itemId={campaignId}
      simple
      colProps={{ md: 6, xl: 3 }}
      className="g-3"
      data={campaign}
    />
  );
};
