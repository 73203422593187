import useApplicants from "./useApplicants";
export default ({
  campaignId,
  filters,
  ...props
}) => {
  return useApplicants({
    ...props,
    filters: { ...filters, campaignId: Number(campaignId) }
  });
};
