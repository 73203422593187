import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import useCampaign from './hooks/useCampaign';
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import BatchInviteModal from './BatchInviteModal';
import useCampaignLink from './hooks/useCampaignLink';
const ShareCampaign = ({ campaignId }) => {
  const [showBatch, setShowBatch] = useState(false);
  const { data: campaign = {} } = useCampaign({ id: campaignId });
  const { copy } = useCampaignLink({ campaignId });
  const handleCopy = () => {
    copy();
  };

  return (
    <>
      <Card>
        <Card.Body className="text-center py-2">
          {/* <Row className="justify-content-center">
            <Col xs={7} md={5}>
              <img className="img-fluid" src={invite} alt="image" width="58%" />
            </Col>
          </Row> */}
          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            Share your campaign!
          </h3>
          <Col lg={6} className="mx-auto">
            <Row className="gx-2 my-4">
              <Col lg={6}>
                <IconButton
                  breakpoint="xs"
                  className="d-block w-100 mb-2 mb-xl-0"
                  iconClassName="me-2"
                  variant="falcon-default"
                  icon="link"
                  data-tour="copy-link"
                  onClick={handleCopy}
                >
                  Copy Link
                </IconButton>
              </Col>
              {/* <Col lg={4}>
                <IconButton
                  className="d-block w-100 mb-2 mb-xl-0"
                  variant="falcon-default"
                  icon={faEnvelope}
                  onClick={() => setShowBatch(true)}
                  iconClassName="me-2"
                >
                  Batch email
                </IconButton>
              </Col> */}
              <Col lg={6}>
                <IconButton
                  breakpoint="xs"
                  className="d-block w-100 mb-xl-0"
                  variant="falcon-default"
                  icon={['fab', 'facebook-square']}
                  iconClassName="text-facebook"
                  transform="grow-2"
                >
                  Share on Facebook
                </IconButton>
              </Col>
            </Row>
          </Col>
        </Card.Body>
      </Card>
      <BatchInviteModal
        show={showBatch}
        setShow={setShowBatch}
        campaign={campaign}
      />
    </>
  );
};

ShareCampaign.propTypes = {
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired
};

export default ShareCampaign;
