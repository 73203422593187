import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessGroup } from 'apis/flex/auth';
import { SystemTag } from 'apis/flex/helpers';
import { Department, TrainingCourse, UserTrainingCourse } from 'apis/flex/hr';
import { EventPrefix } from 'apis/flex/notifications';
import {
  Client,
  Project,
  ProjectRole,
  Quote,
  TargetGroup,
  TeamRole
} from 'apis/flex/projects';
import { ApiField } from 'apis/types';
import useContracts from 'components/app/documents/contracts/hooks/useContracts';
import useDepartments from 'components/app/hr/departments/useDepartments';
import usePrescreening from 'components/app/hr/prescreening/hooks/usePrescreening';
import useShiftPreferences from 'components/app/hr/shifts/shiftPreferences/useShiftPreferences';
import useShifts from 'components/app/hr/shifts/useShifts';
import useEmployees from 'components/app/hr/staff/hooks/useEmployees';
import useTrainingCourses from 'components/app/hr/training/hooks/useTrainingCourses';
import useClients from 'components/app/pm/clients/useClients';
import useQuestionnaires from 'components/app/pm/projects/questionnaires/useQuestionnaires';
import useResourceGroups from 'components/app/pm/projects/resourceGroups/useResourceGroups';
import useProjectRoles from 'components/app/pm/projects/useProjectRoles';
import useProjects from 'components/app/pm/projects/useProjects';
import useTargetGroups from 'components/app/pm/projects/useTargetGroups';
import useQuotes from 'components/app/pm/quotes/useQuotes';
import useTeamRoles from 'components/app/pm/roles/useTeamRoles';
import useApplicants from 'components/app/recruitment/applicants/hooks/useApplicants';
import useApplicantStages from 'components/app/recruitment/applicants/hooks/useApplicantStages';
import useCampaigns from 'components/app/recruitment/campaigns/hooks/useCampaigns';
import useAccessGroups from 'components/app/settings/team/permissions/useAccessGroups';
import useTickets from 'components/app/support/useTickets';
import useUsers from 'components/app/users/hooks/useUsers';
import useForms from 'components/common/customForms/hooks.js/useForms';
import DomainIcon from 'components/common/DomainIcon';
import { SelectorItem } from 'components/common/ItemSelector';
import SoftBadge from 'components/common/SoftBadge';
import useMailerTemplates from 'components/mailer/templates/useMailerTemplates';
import { getRenderIconFromValue } from 'components/wizard/inputs/WizardIcon';
import { format } from 'date-fns';
import { getTime, weekdays } from 'helpers/dates';
import { DefaultCrudData, defaultCrudHookBuilder } from 'hooks/useDefaultCrud';
import useTags from 'hooks/useTags';
import { startCase } from 'lodash';
import React from 'react';
import {
  FiBookOpen,
  FiBriefcase,
  FiCheckSquare,
  FiClipboard,
  FiClock,
  FiFileText,
  FiGrid,
  FiHome,
  FiInbox,
  FiKey,
  FiLifeBuoy,
  FiList,
  FiMail,
  FiTag,
  FiTarget,
  FiUser,
  FiUserCheck,
  FiUserPlus,
  FiUsers
} from 'react-icons/fi';

export const version = process.env.REACT_APP_FLEXVERSION;
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export type DomainConfig<TData extends DefaultCrudData = any> = {
  sqlDb: string;
  sqlTable: string;
  foreignKey: string;
  icon?: React.ReactNode;
  home?: string | ((d: TData) => string);
  itemUrl?: string | ((d: TData) => string);
  format?: (d: TData) => SelectorItem;
  color?: string;
  crudHook?: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  searchFields: ApiField<TData>[];
};
type Settings = {
  isFluid: boolean;
  isRTL: boolean;
  isDark: boolean;
  isDemo: boolean;
  navbarPosition: string;
  showBurgerMenu: boolean;
  currency: string;
  isNavbarVerticalCollapsed: boolean;
  navbarStyle: string;
  config: any;
  domains: Partial<{
    [key in EventPrefix]: DomainConfig;
  }>;
};
export const settings: Settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isDemo: true,
  navbarPosition: 'top',
  showBurgerMenu: true, // controls showing vertical nav on mobile
  currency: '£',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  config: {},
  domains: {
    ticket: {
      sqlDb: 'Support',
      sqlTable: 'Tickets',
      foreignKey: 'ticketId',
      icon: <FiLifeBuoy />,
      home: '/support/tickets',
      itemUrl: t =>
        `/support/tickets/${t.status === 'Done' ? 'closed' : 'open'}/${t.id}`,
      format: t => ({ label: 'Ticket #' + t.id, value: t.id }),
      searchFields: ['title', 'description'],
      crudHook: useTickets
    },
    applicant: {
      sqlDb: 'Recruitment',
      foreignKey: 'applicantId',
      sqlTable: 'AgentApplicants',
      icon: <FiUserPlus />,
      home: a => '/hr/recruitment/campaigns/' + a.campaignId,
      itemUrl: a =>
        '/hr/recruitment/campaigns/' + a.campaignId + '/applicants/' + a.id,
      format: d => ({ label: d.firstName + ' ' + d.surname, value: d.id }),
      crudHook: useApplicants,
      searchFields: ['firstName', 'surname', 'email', 'telephone']
    },
    'new-starter': {
      sqlDb: 'HR',
      foreignKey: 'employeeId',
      sqlTable: 'Employees',
      icon: <FiUserPlus />,
      home: '/hr/staff',
      format: d => ({
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        avatar: d.avatar
      }),
      crudHook: useEmployees,
      searchFields: ['firstName', 'surname', 'email', 'telephone']
    },
    'recruitment-campaign': {
      sqlDb: 'Recruitment',
      foreignKey: 'campaignId',
      sqlTable: 'Campaigns',
      icon: <FiUserPlus />,
      home: a => '/hr/recruitment/campaigns/' + a.campaignId,
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdBy: d.createdBy }
      }),
      crudHook: useCampaigns,
      searchFields: ['name']
    },
    form: {
      sqlDb: 'Forms',
      foreignKey: 'formId',
      sqlTable: 'CustomForms',
      icon: <FiCheckSquare />,
      home: '/forms',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdBy: d.createdBy, createdDate: d.createdDate }
      }),
      crudHook: useForms,
      searchFields: ['name']
    },
    // project: { icon: <FiList /> },
    user: {
      sqlDb: 'Users',
      foreignKey: 'userId',
      sqlTable: 'AppUsers',
      icon: <FiUser />,
      home: '/hr/staff',
      format: d => ({
        avatar: d.avatar,
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        info: { jobTitle: d.jobTitle, email: d.email }
      }),
      crudHook: useUsers,
      searchFields: ['firstName', 'surname', 'email']
    },
    employee: {
      sqlDb: 'HR',
      foreignKey: 'employeeId',
      sqlTable: 'Employees',
      icon: <FiUser />,
      home: '/hr/staff',
      format: d => ({
        label: d.firstName + ' ' + d.surname,
        value: d.id,
        info: { jobTitle: d.jobTitle, email: d.email },
        avatar: d.avatar
      }),
      crudHook: useEmployees,
      searchFields: ['firstName', 'surname', 'email']
    },
    contract: {
      foreignKey: 'contractId',
      sqlDb: 'HR',
      sqlTable: 'Contracts',
      icon: <FiFileText />,
      home: '/hr/contracts',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { createdDate: d.createdDate, createdBy: d.createdBy }
      }),
      crudHook: useContracts,
      searchFields: ['name']
    },
    'employee-contract': {
      foreignKey: 'employeeContractId',
      sqlDb: 'HR',
      sqlTable: 'EmployeeContracts',
      icon: <FiFileText />,
      home: d => `/hr/employee/${d.employeeId}`,
      format: d => ({
        label: d.firstName + ' ' + d.surname + ' (' + d.name + ')',
        value: d.id,
        info: { startDate: d.startDate, terminatedDate: d.terminatedDate }
      }),
      searchFields: ['firstName', 'surname', 'name']
    },
    department: {
      sqlDb: 'HR',
      crudHook: useDepartments,
      foreignKey: 'departmentId',
      sqlTable: 'Departments',
      icon: <FiHome />,
      home: '/hr/company/departments',
      format: (d: Department) => ({
        avatar: d.avatar || d.background,
        label: d.name,
        value: d.id,
        info: {
          department: d.parentName,
          employees: d.employees + ' employees',
          subDepartments: d.departments + ' sub-departments'
        }
      }),
      searchFields: ['name']
    },
    'training-course': {
      sqlDb: 'HR',
      foreignKey: 'courseId',
      sqlTable: 'TrainingCourses',
      icon: <FiBookOpen />,
      home: '/hr/training/courses',
      format: (d: TrainingCourse) => ({
        label: d.name,
        value: d.id,
        avatar: d.profileBackground,
        info: {
          modules: (d.resources?.length || 0) + ' resources'
        }
      }),
      crudHook: useTrainingCourses,
      searchFields: ['name', 'description']
    },
    'user-training': {
      sqlDb: 'HR',
      sqlTable: 'UserTrainingCourses',
      foreignKey: 'userTrainingId',
      icon: <FiBookOpen />,
      home: '/hr/training/courses/sessions',
      format: (d: UserTrainingCourse) => ({
        label: 'Session #' + d.id,
        value: d.id,
        info: {
          enrolledDate: format(new Date(d.startedDate), 'dd/MM/yyyy'),
          user: d.userId
        }
      }),
      searchFields: ['courseName']
    },
    'applicant-stage': {
      sqlDb: 'Recruitment',
      foreignKey: 'applicantStageId',
      sqlTable: 'ApplicantStage',
      icon: <FiList />,
      home: '/hr/recruitment',
      crudHook: useApplicantStages,
      searchFields: ['applicantFirstName', 'applicantSurname', 'stageName']
    },
    'employee-screening': {
      sqlDb: 'HR',
      foreignKey: 'screeningId',
      sqlTable: 'Screenings',
      icon: <FiUserCheck />,
      home: '/hr/staff/screening',
      searchFields: ['checks.checkType'],
      crudHook: usePrescreening
    },
    'mailer-template': {
      sqlDb: 'Notifications',
      foreignKey: 'notificationId',
      sqlTable: 'MailerTemplates',
      icon: <FiMail />,
      home: '/settings/notifications/templates',
      format: d => ({ label: d.name, value: d.id }),
      crudHook: useMailerTemplates,
      searchFields: ['name']
    },
    client: {
      sqlDb: 'Projects',
      sqlTable: 'Clients',
      foreignKey: 'clientId',
      icon: <FiBriefcase />,
      home: '/fieldwork/clients',
      format: (d: Client) => ({ label: d.name, value: d.id }),
      crudHook: useClients,
      searchFields: ['name', 'website', 'email']
    },
    project: {
      sqlDb: 'Projects',
      sqlTable: 'Projects',
      foreignKey: 'projectId',
      icon: <FiClipboard />,
      home: '/fieldwork/pm',
      format: (d: Project) => ({
        label: d.quoteRef + ' ' + d.name,
        value: d.id
      }),
      crudHook: useProjects,
      searchFields: ['name', 'clientName', 'quoteRef']
    },
    quote: {
      sqlDb: 'Projects',
      sqlTable: 'Quotes',
      foreignKey: 'quoteId',
      icon: <FiInbox />,
      home: '/fieldwork/quotes',
      format: (d: Quote) => ({
        label: d.reference + ' ' + d.name,
        value: d.id
      }),
      crudHook: useQuotes,
      searchFields: ['reference', 'name']
    },
    'target-group': {
      sqlDb: 'Projects',
      sqlTable: 'TargetGroups',
      foreignKey: 'targetGroupId',
      icon: <FiTarget />,
      home: d => '/fieldwork/pm/' + d.projectId + '/target-groups',
      format: (d: TargetGroup) => ({
        label: d.quoteRef + ' ' + d.projectName + ' - ' + d.name,
        value: d.id
      }),
      crudHook: useTargetGroups,
      searchFields: ['quoteRef', 'projectName', 'name']
    },
    'team-role': {
      sqlDb: 'Projects',
      sqlTable: 'TeamRoles',
      foreignKey: 'roleId',
      icon: <FiUsers />,
      home: '/fieldwork/pm/roles',
      format: (d: TeamRole) => ({ label: d.name, value: d.id }),
      crudHook: useTeamRoles,
      searchFields: ['name']
    },
    'project-role': {
      sqlDb: 'Projects',
      sqlTable: 'ProjectRoles',
      foreignKey: 'projectRoleId',
      icon: <FiUsers />,
      home: d => '/fieldwork/pm/' + d.projectId + '/roles',
      format: (d: ProjectRole) => ({ label: d.roleName, value: d.id }),
      crudHook: useProjectRoles,
      searchFields: ['roleName']
    },
    shift: {
      sqlDb: 'HR',
      sqlTable: 'Shifts',
      foreignKey: 'shiftId',
      icon: <FiClock />,
      home: '/hr/ta/shifts/',
      format: d => ({
        label: getTime(d.startTime) + '-' + getTime(d.endTime),
        value: d.id
      }),
      crudHook: useShifts,
      searchFields: ['startTime', 'endTime']
    },
    'employee-shift': {
      sqlDb: 'HR',
      sqlTable: 'EmployeeShifts',
      foreignKey: 'employeeShiftId',
      icon: <FiClock />,
      home: '/hr/ta/employee-shifts',
      format: d => ({
        label: getTime(d.startTime) + '-' + getTime(d.endTime),
        value: d.id,
        group:
          format(new Date(d.date), 'dd/MM/yyyy') +
          ' | ' +
          d.employeeFirstName +
          ' ' +
          d.employeeSurname
      }),
      searchFields: [
        'startTime',
        'endTime',
        'employeeFirstName',
        'employeeSurname'
      ]
      // crudHook:useEmployeeShifts
    },
    'shift-preference': {
      sqlDb: 'HR',
      sqlTable: 'ShiftPreferences',
      foreignKey: 'shiftPreferenceId',
      icon: <FiClock />,
      itemUrl: d => '/hr/staff/' + d.employeeId,
      format: d => ({
        label: getTime(d.startTime) + '-' + getTime(d.endTime),
        value: d.id,
        group:
          d.employeeFirstName +
          ' ' +
          d.employeeSurname +
          ' | ' +
          weekdays[d.weekday]
      }),
      crudHook: useShiftPreferences,
      searchFields: [
        'startTime',
        'endTime',
        'employeeFirstName',
        'employeeSurname'
      ]
    },
    'resource-group': {
      sqlDb: 'Projects',
      sqlTable: 'ResourceGroups',
      foreignKey: 'resourceGroupId',
      icon: <FiGrid />,
      home: '/fieldwork/pm/resource-groups/',
      format: d => ({
        label: d.name,
        value: d.id,
        info: { description: d.description },
        icon: (
          <FontAwesomeIcon icon={getRenderIconFromValue(d.icon, faUserTie)} />
        )
      }),
      crudHook: useResourceGroups,
      searchFields: ['name', 'description']
    },
    'access-group': {
      crudHook: useAccessGroups,
      sqlDb: 'Users',
      sqlTable: 'AccessGroups',
      foreignKey: 'groupId',
      icon: <FiKey />,
      home: '/settings/access-groups/',
      format: (d: AccessGroup) => ({
        label: d.name,
        value: d.id,
        tags: (
          <>
            {d.roles?.map(r => (
              <SoftBadge key={r.id} bg="info">
                <DomainIcon
                  size="xs"
                  className="me-1"
                  domain={r.collectionName}
                />
                {startCase(r.collectionName) + ' ' + r.levelName}
              </SoftBadge>
            ))}
          </>
        )
      }),
      searchFields: ['name', 'roles.collectionName', 'roles.levelName']
    },
    questionnaire: {
      sqlDb: 'Projects',
      sqlTable: 'Questionnaires',
      foreignKey: 'questionnaireId',
      icon: <FiFileText />,
      home: d =>
        d.quoteId
          ? '/fieldwork/quotes/' + d.quoteId
          : '/fieldwork/pm/' + d.projectId + '/questionnaires',
      format: d => ({
        label: d.name,
        value: d.id,
        tags: (
          <>
            <SoftBadge>
              {Math.round(d.actualIncidence || d.expectedIncidence)}% IR
            </SoftBadge>
            <SoftBadge>{d.expectedDuration || d.actualDuration}mins</SoftBadge>
          </>
        )
      }),
      searchFields: ['name'],
      crudHook: useQuestionnaires
    },
    tag: {
      sqlDb: 'System',
      sqlTable: 'Tags',
      foreignKey: 'tagId',
      icon: <FiTag />,
      crudHook: useTags,
      format: (d: SystemTag) => ({ label: d.name, value: d.id }),
      searchFields: ['name']
    }
  }
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
