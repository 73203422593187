import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import useRpu from './useRpu';
import { TargetGroup } from 'apis/flex/projects';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';

const FixedHeightTD = styled.td`
  height: 47px;
  vertical-align: middle;
`;

export default () => {
  const targetGroup = useWatch() as TargetGroup;
  const { data: rpu } = useRpu({
    targetGroupDefinition: {
      ...targetGroup,
      id: targetGroup.id,
      target: targetGroup.target
    }
  });
  return (
    <table
      className="table table-sm w-lg-50 fs--1"
      style={{ tableLayout: 'fixed' }}
    >
      <tbody>
        <tr>
          <FixedHeightTD>Revenue per unit (calculated)</FixedHeightTD>
          <FixedHeightTD>£{rpu ? rpu.toFixed(2) : '-'}</FixedHeightTD>
        </tr>
        <tr>
          <FixedHeightTD>Adjustment</FixedHeightTD>
          <FixedHeightTD>
            <WizardInput
              name="rpuAdjustment"
              type="currency"
              hideLabel
              formGroupProps={{ className: 'mb-auto' }}
              formControlProps={{ step: 0.01, min: -999999 }}
              registerProps={{ required: false }}
            />
          </FixedHeightTD>
        </tr>
        <tr>
          <FixedHeightTD>Revenue per unit (final)</FixedHeightTD>
          <FixedHeightTD>
            <AdjustedRpu rpu={rpu} />
          </FixedHeightTD>
        </tr>
      </tbody>
    </table>
  );
};
const AdjustedRpu = ({ rpu }: { rpu: number }) => {
  const adjustment = useWatch({ name: 'rpuAdjustment' });
  return <>£{rpu ? (rpu + (adjustment || 0)).toFixed(2) : '-'}</>;
};
