import { CrudContext } from 'context/Context';
import React, { useContext } from 'react';
import { DefaultCrudData, UseCrudProps } from './useDefaultCrud';

export default <T extends DefaultCrudData, TS>() => {
  return useContext<UseCrudProps<T, TS>>(CrudContext);
};
export const CrudConfig = ({ children, value }) => {
  return <CrudContext.Provider value={value}>{children}</CrudContext.Provider>;
};
