import { customFormsApi } from "apis/flex/customForms";
import { defaultCrudHookBuilder } from "hooks/useDefaultCrud";
import PropTypes from "prop-types";
export const useFormsWithQuestions = ({
  fields = ["email"],
  functions,
  filters,
  select = (v) => v,
  enabled,
  ...rest
}) => {
  return useForms({
    filters,
    useFilter: true,
    select: (d) => {
      const selected = select(d).filter((f) => {
        const questionFilters = functions || fields?.map((f2) => (q) => q.inputType === f2 && q.isRequired) || [];
        const result = questionFilters.every(
          (fn) => f.sections?.find((s) => s.questions?.find((q) => fn(q)))
        );
        return result;
      });
      return selected;
    },
    enabled,
    ...rest
  });
};
useFormsWithQuestions.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  functions: PropTypes.arrayOf(PropTypes.func),
  filters: PropTypes.object,
  select: PropTypes.func
};
const useForms = defaultCrudHookBuilder("CustomForms", customFormsApi, {
  staleTime: 1e3 * 30
});
export default useForms;
