import DomainDetail from 'components/common/DomainDetail';
import React from 'react';
import useAccessGroups from '../team/permissions/useAccessGroups';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { PermissionsGrid } from '../team/permissions/UserPermissions';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';

export default () => {
  return (
    <DomainDetail
      loadMutator={d => ({
        ...d,
        roleIds: d.roles?.map(r => r.collectionId + '#' + r.levelId) || [],
        userIds: d.users?.map(u => u.userId) || []
      })}
      saveMutator={d => ({
        ...d,
        roles: d.roleIds?.map(id => ({
          collectionId: id.split('#')[0],
          levelId: id.split('#')[1]
        })),
        users: d.userIds?.map(u => ({ userId: u, groupId: d.id }))
      })}
      domain="access-group"
      crudHook={useAccessGroups}
    >
      <DetailCard title="Info" id="info">
        <WizardInput name="name" />
        <WizardInput name="description" type="textarea" />
      </DetailCard>
      <DetailCard id="roles" title="Permissions">
        <PermissionsGrid namePrefix={'roleIds.'} />
      </DetailCard>
      <DetailCard id="users" title="Users">
        <UserSelector name="userIds" multiple />
      </DetailCard>
    </DomainDetail>
  );
};
