import { useTour } from "@reactour/tour";
import { useEffect } from "react";
const useNewTour = (id, steps, enabled) => {
  const tour = useTour();
  const getId = () => id;
  useEffect(() => {
    if (!tour?.isOpen && enabled) {
      tour.setMeta(getId());
      console.log("opening tour", tour);
      tour.setSteps(steps);
      tour.setIsOpen(true);
      tour.setCurrentStep(0);
    }
  }, [enabled]);
  return tour;
};
export default useNewTour;
