import React from 'react';
import { Button } from 'react-bootstrap';
import LoadingButton from './LoadingButton';

export default ({
  submitText = 'Submit',
  cancelText = 'Cancel',
  isLoading,
  onSubmit,
  onCancel
}: {
  submitText?: string;
  cancelText?: string;
  isLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  return (
    <div className="d-flex justify-content-end">
      {onCancel && (
        <Button variant="link" onClick={onCancel}>
          {cancelText}
        </Button>
      )}
      <LoadingButton type="submit" loading={isLoading} onClick={onSubmit}>
        {submitText}
      </LoadingButton>
    </div>
  );
};
