import { useWatch } from 'react-hook-form';

export default <Tfields extends string[]>({
  children,
  fields
}: {
  children: (values: Record<Tfields[number], any>) => any;
  fields?: Tfields;
}) => {
  //   const truthyFields = Object.keys(names).filter(k => names[k]);
  const values = useWatch({ name: fields });
  return children(values.reduce((a, b, i) => ({ ...a, [fields[i]]: b }), {}));
};
export const WithAllFormValues = <TData extends Record<any, any>>({
  children
}: {
  children: (values: TData) => any;
}) => {
  const values = useWatch<TData>() as TData;
  return children(values);
};
