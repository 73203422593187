import { getSqlCols } from "apis/flex/adminTable";
import { useQuery } from "@tanstack/react-query";
export default ({
  db,
  tables,
  ...rest
}) => useQuery({
  queryKey: ["ColumnInfo", db, tables],
  queryFn: () => getSqlCols(tables, db).then((d) => d.data),
  staleTime: Infinity,
  ...rest
});
