import React from 'react';
import classNames from 'classnames';
import { getColor } from 'helpers/utils';

export default ({
  className,
  label,
  max = 100,
  min = 0,
  now,
  color = 'primary',
  ...rest
}: {
  className?: string;
  label?: string;
  max?: number;
  min?: number;
  now: number;
  color?: string;
}) => {
  const percent = Math.round(((now - min) / (max - min)) * 100);
  return (
    <div
      {...rest}
      className={classNames(
        'd-inline-flex align-items-center gap-2',
        className
      )}
    >
      <div
        style={{
          ['--falcon-circle-progress-bar' as any]: percent
        }}
      >
        <svg
          className="circle-progress-svg"
          width="26"
          height="26"
          viewBox="0 0 120 120"
        >
          <circle
            className="progress-bar-rail"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            strokeWidth="12"
          ></circle>
          <circle
            className="progress-bar-top"
            cx="60"
            cy="60"
            r="54"
            fill="none"
            strokeLinecap="round"
            stroke={getColor(color)}
            strokeWidth="12"
          ></circle>
        </svg>
      </div>
      <h6 className="mb-0 text-700">{label || percent + '%'}</h6>
    </div>
  );
};
