import DetailPage from 'components/common/detail/DetailPage';
import React from 'react';
import useClients from './useClients';
import { useParams } from 'react-router-dom';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { useFieldArray } from 'react-hook-form';
import { Client } from 'apis/flex/projects';
import FieldArrayTable from 'components/common/FieldArrayTable';
const Contacts = () => {
  const fieldArray = useFieldArray<Client>({
    name: 'contacts'
  });
  return (
    <FieldArrayTable
      fieldArray={fieldArray}
      defaultValues={{ firstName: '', surname: '', email: '' }}
      name="contacts"
      columns={['firstName', 'surname', 'email']}
      form={(f, i) => (
        <div>
          <WizardInput name={`contacts.${i}.firstName`} label="First Name" />
          <WizardInput name={`contacts.${i}.surname`} label="Surname" />
          <WizardInput
            name={`contacts.${i}.jobTitle`}
            registerProps={{ required: false }}
            label="Job Title"
          />
          <WizardInput
            name={`contacts.${i}.email`}
            registerProps={{ required: false }}
            label="Email"
            type="email"
          />
          <WizardInput
            name={`contacts.${i}.secondaryEmail`}
            registerProps={{ required: false }}
            label="Secondary Email"
            type="email"
          />
          <WizardInput
            name={`contacts.${i}.telephone`}
            label="Telephone"
            registerProps={{ required: false }}
            type="telephone"
          />
          <WizardInput
            name={`contacts.${i}.secondaryTelephone`}
            label="Secondary Telephone"
            registerProps={{ required: false }}
            type="telephone"
          />
        </div>
      )}
    />
  );
};
export default () => {
  const { id } = useParams();
  const { data, isLoading, upsert, isUpdating } = useClients({
    id,
    select: d => d[0]
  });
  return (
    <DetailPage
      title={data?.name || 'New Client'}
      data={data}
      isLoading={isLoading}
      onSave={upsert}
      isSaving={isUpdating}
      domain="client"
      itemId={Number(id)}
    >
      <DetailCard id="info" title="Info">
        <WizardInput name="name" />
        <WizardInput name="website" registerProps={{ required: false }} />
        <WizardInput
          name="email"
          instruction="General enquiries email address"
          type="email"
          registerProps={{ required: false }}
        />
        <WizardInput
          name="telephone"
          instruction="General enquiries phone number"
          type="telephone"
          registerProps={{ required: false }}
        />
        <WizardInput
          name="accountsEmail"
          instruction="Generic accounts email address, if there is no specific accounts contact at the company"
          type="email"
          registerProps={{ required: false }}
        />
      </DetailCard>
      <DetailCard id="contacts" title="Contacts">
        <Contacts />
      </DetailCard>
    </DetailPage>
  );
};
