import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DropdownContextWrapper = ({ menuItems, children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const wrapperRef = useRef();
  const handleContextMenu = e => {
    e.preventDefault();
    setShowMenu(true);
    const wrapperPos = wrapperRef.current.getBoundingClientRect();
    setPosition({
      x: e.clientX - wrapperPos.x,
      y: e.clientY - wrapperPos.y
    });
  };
  const handleToggle = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };
  if (!menuItems) return children;
  return (
    <div
      ref={wrapperRef}
      onContextMenu={handleContextMenu}
      className="position-relative"
    >
      {children}
      {showMenu && (
        <div
          style={{ position: 'absolute', top: position.y, left: position.x }}
        >
          <Dropdown show={showMenu} autoClose onToggle={handleToggle}>
            <Dropdown.Menu
              popperConfig={{ modifiers: { flip: true } }}
              className="border py-0"
              style={{ zIndex: 1015 }}
            >
              {menuItems}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};
DropdownContextWrapper.propTypes = {
  menuItems: PropTypes.node,
  children: PropTypes.node
};
export default DropdownContextWrapper;
