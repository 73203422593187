import axios from "axios";
import { getItemFromStore } from "helpers/utils";
import { sessionId } from "index";
const flexAPI = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + "/api"
});
flexAPI.interceptors.request.use(
  (req) => {
    req.headers.Authorization = "Bearer " + getItemFromStore("client-access-token", null);
    req.headers["x-react-session-id"] = sessionId;
    req.headers["X-API-VERSION"] = 1;
    req.withCredentials = true;
    return req;
  },
  (err) => {
    return Promise.reject(err.response);
  }
);
flexAPI.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    console.log("caught error in api. Rejecting promise", err);
    return Promise.reject(err);
  }
);
export const fetchWithCredentials = (url) => fetch(url, {
  credentials: "include",
  headers: {
    Authorization: "Bearer " + getItemFromStore("client-access-token", null),
    "x-react-session-id": sessionId
  }
});
export default flexAPI;
