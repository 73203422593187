import { useMutation } from "@tanstack/react-query";
export default () => {
  const { mutate } = useMutation({
    mutationFn: async ({ cb, ms }) => {
      await new Promise((res) => setTimeout(res, ms));
      cb?.();
    }
  });
  return {
    set: (cb, ms) => {
      mutate({ cb, ms });
    }
  };
};
