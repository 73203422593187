import { RoleDomain } from 'apis/flex/users';
import React from 'react';
import CustomReportTiles, { CustomReportTilesProps } from './CustomReportTiles';
import { domainConfigs } from 'components/notification/config';
import { DomainConfig } from 'config';

export default ({
  domain,
  itemId,
  data,
  ...rest
}: {
  domain: RoleDomain;
  itemId?: number;
  data?: any;
} & CustomReportTilesProps) => {
  const domainConfig: DomainConfig = domainConfigs[domain];
  return (
    <CustomReportTiles
      // reportFilters={{ db: domainConfig.sqlDb }}
      variables={data}
      defaultValues={
        {
          // db: domainConfig.sqlDb
        }
      }
      filters={
        !!itemId && [
          [
            {
              question: domainConfig.foreignKey,
              type: '==',
              value: itemId.toString()
            }
          ]
        ]
      }
      {...rest}
    />
  );
};
