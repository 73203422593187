import DetailPage from 'components/common/detail/DetailPage';
import React from 'react';
import useResourceGroups from './useResourceGroups';
import DomainDetail from 'components/common/DomainDetail';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { Col, Row } from 'react-bootstrap';

export default () => {
  return (
    <DomainDetail domain="resource-group" crudHook={useResourceGroups}>
      <DetailCard title="Info" id="info">
        <Row>
          <Col md={2}>
            <WizardInput name="icon" type="icon" />
          </Col>
          <Col md={10}>
            <WizardInput name="name" />
          </Col>
        </Row>
        <WizardInput
          registerProps={{ required: false }}
          name="description"
          type="textarea"
        />
      </DetailCard>
    </DomainDetail>
  );
};
