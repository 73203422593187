import { domainConfigs } from "components/notification/config";
import { useNavigate } from "react-router-dom";
import { getUserGroups, usersApi } from "apis/flex/users";
import {
  applicantsApi,
  applicantStagesApi,
  campaignsApi
} from "apis/flex/recruitment";
import {
  contractsApi,
  employeesApi,
  screeningApi,
  trainingApi
} from "apis/flex/hr";
import { useCallback } from "react";
import { customFormsApi, customFormsResponseApi } from "apis/flex/customForms";
import { ticketsApi } from "apis/flex/support";
import { cleanFilePath } from "helpers/utils";
const getDomainItemGetter = (domain) => {
  if (domain === "applicant") return (id) => applicantsApi.get({ id });
  if (domain === "contract") return (id) => contractsApi.get({ id });
  if (domain === "employee-screening") return (id) => screeningApi.getOne(id);
  if (domain === "form") return (id) => customFormsApi.getOne(id);
  if (domain === "form-response")
    return (id) => customFormsResponseApi.getOne(id);
  if (domain === "recruitment-campaign") return (id) => campaignsApi.getOne(id);
  if (domain === "training-course")
    return (id) => trainingApi.courses.get({ id });
  if (domain === "user") return (id) => usersApi.getOne(id);
  if (domain === "user-group") return (id) => getUserGroups({ id });
  if (domain === "user-training")
    return (id) => trainingApi.userCourses.getOne(id);
  if (domain === "applicant-stage")
    return (id) => applicantStagesApi.get({ stageId: id });
  if (domain === "employee") return (id) => employeesApi.get({ id });
  if (domain === "new-starter") return (id) => usersApi.getOne(id);
  if (domain === "ticket") return (id) => ticketsApi.getOne(id);
  return () => Promise.resolve(null);
};
const getDomainItem = async (domain, itemId) => {
  const itemGetter = getDomainItemGetter(domain);
  const item = await itemGetter(itemId);
  return Array.isArray(item) ? item[0] : item;
};
export const getDomainHome = (domain, item, itemIds) => {
  if (!domainConfigs[domain]?.home) return "";
  return typeof domainConfigs[domain]?.home === "string" ? domainConfigs[domain].home : item ? domainConfigs[domain].home(item, itemIds) : null;
};
const getDomainHomeState = (domain, itemIds) => {
  const idField = domainConfigs[domain]?.homeIdField || "id";
  return itemIds?.length > 1 ? "?" + idField + "=" + itemIds.map((i) => i.toString()).join("&" + idField + "=") : "";
};
export const getDomainItemUrl = (domain, item) => {
  return typeof domainConfigs[domain]?.itemUrl === "string" ? domainConfigs[domain]?.itemUrl : item && (domainConfigs[domain]?.itemUrl?.(item) || getDomainHome(domain, item) + "/" + item.id);
};
export default ({ domain: _domain } = {}) => {
  const nav = useNavigate();
  const getHomeUrl = useCallback(
    async ({ itemIds, itemId, domain: eventRoom = _domain }) => {
      if (!eventRoom.length) throw new Error();
      const item = await getDomainItem(eventRoom, itemIds?.[0] || itemId);
      const domainHome = getDomainHome(eventRoom, item, itemIds);
      if (!domainHome) throw new Error();
      const urlState = getDomainHomeState(eventRoom, itemIds);
      return domainHome + (domainHome.includes("?") ? "" : urlState);
    },
    []
  );
  const getItemUrl = useCallback(
    async ({ itemId, domain: eventRoom = _domain }) => {
      if (!eventRoom) throw new Error();
      const item = await getDomainItem(eventRoom, itemId);
      const itemUrl = getDomainItemUrl(eventRoom, item);
      if (itemUrl) {
        return itemUrl;
      }
      const domainHome = getDomainHome(eventRoom, item);
      if (!domainHome) throw new Error();
      const itemPath = "/" + (item?.id || itemId);
      return cleanFilePath(domainHome) + itemPath;
    },
    []
  );
  return {
    getHomeUrl,
    getItemUrl,
    goToHome: async (props) => {
      const homeUrl = await getHomeUrl(props);
      nav(homeUrl);
    },
    goToItem: async (props) => {
      const itemUrl = await getItemUrl(props);
      nav(itemUrl);
    },
    getDomainHome
  };
};
