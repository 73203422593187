import { projectRolesApi } from "apis/flex/projects";
import { defaultCrudHookBuilder } from "hooks/useDefaultCrud";
const useProjectRoles = defaultCrudHookBuilder("ProjectRoles", projectRolesApi);
export const useProjectTeam = ({
  projectId,
  filters,
  ...props
}) => useProjectRoles({
  filters: { ...filters, projectId },
  useFilter: true,
  ...props
});
export default useProjectRoles;
