import { useQuery } from "@tanstack/react-query";
import { decryptParams } from "apis/flex/helpers";
import { useMemo } from "react";
export default () => {
  const params = new URLSearchParams(window.location.search);
  const iv = params.get("i");
  const enc = params.get("p");
  const { data, isLoading } = useQuery({
    enabled: !!iv && !!enc,
    queryKey: ["decryptParams", iv, enc],
    queryFn: () => decryptParams(enc, iv).then((d) => d?.data || {}),
    staleTime: Infinity
    // initialData: {}
  });
  return { data: useMemo(() => data || {}, [data]), isLoading };
};
