import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { checkUserEmails } from 'apis/flex/users';
import { isSupervisor } from 'hooks/useGuard';
import SelectedApplicants from './widgets/SelectedApplicants';
import useApplicants from './hooks/useApplicants';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';
import FormPicker from 'components/common/customForms/widgets/FormPicker';
import EmployeeContractForm from 'components/app/documents/contracts/EmployeeContractForm';
import TrainingCoursePicker from 'components/app/hr/training/widgets/TrainingCoursePicker';
import { NewPrescreeningGeneric } from 'components/app/hr/prescreening/NewPrescreening';
import { Applicant } from 'apis/flex/recruitment';
import { CourseEnrollFormGeneric } from 'components/app/hr/training/courses/Enroll';
import WithFormValues from 'components/wizard/WithFormValues';
import TemplatePicker from 'components/mailer/templates/TemplatePicker';
export const AcceptForm = ({
  ids: accepting = [],
  name = ''
}: {
  ids?: number[];
  name?: '' | `${string}.`;
}) => {
  const { data: applicantData = [] } = useApplicants({
    id: accepting,
    enabled: !!accepting?.length
  });
  const { data: duplicates = [] } = useQuery<
    { email: string; user: boolean }[],
    Error,
    Applicant[]
  >({
    queryKey: ['duplicates', accepting],
    enabled: !!applicantData?.length,
    queryFn: () => checkUserEmails(applicantData?.map(d => d.email)),
    select: d =>
      d
        .filter(d => !!d.user)
        .map(d => applicantData?.find(a => a.email == d.email))
  });
  // console.log('applicantData', applicantData);
  return (
    <>
      <NewPrescreeningGeneric namePrefix={name} />
      <EmployeeContractForm />
      <UserSelector
        name={name + 'managerId'}
        label="Manager"
        filter={{
          first: 'email',
          negate: true,
          second: applicantData?.map(d => d.email)
        }}
      />
      <FormPicker
        label="New Starter Form"
        instruction={
          <>
            This form will be asked <i>in addition to</i> the system New Starter
            Form
          </>
        }
        registerProps={{ required: false }}
        name={name + 'newStarterFormId'}
      />
      <TrainingCoursePicker
        name={name + 'trainingCourseIds'}
        multiple
        label="Training Courses"
        registerProps={{ required: false }}
        instruction="Any training selected here will need to be completed by the applicants before they start their first shift"
      />
      <WithFormValues fields={['trainingCourseIds']}>
        {({ trainingCourseIds }) =>
          trainingCourseIds && (
            <CourseEnrollFormGeneric namePrefix="training." />
          )
        }
      </WithFormValues>
      <TemplatePicker domain="applicant" action="accepted" />
      {!!duplicates.length && (
        <>
          <div className="bg-warning-subtle rounded-4 p-3 form-text text-warning">
            <div className="mb-2">
              <p>
                Some applicants already exist in Flex according to their email
                addresses.
              </p>
              <p>
                These applicants will not have new user accounts created. They
                should log in using their existing account details.
              </p>
              <p>
                Any settings configured above will be applied to their existing
                accounts.
              </p>
            </div>
            <SelectedApplicants ids={duplicates?.map(d => d.id)} />
          </div>
        </>
      )}
    </>
  );
};
