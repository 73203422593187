import React from 'react';
import WizardInput from 'components/wizard/WizardInput';

export const SignatureContent = ({ disabled = false, resource }) => {
  return (
    <>
      <WizardInput
        type="sign"
        formControlProps={{
          height: 120,
          readOnly: disabled
        }}
        name={`signature`}
        label={
          <span
            dangerouslySetInnerHTML={{
              __html: resource.confirmationText
            }}
          />
        }
      />
    </>
  );
};
export const CheckboxContent = ({ disabled = false, resource }) => {
  return (
    <WizardInput
      formControlProps={{
        readOnly: disabled
      }}
      type="checkbox"
      name={`checkbox`}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: resource.confirmationText
          }}
        />
      }
    />
  );
};
