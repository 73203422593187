import CrudApi from "./CrudApi";
import api from "./index";
const getStrategy = (type) => {
  switch (type) {
    case "enterprise":
      return "enterprise-bearer";
    case "public":
      return "b2c-bearer";
    default:
      return null;
  }
};
export const flexLogout = () => api.get("/users/auth/logout");
export const flexLogin = () => {
  return api.get("/users/auth/login", {
    params: { strategy: getStrategy("public") }
  });
};
export const getItemOwnership = () => {
  return api.get("users/auth/itemownership").then((d) => d.data);
};
export const getAppRoles = () => {
  return api.get("/users/auth/roles").then((d) => d.data);
};
export const accessGroupsApi = new CrudApi("/users/access");
