import React from 'react';
import AccessGroupsTable from './AccessGroupsTable';

export default () => {
  return (
    <>
      <AccessGroupsTable />
    </>
  );
};
