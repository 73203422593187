import React, { useEffect, useMemo, useState } from 'react';
import useTrainingCourses from '../hooks/useTrainingCourses';
import DetailPage, { useDetailPage } from 'components/common/detail/DetailPage';
import {
  TrainingCourse,
  TrainingResource,
  UserTrainingCourse,
  UserTrainingResource
} from 'apis/flex/hr';
import { Card, Col } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import useUserTraining from '../hooks/useUserTraining';
import Flex from 'components/common/Flex';
import FileIcon from 'components/files/FileIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/generic/training.jpg';
import {
  faCheckCircle,
  faClock,
  faLock,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import AuthorStamp from 'components/common/AuthorStamp';
import { useNavigate } from 'react-router-dom';
import CustomTabs from 'components/common/CustomTabs';
import ResourceWizard from './wizard/ResourceWizard';
import FrontPage from './FrontPage';
import ReviewWizard from './wizard/ReviewWizard';
import { useGuard } from 'hooks/useGuard';
import corner7 from 'assets/img/icons/spot-illustrations/corner-7.png';
import Background from 'components/common/Background';

export const ResourcePreview = ({ resource }) => {
  return (
    <Flex className={'mb-3'} alignItems={'center'} justifyContent={'start'}>
      <div className="file-thumbnail">
        <FileIcon
          url={resource.url}
          file={
            resource.fileId ||
            resource.videoId ||
            (resource.videoUrl && { type: 'video' })
          }
        />
      </div>
      <div className="ms-3">
        <h6 className="mb-1 text-900 fw-semi-bold">{resource.name}</h6>
        <AuthorStamp
          showIcon={false}
          userId={resource.createdBy}
          date={resource.createdDate}
        />
      </div>
    </Flex>
  );
};

const Resource = ({
  resource,
  index,
  review,
  setActiveResource
}: {
  resource: TrainingResource;
  index: number;
  /*
   only to be set to true if it's a manager reviewing a trainee's session. Not to be confused with
   where a trainee is reviewing their own session
  **/
  review: boolean;
  setActiveResource: (id: number) => void;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isTraineeReview, setIsTraineeReview] = useState(false);
  const { submit } = useDetailPage();
  const handleTraineeReview = () => {
    setIsTraineeReview(true);
    setIsActive(true);
  };
  return (
    <div>
      {/* <div className="position-absolute top-0 start-0 w-100 h-100 z-1 bg-black opacity-75" />
    <div> */}
      {isActive ? (
        isTraineeReview || review ? (
          <ReviewWizard
            resource={resource}
            index={index}
            review={review}
            onFinished={() => {
              setIsTraineeReview(false);
              setIsActive(false);
            }}
          />
        ) : (
          <ResourceWizard
            resource={resource}
            index={index}
            onFinished={() => {
              setIsActive(false);
              submit();
            }}
          />
        )
      ) : (
        <FrontPage
          resource={resource}
          index={index}
          review={review}
          onBegin={() => setIsActive(true)}
          onReview={handleTraineeReview}
        />
      )}
      {/* </div> */}
    </div>
  );
};
const getFirstUnpassedResource = (
  resources: TrainingResource[],
  userResources: UserTrainingResource[]
) => {
  const lookup = new Map(userResources.map(r => [r.resourceId, r]));
  return resources
    .sort((a, b) => a.index - b.index)
    .find(r => !lookup.get(r.id)?.approvedDate);
};
const Resources = ({
  course,
  resources,
  review,
  setActiveResource,
  activeResource,
  isLoading
}: {
  course: TrainingCourse;
  review?: boolean;
  resources: TrainingResource[];
  activeResource?: number;
  setActiveResource: (name: number) => void;
  isLoading?: boolean;
}) => {
  const { resetField, getValues } = useFormContext();
  useEffect(() => {
    const inputs = getValues('resources');
    if (!inputs) {
      const lookup = inputs?.reduce(
        (acc, cur) => ({ ...acc, [cur.resourceId]: cur }),
        {}
      );
      resetField('resources', {
        defaultValue: resources?.map(r => ({
          resourceId: r.id,
          confirmationText:
            'I confirm I have read and understood the information presented herein.',
          ...(lookup ? lookup[r.id] : {})
        })),
        keepDirty: false
      });
    }
  }, [resources]);

  return (
    <Col xs={12}>
      <CustomTabs
        isLoading={isLoading}
        activeKey={activeResource}
        setActiveKey={setActiveResource}
        items={resources?.map((resource, i) => ({
          title: r => (
            <>
              {resource.name}{' '}
              {r && i > 0 && course.enforceOrder && !r[i - 1]?.approvedDate && (
                <FontAwesomeIcon icon={faLock} className="ms-1" />
              )}
            </>
          ),
          formField: `resources`,
          disabled: r =>
            r && i > 0 && course.enforceOrder && !r[i - 1]?.approvedDate,
          tabClass: r => {
            if (!r || !r[i]) return;
            return r[i]?.approvedDate
              ? 'text-success'
              : r[i]?.failedDate
              ? 'text-danger'
              : r[i]?.finishedDate
              ? 'text-info'
              : null;
          },
          icon: r => {
            if (!r || !r[i]) return;
            return r[i]?.approvedDate
              ? faCheckCircle
              : r[i]?.failedDate
              ? faTimesCircle
              : r[i]?.finishedDate
              ? faClock
              : null;
          },
          id: resource.id,
          content: (
            <Resource
              setActiveResource={setActiveResource}
              review={review}
              resource={resource}
              index={i}
            />
          )
        }))}
      />
    </Col>
  );
};
const TrainingClient = ({
  courseId,
  userId,
  userCourseId,
  review
}:
  | {
      courseId: number;
      userId: number;
      userCourseId?: never;
      review?: boolean;
    }
  | {
      courseId?: never;
      userId?: never;
      userCourseId: number;
      review?: boolean;
    }) => {
  const {
    data: userTraining,
    isLoading: userLoading,
    upsert,
    upsertSelf,
    isUpserting
  } = useUserTraining<UserTrainingCourse>({
    filters: { courseId, userId },
    id: userCourseId,
    useFilter: !userCourseId && !!courseId && !!userId,
    select: d => d[0],
    staleTime: Infinity,
    noReturnOnChange: true
  });
  const courseIdToUse = useMemo(
    () => courseId || userTraining?.courseId,
    [courseId, userTraining?.courseId]
  );
  const { data, isLoading: trainingLoading } =
    useTrainingCourses<TrainingCourse>({
      id: courseIdToUse,
      select: d => d[0],
      staleTime: Infinity
    });
  const userResourceLookup = new Map(
    userTraining?.resources?.map(r => [r.resourceId, r])
  );
  const mappedUserResources = useMemo(
    () =>
      data?.resources?.map(r => ({
        resourceId: r.id,
        ...userResourceLookup.get(r.id)
      })) || [],
    [data?.resources, userTraining?.resources]
  );
  const defaultValues = {
    courseId: courseIdToUse,
    ...userTraining,
    resources: data?.resources?.map(r => ({
      resourceId: r.id
    }))
  };
  const nav = useNavigate();
  const handleSave = (d, a, vals) => {
    const firstUnpassedResource = getFirstUnpassedResource(
      data?.resources,
      vals?.resources
    );
    if (firstUnpassedResource) {
      setActiveResource(firstUnpassedResource.id);
    }
    if (vals?.resources?.every(r => r.completedDate)) {
      nav('/hr/training/me');
    }
  };
  const pageData = useMemo(
    () => ({
      ...userTraining,
      courseId: courseIdToUse,
      resources: mappedUserResources
    }),
    [userTraining, mappedUserResources, courseIdToUse]
  );
  const [activeResource, setActiveResource] = useState<number>();
  const { canEdit } = useGuard({
    roles: ['training-course'],
    itemIds: [courseId]
  });
  return (
    <DetailPage
      readOnly={review}
      domain="training-course"
      reset={!userTraining?.resources?.some(r => r?.failedDate)}
      data={pageData}
      background={{
        fieldName: 'profileBackground',
        disabled: true,
        defaultImg: coverSrc
      }}
      isSaving={isUpserting}
      isLoading={trainingLoading || userLoading || isUpserting}
      title={data?.name}
      createdBy={userTraining?.userId}
      createdDate={userTraining?.lastSubmittedDate}
      onSave={canEdit ? upsert : upsertSelf}
      defaultValues={defaultValues}
      bottombar={'scroll'}
      afterSave={handleSave}
    >
      {data?.description && (
        <Col className="mt-4 mb-2">
          <Card>
            <Background image={corner7} />
            <Card.Body>
              <div
                className="px-3 pt-5 pb-3"
                dangerouslySetInnerHTML={{ __html: data?.description }}
              />
            </Card.Body>
          </Card>
        </Col>
      )}
      <Resources
        course={data}
        isLoading={trainingLoading || userLoading || isUpserting}
        review={review && !!canEdit}
        resources={data?.resources?.sort((a, b) => a.index - b.index)}
        setActiveResource={setActiveResource}
        activeResource={activeResource}
      />
    </DetailPage>
  );
};
export default TrainingClient;
