import PropTypes from 'prop-types';
export const renderProps = PropTypes.shape({
  form: PropTypes.shape({
    error: PropTypes.object,
    errors: PropTypes.any,
    field: PropTypes.object
  }),
  label: PropTypes.node,
  input: PropTypes.func
});
export const formWizardProps = {
  onSubmit: PropTypes.func,
  success: PropTypes.shape({
    element: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.node,
    animation: PropTypes.object,
    celebrate: PropTypes.bool
  }),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  review: PropTypes.bool,
  storeId: PropTypes.string,
  store: PropTypes.object,
  isFinished: PropTypes.bool,
  defaultValues: PropTypes.object,
  onScreenout: PropTypes.func,
  fieldArray: PropTypes.shape({
    data: PropTypes.array,
    icon: PropTypes.func,
    label: PropTypes.func,
    output: PropTypes.func,
    onSubmit: PropTypes.func
  }),
  isFormLoading: PropTypes.bool,
  mediaUploadPath: PropTypes.string
};
