import useCampaign from "./useCampaign";
import useShortLinks from "hooks/useShortLinks";
import useEncryptUrlParams from "hooks/useEncryptUrlParams";
import useCopyModal from "hooks/useCopyModal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
export default ({ campaignId } = {}) => {
  const [requested, setRequested] = useState(null);
  const [addStarted, setAddStarted] = useState(null);
  const id = typeof requested === "number" ? requested : campaignId;
  const {
    data: campaign,
    update,
    isUpdating
  } = useCampaign({
    id,
    enabled: !!requested
  });
  const { copy } = useCopyModal();
  const { add, isAdding, error } = useShortLinks({
    afterSave: (d) => update({ id, data: { inviteLink: d.shortLink }, noReturn: true })
  });
  const { data: params } = useEncryptUrlParams(
    {
      campaignId: id,
      campaignType: "recruitment"
    },
    !!id
  );
  useEffect(() => {
    if (!requested || !!isAdding || !!isUpdating || !!addStarted) return;
    if (!!campaign?.inviteLink && Number(requested) === Number(campaign?.id)) {
      copy(campaign.inviteLink);
      setRequested(null);
      setAddStarted(null);
      return;
    }
    if (!!campaign && !campaign?.inviteLink && !!params) {
      add({
        url: "/respond/" + campaign.formId + params,
        campaignId: id,
        campaignType: "recruitment"
      });
      setAddStarted(campaign.id);
      return;
    }
  }, [campaign, requested, isAdding, isUpdating, addStarted, params]);
  const [toastState, setToast] = useState(null);
  useEffect(() => {
    if (!!requested && !toastState) {
      return setToast(
        toast.loading("Requesting link...", {
          isLoading: true,
          containerId: "default"
        })
      );
    }
    if (!requested && !!toastState) {
      toast.dismiss({ id: toastState, containerId: "default" });
      setToast(null);
    }
  }, [requested]);
  const handleRequest = (cid = campaignId) => {
    setRequested(cid);
  };
  return {
    copy: (campaignId2) => handleRequest(campaignId2),
    error,
    isLoading: !!requested && (isAdding || isUpdating) && !error
  };
};
