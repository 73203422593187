import { resolveShortLink, shortLinksApi } from "apis/flex/helpers";
import useDefaultCrud from "./useDefaultCrud";
import { useQuery } from "@tanstack/react-query";
export default (props) => {
  return useDefaultCrud("ShortLinks", shortLinksApi, props);
};
export const useResolveShortLink = (hash) => {
  return useQuery({
    queryKey: ["resolveShortLink", hash],
    queryFn: () => resolveShortLink(hash),
    enabled: !!hash
  });
};
