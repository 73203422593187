import React from 'react';
import useProjects from '../useProjects';
import { useFieldArray, useWatch } from 'react-hook-form';
import { SelectorItem } from 'components/common/ItemSelector';
import SoftBadge from 'components/common/SoftBadge';
import {
  FieldArrayList,
  FieldArrayListProps
} from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import WizardInput from 'components/wizard/WizardInput';
import { TargetGroup, TargetGroupCostCenter } from 'apis/flex/projects';
import { ensureArray } from 'helpers/utils';
import WizardInputLabel from 'components/wizard/WizardInputLabel';
export default ({
  label = 'Cost Centers',
  instruction,
  ...rest
}: {
  label?: string;
  instruction?: string;
} & Omit<
  FieldArrayListProps<TargetGroupCostCenter>,
  'item' | 'fields' | 'remove' | 'append' | 'defaultValues'
>) => {
  return (
    <>
      <WizardInputLabel
        id={'costCenters'}
        name={'costCenters'}
        label={label}
        instruction={instruction}
      />
      <ArrayInput {...rest} />
    </>
  );
};
const ArrayInput = (props: any) => {
  const pid = useWatch({ name: 'projectId' });
  const tgId = useWatch({ name: 'id' });
  const { data } = useProjects({
    id: pid,
    select: (d): SelectorItem[] =>
      d[0]?.costCenters?.map(d => ({
        label: d.costCenter[0].name,
        description: `${d.units} units @ £${d.unitValue.toFixed(2)} | ${
          d.costCenter[0].description
        }`,
        value: d.id,
        tags: <SoftBadge bg="secondary">{d.costCenter[0].category}</SoftBadge>
      }))
  });
  const { fields, append, remove } = useFieldArray<TargetGroup, 'costCenters'>({
    name: 'costCenters'
  });
  console.log('fields', fields);
  return (
    <FieldArrayList
      remove={i => {
        console.log('removing', fields, i);
        remove(i);
      }}
      append={() =>
        append({
          targetGroupId: tgId,
          projectCostCenterId: undefined,
          id: undefined
        })
      }
      defaultValues={{}}
      fields={fields}
      item={(field, i) => (
        <Item field={field} index={i} name={'costCenters'} projects={data} />
      )}
      {...props}
    />
  );
};
const Item = ({ field, index, name, projects }) => {
  const all = useWatch({ name });
  console.log('all', all);
  const others = ensureArray(all)?.filter(d => d.id !== field.id) || [];
  return (
    <div className="d-flex gap-2">
      <WizardInput
        type="select"
        name={`${name}.${index}.projectCostCenterId`}
        options={
          projects?.map(d => ({
            ...d,
            isDisabled: others.includes(d.value)
          })) || []
        }
        label="Cost Center"
      />
      <WizardInput
        type="number"
        name={`${name}.${index}.proportion`}
        label="Proportion"
        instruction="The proportion of the project cost center budget that this target group will be allotted. Will be auto-calculated if left blank (recommended)"
        registerProps={{ required: false }}
        formControlProps={{ step: 0.01 }}
        suffix="%"
      />
    </div>
  );
};
