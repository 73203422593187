import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect } from 'react';
import SelectedApplicants from './widgets/SelectedApplicants';
import StageForm from '../stages/StageForm';
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import useCampaignApplicants from './hooks/useCampaignApplicants';
import useStages from './hooks/useStages';
import useApplicantStages from './hooks/useApplicantStages';
import { ApplicantStage } from 'apis/flex/recruitment';
import ActionFooter from 'components/common/ActionFooter';
import { ResponsiveModal } from 'components/common/Modals';
const ApplicantStageFormInner = ({
  campaignId,
  applicantIds = [],
  namePrefix = ''
}: {
  campaignId: number;
  applicantIds?: number[];
  namePrefix?: '' | `${string}.`;
}) => {
  const selectedStage = useWatch({ name: namePrefix + 'stageId' });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (selectedStage) {
      const { sendEmail, notificationTemplateId } = data.find(
        s => s.value == selectedStage
      );
      setValue('sendEmail', sendEmail);
      setValue('notificationTemplateId', notificationTemplateId);
    }
  }, [selectedStage]);
  const { data: applicants = [], isLoading: applicantsLoading } =
    useCampaignApplicants({
      campaignId,
      id: applicantIds
    });
  const { data, isLoading } = useStages({
    filters: { campaignId },
    useFilter: !!campaignId,
    enabled: !applicantsLoading,
    select: d =>
      d.map(s => ({
        label: s.name,
        value: s.id,
        disabled: applicants.every(a => a.currentStage?.stageId == s.id),
        ...s
      }))
  });
  return (
    <>
      <WizardInput
        name={namePrefix + 'stageId'}
        label="Stage"
        loading={isLoading}
        type="select"
        options={data}
      />
      <StageForm
        withDueDate={!!data?.find(s => s.value == selectedStage)?.formId}
      />
    </>
  );
};
export const ApplicantStageForm = ({
  campaignId,
  applicantIds,
  onFinished
}: {
  campaignId: number;
  applicantIds: number[];
  onFinished: () => void;
}) => {
  const { bulkAdd, data, isBulkAdding } = useApplicantStages({
    afterSave: () => onFinished()
  });
  const methods = useForm<ApplicantStage, any, ApplicantStage>();
  const handleSubmit = methods.handleSubmit(vals =>
    bulkAdd(applicantIds.map(id => ({ applicantId: id, ...vals })))
  );
  return (
    <FormProvider {...methods}>
      <ApplicantStageFormInner
        campaignId={campaignId}
        applicantIds={applicantIds}
      />
      <ActionFooter
        onCancel={onFinished}
        onSubmit={handleSubmit}
        isLoading={isBulkAdding}
      />
    </FormProvider>
  );
};
export const ApplicantStageModal = ({
  show,
  setShow,
  applicantIds,
  campaignId
}: {
  campaignId: number;
  show: boolean;
  setShow: (show: boolean) => void;
  applicantIds: number[];
}) => {
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <ApplicantStageForm
        campaignId={campaignId}
        applicantIds={applicantIds}
        onFinished={() => setShow(false)}
      />
    </ResponsiveModal>
  );
};
